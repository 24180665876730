import React, { SyntheticEvent, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Edit } from "@mui/icons-material";
import DeleteConfirmModal from "../../../Modal/DeleteConfirmModal";
import { Delete } from "@mui/icons-material";
import { ContentCopy } from "@mui/icons-material";
import PageDetails from "./PageDetails";
import WebsiteAPI from "../../../../../store/reducers/builderReducers/website/websiteApi";
import PagesAPI, {
  Page,
  Pages as PagesStore,
} from "../../../../../store/reducers/builderReducers/pages/pagesApi";
import LanguageSettingsAPI from "../../../../../store/reducers/builderReducers/languageSettings/languageSettingsApi";
import {
  updateWebpages,
  setActivePage,
  deleteWebpageById,
} from "../../../../../store/reducers/builderReducers/pages/pagesActions";
import { useAppSelector } from "../../../../../store/hooks";
import { SidebarMenus } from "../../Sidebar";
import { inClass } from "../../../../../utils/Helpers";
import DropdownModal from "../../../Modal/DropdownModal";

export default function Pages({
  setSelectedMenu,
}: {
  setSelectedMenu: React.Dispatch<React.SetStateAction<SidebarMenus>>;
}) {
  const webpages = useAppSelector(
    (state) => state.builder.pages.get("list") as PagesStore["list"]
  );
  const [sortedWebpages, setSortedWebpages] = useState<Page[]>([]);
  const activePageId = useAppSelector(
    (state) => state.builder.pages.get("active") as PagesStore["active"]
  );
  const [editableId, setEditableId] = useState<number | null>(null);
  const [showPageDetails, setShowPageDetails] = useState(false);

  React.useEffect(() => {
    PagesAPI.loadWebpages();
    LanguageSettingsAPI.loadLanguageSettings();
  }, []);

  function addNewPage() {
    updateWebpages([
      ...webpages,
      {
        id: 0,
        name: "",
        page_type: "contentPage",
        url: "",
        website_id: "",
      } as any,
    ]);
    setEditableId(0);
  }

  React.useEffect(() => {
    setSortedWebpages(
      [...webpages].sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      )
    );
  }, [webpages]);

  function setActivePageId(id: number | null) {
    setActivePage(id);
  }

  function clickEvent(e: SyntheticEvent) {
    if (inClass("dropdown-trigger", e.target as any)) {
      // Do not close sidebar
    } else {
      setSelectedMenu("");
    }
  }

  function duplicatePage(id: number) {
    PagesAPI.duplicateWebpage(id).then((webpage) => {
      updateWebpages([...webpages, webpage]);
    });
  }

  function deletePage(id: number) {
    setActivePageId(webpages[0].id);

    PagesAPI.deleteWebpage(id).then(() => {
      setActivePageId(webpages[0].id);
    });
  }

  return editableId == null ? (
    <div className="b-container">
      <div className="b-row">
        <div className="col-9 fw-bold">Website: Pages</div>
        <div className="col-3 b-pt-1 text-end">
          <a className="pages-button" onClick={() => addNewPage()}>
            + Add
          </a>
        </div>
      </div>
      <div className="b-row b-mt-3 page-list" role="button">
        {sortedWebpages?.map((page) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
              key={page.id}
              className={
                "col-12 b-py-2 page" +
                (activePageId === page.id ? " active" : "")
              }
              onClick={(e) => {
                console.log("this was triggered");

                console.log(page);

                clickEvent(e);
                setActivePageId(page.id);
              }}
            >
              <label role="button">{page.name}</label>

              {/* <MoreHorizIcon
                  type="button"
                  fontSize="small"
                  onClick={() => setShowPageDetails(!showPageDetails)}
                /> */}

              <ul className="dropdown-menu-styling">
                <li>
                  <span
                    className="pages-addon-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditableId(page.id);
                    }}
                  >
                    <Edit />
                  </span>
                </li>
                <DropdownModal>
                  <li
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="pages-addon-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        duplicatePage(page.id);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                      }}
                    >
                      <ContentCopy style={{ fontSize: "15px" }} />
                      <span style={{ marginLeft: "5px", marginTop: "2px" }}>
                        Copy
                      </span>
                    </span>
                  </li>
                  <li>
                    <DeleteConfirmModal
                      onConfirm={() => deletePage(page.id)}
                      text="Delete"
                    />
                  </li>
                </DropdownModal>
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <PageDetails
      page={webpages.find((x) => x.id === editableId)!}
      setEditableId={setEditableId}
      webpages={webpages}
    />
  );
}
