import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom";
import ProductPanel from "./ProductPanel"
import ProductBreadcrumbs from "./ProductBreadcrumbs"
import "./ProductDetailComponent.css"
import { useShoppingCart, LocationProduct } from "../context/ShoppingCartContext"
import { useAppSelector } from "../../../store/hooks";
import { ProductDetailComponent as ProductDetailComponent_ } from "../interfaces/base";
import { logErrorToBackend } from "../utilities/helpers";
import styled from '@emotion/styled';
import ProductMetadata from "./ProductMetadata"
import RenderChildren from "../RenderChildren";
const Div = styled('div')(
  null,
  props => function (props: any) {
    return { ...props['data-style'] }
  }
);

declare var API: any;

export default function ProductDetailComponent({ id, attributes, childElements, childWebpageComponentRelations }: ProductDetailComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get('id'))

  const { locationProducts, setLocationProducts } = useShoppingCart()
  const [ productsLoaded, setProductsLoaded ] = useState(false)
  const navigate = useNavigate();

  var param_id = useParams().id;

  if (window.location.pathname.includes('/builder/')) { // use first product if editor
    param_id = "";
  }

  var locationProduct: LocationProduct | any = [];
  if (locationProducts) {
    if (param_id) {
      locationProduct = locationProducts.find((lp: LocationProduct) => {
        return lp.id == Number(param_id);
      });
    } else {
      locationProduct = locationProducts.length ? locationProducts[0] : null;
    }
  }
  if( productsLoaded && !locationProduct ){
    const productNotFoundData = {
      name: "ProductNotFound",
      message: "Product with id " + param_id + " was not found in list.",
      stack: "Product with id " + param_id + " was not found in list. In ProductDetailComponent.tsx",
      componentStack: "Product with id " + param_id + " was not found in list. In ProductDetailComponent.tsx",
      href: window.location.href,
    }
    logErrorToBackend(productNotFoundData);
      
    navigate("/notFoundProduct/" + param_id); // not found
  }

  useEffect(() => {

    // reload products
    fetch(
      (API ? API.backendURL : '') + "/webstoreApi/loadLocationProducts",
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLocationProducts((currItems) => {
          // let newItems = [...currItems]
          // res.map((locationProduct: LocationProduct) => {
          //   if (currItems.find((item) => item.id === locationProduct.id) == null) {
          //     // Does not exist then append
          //     newItems = [...newItems, locationProduct]
          //   } else {
          //     // Exist then update
          //     newItems = newItems.map((item) => {
          //       if (item.id === locationProduct.id) {
          //         return { ...item, ...locationProduct }
          //       } else {
          //         return item
          //       }
          //     })
          //   }
          // })
          const newItems = [...res];
          return newItems
        })
        setProductsLoaded(true)
      })

  }, [])

  return (
    <Div
      id={id.toString()}
      key={id}
      data-style={attributes && attributes.styles}
      className={
        attributes &&
        attributes.classes +
        (attributes.classes != "container" && attributes.classes != "container-fluid" ? " builder-component builder-component__product_detail" : "") +
        (editorId === id ? " builder-component--active" : "")
      }
      data-component-type='section'
    >
      <ProductMetadata locationProduct={locationProduct} />
      <RenderChildren key={id + "children"} childWebpageComponentRelations={childWebpageComponentRelations}/>

      <div className="page light-gray-background-2 animsition withoutsidemenu product-page">
        {locationProduct && <ProductBreadcrumbs locationProduct={locationProduct} />}
        {locationProduct &&
          <div className="page-content productsResults productPage">
            <ProductPanel locationProduct={locationProduct} />
          </div>
        }
      </div>
    </Div>
  )
}
