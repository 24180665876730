import { findComponentById } from "../../../../../utils/Helpers";
import { useAppSelector } from "../../../../../store/hooks";
import AddSubtractInputNumber from "./AddSubtractInputNumber/AddSubtractInputNumber";
import { updateComponentStyle } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import Dropdown from "../../../Dropdown/Dropdown";
import ColorPicker from "../FontMenu/ColorPicker/ColorPicker";
import { useState } from "react";

interface Props {
  id: number;
}

export default function TextShadowMenu({ id }: Props) {
  console.log("TextShadowMenu", id);
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )!;
  const textShadow = (
    component.attributes.styles.textShadow ?? "0px 0px 10px #fff"
  ).split(" ");

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  return (
    <>
      <div className="d-flex flex-wrap align-items-center b-gap-2">
        <div>X-position:</div>
        <div className="textshadow-menu_input">
          <AddSubtractInputNumber
            value={parseInt(textShadow[0].match(/(-\d+|\d+)/)![0])}
            setValue={(newValue) => {
              updateComponentStyle(id, {
                textShadow:
                  newValue +
                  "px " +
                  textShadow[1] +
                  " " +
                  textShadow[2] +
                  " " +
                  textShadow[3],
              });
            }}
          />
        </div>
        <div>Y-position:</div>
        <div className="textshadow-menu_input">
          <AddSubtractInputNumber
            value={parseInt(textShadow[1].match(/(-\d+|\d+)/)![0])}
            setValue={(newValue) =>
              updateComponentStyle(id, {
                textShadow:
                  textShadow[0] +
                  " " +
                  newValue +
                  "px " +
                  textShadow[2] +
                  " " +
                  textShadow[3],
              })
            }
          />
        </div>
        <div>Blur radius:</div>

        <div className="textshadow-menu_input">
          <AddSubtractInputNumber
            value={parseInt(textShadow[2].match(/(-\d+|\d+)/)![0])}
            setValue={(newValue) =>
              updateComponentStyle(id, {
                textShadow:
                  textShadow[0] +
                  " " +
                  textShadow[1] +
                  " " +
                  newValue +
                  "px " +
                  textShadow[3],
              })
            }
          />
        </div>
        <div>Color:</div>
        <div className="color-picker color-picker__swatch-item color-picker__swatch-item__parent position-relative dropup">
          <div
            className="color-picker color-picker__swatch-item position-relative"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            style={{
              margin: 0,
              backgroundColor: textShadow[3] ?? "#fff",
            }}
            onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
          ></div>

          {isColorPickerOpen && (
            <ColorPicker
              title="Color"
              returnType="hex"
              value={textShadow[3] ?? "#fff"}
              onChange={(newValue) => {
                updateComponentStyle(id, {
                  textShadow:
                    textShadow[0] +
                    " " +
                    textShadow[1] +
                    " " +
                    textShadow[2] +
                    " " +
                    newValue,
                });
              }}
              setColorPickerOpen={() =>
                (document.querySelector(
                  '.color-picker.show[data-bs-toggle="dropdown"]'
                ) as HTMLElement)!.click()
              }
            />
          )}
        </div>
      </div>
    </>
  );
}
