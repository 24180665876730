import React, { useState } from 'react'
import ColorsFormEdit from './ColorsFormEdit'
import ColorsFormView from './ColorsFormView'
import ColorAPI from '../../../../../store/reducers/builderReducers/color/colorApi'
import { useAppSelector } from '../../../../../store/hooks'

export default function Colors() {
  React.useEffect(() => {
    ColorAPI.loadColors()
  }, [])
  const colors = useAppSelector((state) => state.builder.colors)

  const [formEditModes, setFormEditModes] = useState({
    colors: false,
  })

  function setFormModes(newMode: {}) {
    setFormEditModes((modes) => ({
      ...modes,
      ...newMode,
    }))
  }

  return (
    <div className='b-container'>
      {formEditModes.colors ? <ColorsFormEdit handleEdit={setFormModes} colors={colors.toArray()} /> : <ColorsFormView handleEdit={setFormModes} colors={colors.toArray()} />}
    </div>
  )
}
