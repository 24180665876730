import React, { useState } from "react";
import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";
import Select from "react-select";
import { DefaultReactSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import WebsiteAPI from "../../../../../store/reducers/builderReducers/website/websiteApi";
import DragAndDropUpload from "../../../DragAndDropUpload/DragAndDropUpload";

interface Props {
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  website: Website;
}

export default function WebsiteFaviconFormEdit({ setEditing, website }: Props) {
  const [cachedWebsiteParameters, setCachedWebsiteParameters] = useState({
    content_width_size: website!.content_width_size,
    content_width_unit: website!.content_width_unit,
  });

  function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    WebsiteAPI.saveWebsite({ ...website!, ...cachedWebsiteParameters }).then(
      () => {
        setEditing(false);
      }
    );
  }

  const reactSelectStyle = {
    ...DefaultReactSelectStyle,
    container: (styles: any, data: any) => {
      return {
        ...DefaultReactSelectStyle.container!(styles, data),
        height: "34px",
      };
    },
  };

  function uploadFile(e: any) {
    console.log(e.target.files[0]);
    WebsiteAPI.setWebsiteFavicon(e.target.files[0]).then(() => {
      setEditing(false);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row b-mt-3">
        <div className="col-12">
          <DragAndDropUpload icon="cloud" onChange={uploadFile} />
        </div>
      </div>
      <div className="b-mt-3">
        {/* <button className='b-btn btn-primary' type='submit'>
                    Save
                </button> */}
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => setEditing(false)}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
