import React, { useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import WebsiteAPI from "../../../../../store/reducers/builderReducers/website/websiteApi";
import useClickOutside from "./useClickOutside";
import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";

interface Props {
  handleEdit: (v: boolean) => void;
  website: Website;
}

export default function WebsiteTrackingFormEdit({
  handleEdit,
  website,
}: Props) {
  const popover1 = useRef<HTMLInputElement>(null);
  const popover2 = useRef<HTMLInputElement>(null);
  const popover3 = useRef<HTMLInputElement>(null);
  const [popoverOpen, setPopoverOpen] = useState({
    row_color: false,
    column_color: false,
    element_color: false,
  });
  const [cachedColors, setCachedColors] = useState(website!);

  useClickOutside(popover1, () =>
    setPopoverOpen({ ...popoverOpen, row_color: false })
  );
  useClickOutside(popover2, () =>
    setPopoverOpen({ ...popoverOpen, column_color: false })
  );
  useClickOutside(popover3, () =>
    setPopoverOpen({ ...popoverOpen, element_color: false })
  );

  function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    WebsiteAPI.saveWebsite({
      ...website!,
      row_color: cachedColors.row_color,
      element_color: cachedColors.element_color,
      column_color: cachedColors.column_color,
    }).then(() => {
      handleEdit(false);
    });
  }

  return (
    <form onSubmit={(evt) => handleSubmit(evt)}>
      <div className="row b-mt-4">
        <div className="col-12 b-pb-1">
          <label className="fw-bold">Page builder color</label>
        </div>
        <div className="col-12 b-mt-3 d-flex b-py-1 align-items-center">
          <div className="picker position-relative">
            <div
              className="color-box"
              style={{ backgroundColor: cachedColors.row_color }}
              onClick={() =>
                setPopoverOpen({ ...popoverOpen, row_color: true })
              }
            />

            {popoverOpen.row_color && (
              <div className="popover" ref={popover1}>
                <HexColorPicker
                  color={cachedColors.row_color}
                  onChange={(newColor) =>
                    setCachedColors({ ...cachedColors, row_color: newColor })
                  }
                />
              </div>
            )}
          </div>
          <input
            className="b-px-4 d-flex align-items-center color-picker-value text-center"
            value={cachedColors.row_color}
            onChange={(evt) =>
              setCachedColors({ ...cachedColors, row_color: evt.target.value })
            }
          />
          <label className="b-ps-3 align-self-center">Row</label>
        </div>
        <div className="col-12 d-flex b-py-1">
          <div className="picker position-relative">
            <div
              className="color-box"
              style={{ backgroundColor: cachedColors.column_color }}
              onClick={() =>
                setPopoverOpen({ ...popoverOpen, column_color: true })
              }
            />

            {popoverOpen.column_color && (
              <div className="popover" ref={popover2}>
                <HexColorPicker
                  color={cachedColors.column_color}
                  onChange={(newColor) =>
                    setCachedColors({ ...cachedColors, column_color: newColor })
                  }
                />
              </div>
            )}
          </div>
          <input
            className="b-px-4 d-flex align-items-center color-picker-value text-center"
            value={cachedColors.column_color}
            onChange={(evt) =>
              setCachedColors({
                ...cachedColors,
                column_color: evt.target.value,
              })
            }
          />
          <label className="b-ps-3 align-self-center">Column</label>
        </div>
        <div className="col-12 d-flex b-py-1">
          <div className="picker position-relative">
            <div
              className="color-box"
              style={{ backgroundColor: cachedColors.element_color }}
              onClick={() =>
                setPopoverOpen({ ...popoverOpen, element_color: true })
              }
            />

            {popoverOpen.element_color && (
              <div className="popover" ref={popover3}>
                <HexColorPicker
                  color={cachedColors.element_color}
                  onChange={(newColor) =>
                    setCachedColors({
                      ...cachedColors,
                      element_color: newColor,
                    })
                  }
                />
              </div>
            )}
          </div>
          <input
            className="b-px-4 d-flex align-items-center color-picker-value text-center"
            value={cachedColors.element_color}
            onChange={(evt) =>
              setCachedColors({
                ...cachedColors,
                element_color: evt.target.value,
              })
            }
          />
          <label className="b-ps-3 align-self-center">Element</label>
        </div>
      </div>
      <div className="b-mt-3">
        <button className="b-btn btn-primary sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => handleEdit(false)}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
