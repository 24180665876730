import React from "react"
import { FormComponent as FormComponent_ } from "./interfaces/base";
import RenderChildren from "./RenderChildren";
import styled from '@emotion/styled';
import './Form.css'

const Form = styled('form')(
  null,
  props => function(props: any){
    return {...props['data-style']}
  }
);



interface Props {
  id: number
  attributes: FormComponent_['attributes']
  editor: JSX.Element
  childElements: any
  childWebpageComponentRelations: any
}

export default function FormComponent({ id, attributes, childElements, childWebpageComponentRelations }: Props) {
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    fetch(attributes.action, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => {
      if (response.ok) {
        alert('Sõnum edastatud')
      } else {
        alert(response.statusText)
      }
    })
  }

  return (
    <Form
      id={id ? id.toString() : ''}
      onSubmit={handleSubmit}
      action={attributes.action}
      data-style={attributes && attributes.styles}
      className={attributes && attributes.classes}
    >
      <RenderChildren key={id + "children"} childWebpageComponentRelations={childWebpageComponentRelations}/>
    </Form>
  )
}
