import { useState, useRef, useEffect, memo } from "react";
import { useLayoutEffect } from "react";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import BorderStyleOutlinedIcon from "@mui/icons-material/BorderStyleOutlined";
import FitScreenOutlinedIcon from "@mui/icons-material/FitScreenOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import FormatColorTextIcon from "@mui/icons-material/FormatColorTextOutlined";
import BorderMenu from "../Menus/BorderMenu/BorderMenu";
import SpacingMenu from "../Menus/SpacingMenu/SpacingMenu";
import BackgroundMenu from "../Menus/BackgroundMenu/BackgroundMenu";
import SelectAllOutlinedIcon from "@mui/icons-material/SelectAllOutlined";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ColMenu from "../Menus/ColMenu/ColMenu";
import {
  findComponentById,
  getComponentType,
  inClass,
} from "../../../../utils/Helpers";
import { MoreHorizOutlined } from "@mui/icons-material";
import Confirmation from "../Confirmation/Confirmation";
import CreateTemplateModal from "../Modals/CreateTemplateModal";
// import store from '../../../../store/store'
import { setEditableComponentId } from "../../../../store/reducers/textEditor/textEditorActions";
import SelectElementType from "./SelectElementType/SelectElementType";
import SelectTemplate from "./SelectTemplate/SelectTemplate";
import FontMenu from "../Menus/FontMenu/FontMenu";
import LogoMenu from "../Menus/LogoMenu/LogoMenu";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import WidthWideOutlinedIcon from "@mui/icons-material/WidthWideOutlined";
import LinkIcon from "@mui/icons-material/Link";
import MenuMenu from "../Menus/MenuMenu/MenuMenu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CartMenu from "../Menus/CartMenu/CartMenu";
import CartFontMenu from "../Menus/CartFontMenu/CartFontMenu";
import LogoBgMenu from "../Menus/LogoBgMenu/LogoBgMenu";
import AddIcon from "@mui/icons-material/Add";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StructureAPI from "../../../../store/reducers/builderReducers/structure/structureApi";
import {
  updateStructureComponent,
  addNewComponent,
  deleteComponentById,
  removeChildFromParent,
  addChildToParent,
  updateComponentStructureOrder,
  updateStructureWebpageComponentsOrder,
} from "../../../../store/reducers/builderReducers/structure/structureActions";
import { useAppSelector } from "../../../../store/hooks";
import LinkMenu from "../Menus/LinkMenu/LinkMenu";
import ContentWidthMenu from "../Menus/ContentWidthMenu/ContentWidthMenu";
import { AllComponents } from "../../../_default/interfaces/base";
import TextShadowMenu from "../Menus/TextShadowMenu/TextShadowMenu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Dropdown from "react-overlays/Dropdown";
import { createPortal } from "react-dom";
import { et } from "date-fns/locale";

function Editor({
  id,
  parent_id,
  keyDownEvent,
}: {
  id: number;
  parent_id: number;
  keyDownEvent: KeyboardEvent;
}) {
  const editorId = id;
  id = parent_id;
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )!;
  const editorState = useAppSelector((state) => state.builder.editor);

  const parentComponent = useAppSelector(
    (state) =>
      state.builder.structure.components[editorState.component_parent_id ?? ""]
  );

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [createComponentTemplate, setCreateComponentTemplate] =
    useState<AllComponents | null>(null);
  const [showConfirmTemplateUnlink, setShowConfirmTemplateUnlink] =
    useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [onMouseUp, setOnMouseUp] = useState<MouseEvent>();
  // const component = structure.components[id]!
  const website = useAppSelector((state) => state.builder.website);

  const templates = useAppSelector((state) => state.builder.templates);
  const template = templates.find(
    (x: any) => x.webpage_component_id === component.id
  );
  const templateId = template ? template.id : null;
  function unlinkTemplate() {
    // duplicate element
    duplicateComponent(component);
    // remove existing component
    deleteComponent(component);
  }

  const componentType = getComponentType(component);
  const parentComponentType = parentComponent
    ? getComponentType(parentComponent)
    : "";
  // console.log("Editing id:" + parent_id);
  // console.log("Editing type:" + componentType);
  // console.log(editorState);
  // console.log("Parent component: ", parentComponent);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  const [range, saveRange] = useState<Range>();
  const [editorPos, setEditorPos] = useState<number>(24);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutsideBuilderElement(event: any) {
      const builderElement = inClass("builder-component", ref.current!);

      if (builderElement && !builderElement?.contains(event.target)) {
        // console.log("builderElement click outside");
        // console.log(["text", "Button", "nav-item"].includes(component.type));
        // console.log(builderElement!.querySelector("[contenteditable=true]"));
        // console.log(builderElement);
        // click outside
        if (
          ["text", "Button", "nav-item"].includes(component.type) &&
          builderElement &&
          builderElement!.querySelector("[contenteditable=true]") &&
          // @ts-ignore
          component.attributes.text !==
            builderElement.querySelector("[contenteditable=true]")!.innerHTML
        ) {
          // console.log("update text");
          updateStructureComponent(
            id,
            "attributes.text",
            builderElement!.querySelector("[contenteditable=true]")!.innerHTML
          );
        }
      }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutsideBuilderElement);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutsideBuilderElement);
    };
  }, [ref]);

  useLayoutEffect(() => {
    // console.log("useLayoutEffect editor");
    return () => {
      console.log("useLayoutEffect editor out");
      const builderElement = inClass("builder-component--active", ref.current!);
      if (
        ["text", "Button", "nav-item"].includes(component.type) &&
        builderElement &&
        builderElement!.querySelector("[contenteditable]") &&
        // @ts-ignore
        component.attributes.text !==
          builderElement.querySelector("[contenteditable]")!.innerHTML
      ) {
        //console.log("update text");
        updateStructureComponent(
          id,
          "attributes.text",
          builderElement!.querySelector("[contenteditable]")!.innerHTML
        );
      }
    };
  }, []);

  useLayoutEffect(() => {
    setEditorPos(ref.current?.clientHeight ?? 24);
    const resizeListener = () => {
      setEditorPos(ref.current?.clientHeight ?? 24);
    };
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  });

  function triggerResize() {
    setTimeout(() => {
      setEditorPos(ref.current?.clientHeight ?? 24);
    }, 1);
  }

  useEffect(() => {
    function mouseupEvent(e: any) {
      if (
        inClass("builder-component--active", e.target) &&
        !inClass("global-editor__menu", e.target)
      ) {
        setOnMouseUp(e);
        var sel = window.getSelection && window.getSelection();
        if (sel && sel.rangeCount > 0) {
          saveRange(window.getSelection()?.getRangeAt(0));
        }
      }
    }
    window.addEventListener("mouseup", mouseupEvent);

    return () => {
      window.removeEventListener("mouseup", mouseupEvent);
    };
  }, []);

  function setRange(range_ = range) {
    let selection = window.getSelection();
    selection?.removeAllRanges();
    if (range_) {
      selection?.addRange(range_);
    }
  }

  function getBottomMenu() {
    switch (activeMenu) {
      case "cartFontMenu":
        return (
          <CartFontMenu
            id={id}
            triggerResize={triggerResize}
            onMouseUp={onMouseUp}
            setRange={setRange}
            keyDownEvent={keyDownEvent}
          />
        );
      case "fontMenu":
        return (
          <FontMenu
            id={id}
            triggerResize={triggerResize}
            onMouseUp={onMouseUp}
            setRange={setRange}
            keyDownEvent={keyDownEvent}
          />
        );
      case "colMenu":
        return <ColMenu id={id} triggerResize={triggerResize} />;
      case "linkMenu":
        return (
          <LinkMenu key="linkMenu" id={id} triggerResize={triggerResize} />
        );
      case "menuMenu":
        return <MenuMenu id={id} />;
      case "cartMenu":
        return <CartMenu id={id} />;
      case "logoMenu":
        return <LogoMenu id={id} />;
      case "borderMenu":
        return <BorderMenu triggerResize={triggerResize} id={id} />;
      case "logoBgMenu":
        return <LogoBgMenu triggerResize={triggerResize} id={id} />;
      case "spacingMenu":
        return <SpacingMenu triggerResize={triggerResize} id={id} />;
      case "backgroundMenu":
        return <BackgroundMenu triggerResize={triggerResize} id={id} />;
      case "textShadowMenu":
        return <TextShadowMenu id={id} />;
      case "contentWidthMenu":
        return <ContentWidthMenu id={id} />;
    }
  }

  function setMenu(menu: string) {
    if (activeMenu === menu) {
      setActiveMenu(null);
    } else {
      setActiveMenu(menu);
    }
  }

  function deleteComponent(component: AllComponents) {
    StructureAPI.deleteComponent(component.webpage_id, component).then((x) => {
      if (!x.hasOwnProperty("message")) setEditableComponentId(null);
    });
  }

  function duplicateComponent(component: AllComponents) {
    StructureAPI.duplicateComponent(component.webpage_id, component).then(
      (x) => {
        deleteComponentById(editorId);
        addNewComponent({
          id: editorId,
          type: "editor",
          sort_order: 1,
          parent_id: x.payload[Object.keys(x.payload)[0]].id,
          childElements: [],
        } as any);
        setEditableComponentId(x.payload[Object.keys(x.payload)[0]].id);
        setTimeout(() => {
          let y = document
            .getElementById(x.payload[Object.keys(x.payload)[0]].id.toString())
            ?.querySelectorAll("[contenteditable]")[0] as HTMLElement;
          if (y) {
            let s = window.getSelection();
            let r = document.createRange();
            r.selectNodeContents(y);
            r.collapse(false);
            s?.removeAllRanges();
            s?.addRange(r);
          }
        }, 500);
      }
    );
  }

  // editorDraghandle
  // const handle = document.getElementById('handle');
  // handle.onmousedown = function(e) {
  //     e.target.parentNode.setAttribute('draggable', 'true')
  // };

  // componentDidMount() {
  // When the component is mounted, add your DOM listener to the "nv" elem.
  // (The "nv" elem is assigned in the render function.)
  // console.log(this);
  // this.nv.addEventListener("nv-enter", this.handleNvEnter);
  // }

  // componentWillUnmount() {
  // Make sure to remove the DOM listener when the component is unmounted.
  // this.nv.removeEventListener("nv-enter", this.handleNvEnter);
  // }

  // https://stackoverflow.com/questions/73251435/drag-and-drop-cells-on-css-grid-only-works-properly-when-moving-a-cell-to-the-ri

  useEffect(() => {
    const builderElement = inClass("builder-component", ref.current!);
    const editorDraghandle = ref.current?.querySelector(".editorDraghandle");

    const onMouseDown = (e) => {
      builderElement?.setAttribute("draggable", "true");
    };

    const onMouseUp = (e) => {
      builderElement?.setAttribute("draggable", "false");
    };

    const onDragStart = (e) => {
      if (
        !inClass("builder-component", builderElement?.parentElement!) &&
        e.target.classList.contains("row")
      ) {
        console.log(e.target.setAttribute("data-drag-type", "main"));
      } else {
        // Get the dragged element parent id
        e.dataTransfer.setData(
          "id",
          inClass("builder-component", builderElement?.parentElement!)!.id
        );
      }

      e.dataTransfer.effectAllowed = "move";
    };

    const onDragEnd = (e) => {
      builderElement?.setAttribute("draggable", "false");
      let dropTargets = Array.from(
        document.querySelectorAll(".builder-component")
      );
      dropTargets.forEach((dropTarget) => {
        dropTarget.classList.remove("dragover");
      });
    };

    const handleDragEnter = (e) => {
      e.preventDefault();
      // e.target.classList.add("dragover");

      e.target.addEventListener("dragover", handleDragOverPosition);

      // handleSortingPosition(e);
    };

    const handleDragOverPosition = (e) => {
      e.preventDefault();

      let targetElement = e.target;
      const rect = targetElement.getBoundingClientRect();
      // Get the bounding rectangle of the target element

      ////////////////////
      // TODO: NEEDS REFACTOR

      // The top, middle, and bottom positions as percentages (drop locations)
      // Can be adjusted to change the drop position (33.3, 33.3, 33.3) might work also.
      const topPosition = 0; // Not needed (always 0)
      const middlePosition = 25; // 25% down from the top
      const bottomPosition = 75; // 75% down from the top

      // Get the current Y position of the mouse pointer relative to the target element
      const mouseY = e.clientY - rect.top;

      // Show only same parent drop locations, others are not visible
      if (e.target.offsetParent === builderElement?.offsetParent) {
        const topPosition = 0; // Not needed (always 0)
        const middlePosition = 33.3; // 33.3% down from the top
        const bottomPosition = 66.6; // 66.6% down from the top

        //
        if (mouseY < middlePosition) {
          builderElement?.setAttribute("data-position", "top");
          e.target.style.webkitBoxShadow =
            "inset 0px 7px 0px -2px rgba(0, 115, 255, 1)";
          e.target.style.mozBoxShadow =
            "inset 0px 7px 0px -2px rgba(0, 115, 255, 1)";
          e.target.style.boxShadow =
            "inset 0px 7px 0px -2px rgba(0, 115, 255, 1)";
        } else if (mouseY >= middlePosition && mouseY < bottomPosition) {
          e.target.style.webkitBoxShadow =
            "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";
          e.target.style.mozBoxShadow =
            "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";
          e.target.style.boxShadow =
            "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";

          // Set the position to the builderElement
          builderElement?.setAttribute("data-position", "middle");
        } else {
          // e.target.style.borderTop = `0px solid blue`;
          // e.target.style.borderBottom = `4px solid blue`;

          e.target.style.webkitBoxShadow =
            "inset 0px -7px 0px -2px rgba(0, 115, 255, 1)";
          e.target.style.mozBoxShadow =
            "inset 0px -7px 0px -2px rgba(0, 115, 255, 1)";
          e.target.style.boxShadow =
            "inset 0px -7px 0px -2px rgba(0, 115, 255, 1)";

          // Set the position to the builderElement
          builderElement?.setAttribute("data-position", "bottom");
        }
      }

      // IF function to check whether the target is a section
      if (e.target.getAttribute("data-component-type") === "section") {
        // Means the target is a section, which should show the drop position (including middle)

        // Check if we are draggin a text

        if (mouseY < middlePosition) {
          // Set the position to the builderElement
          builderElement?.setAttribute("data-position", "top");

          e.target.style.webkitBoxShadow =
            "inset 0px 7px 0px -2px rgba(0, 115, 255, 1)";
          e.target.style.mozBoxShadow =
            "inset 0px 7px 0px -2px rgba(0, 115, 255, 1)";
          e.target.style.boxShadow =
            "inset 0px 7px 0px -2px rgba(0, 115, 255, 1)";

          // e.target.style.borderBottom = `0px solid blue`;
        } else if (mouseY >= middlePosition && mouseY < bottomPosition) {
          e.target.style.webkitBoxShadow =
            "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";
          e.target.style.mozBoxShadow =
            "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";
          e.target.style.boxShadow =
            "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";

          // Set the position to the builderElement
          builderElement?.setAttribute("data-position", "middle");
        } else {
          e.target.style.webkitBoxShadow =
            "inset 0px -7px 0px -2px rgba(0, 115, 255, 1)";
          e.target.style.mozBoxShadow =
            "inset 0px -7px 0px -2px rgba(0, 115, 255, 1)";
          e.target.style.boxShadow =
            "inset 0px -7px 0px -2px rgba(0, 115, 255, 1)";

          // Set the position to the builderElement
          builderElement?.setAttribute("data-position", "bottom");
        }

        return;
      }
    };

    const handleDragLeave = (e) => {
      // Remove styles & classes from target when leave
      e.target?.classList.remove("dragover");
      e.target.style.webkitBoxShadow =
        "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";
      e.target.style.mozBoxShadow =
        "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";
      e.target.style.boxShadow = "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";
      e.target.removeEventListener("dragover", handleDragOverPosition);
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.dataTransfer!.dropEffect = "move";
      return false;
    };

    const ondrop = (e) => {
      e.preventDefault();
      e.stopPropagation();

      let parent_id = e.target.getAttribute("id");

      // Remove the styles that were left on the target
      e.target.style.webkitBoxShadow =
        "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";
      e.target.style.mozBoxShadow =
        "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";
      e.target.style.boxShadow = "inset 0px 0px 0px 0px rgba(0, 115, 255, 1)";

      // TODO: Needs refactoring

      // 1) Check if the element we are dragging is a section or text
      if (
        !builderElement?.getAttribute("data-component-type") ||
        builderElement?.getAttribute("data-component-type") !== "section"
      ) {
        if (
          parent_id === e.dataTransfer.getData("id") ||
          e.target.offsetParent.id === e.dataTransfer.getData("id")
        ) {
          if (
            parent_id &&
            builderElement?.getAttribute("data-position") === "top"
          ) {
            const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
            const destinationId = parent_id; // ID of the element, which we dragged on top

            updateComponentStructureOrder(
              parentComponent.id,
              draggedComponentId,
              destinationId,
              "top"
            );
          }

          if (
            parent_id &&
            builderElement?.getAttribute("data-position") === "bottom"
          ) {
            const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
            const destinationId = parent_id; // ID of the element, which we dragged on top

            updateComponentStructureOrder(
              parentComponent.id,
              draggedComponentId,
              destinationId,
              "bottom"
            );
          }
        } else if (e.target.getAttribute("data-component-type") === "section") {
          addChildToParent(parent_id, component.id);
          removeChildFromParent(e.dataTransfer.getData("id"), component.id);
        } else if (
          e.target.offsetParent.getAttribute("data-component-type") ===
          "section"
        ) {
          const offsetParent = e.target.offsetParent.getAttribute("id");

          addChildToParent(offsetParent, component.id);
          removeChildFromParent(e.dataTransfer.getData("id"), component.id);
        }
      }

      // Check if the element is dropped onto itself (returning)
      if (parent_id === builderElement?.getAttribute("id")) {
        return;
      }

      // If element is droppped on middle
      if (
        parent_id &&
        builderElement?.getAttribute("data-position") === "middle" &&
        e.target.getAttribute("data-component-type") === "section"
      ) {
        addChildToParent(parent_id, component.id);
      }

      // If element is dropped on top
      if (
        parent_id &&
        builderElement?.getAttribute("data-position") === "top" &&
        e.target.getAttribute("data-component-type") === "section"
      ) {
        const draggedComponentId = builderElement.getAttribute("id"); // ID of the element that was dragged
        const destinationId = parent_id; // ID of the element, which we dragged on top
        // const position = builderElement.getAttribute("data-position"); // Position of the component that was dragged

        if (builderElement.getAttribute("data-drag-type") === "main") {
          updateStructureWebpageComponentsOrder(
            draggedComponentId,
            destinationId,
            "top"
          );
        } else {
          updateComponentStructureOrder(
            parentComponent.id,
            draggedComponentId,
            destinationId,
            "top"
          );
        }
      }

      // If element is dropped on bottom
      if (
        parent_id &&
        builderElement?.getAttribute("data-position") === "bottom" &&
        e.target.getAttribute("data-component-type") !== "section" &&
        e.target.offsetParent.getAttribute("data-component-type") !== "section"
      ) {
        const draggedComponentId = builderElement.getAttribute("id"); // ID of the component that was dragged
        const destinationId = parent_id; // ID of the component, which we dragged on top

        if (builderElement.getAttribute("data-drag-type") === "main") {
          updateStructureWebpageComponentsOrder(
            draggedComponentId,
            destinationId,
            "bottom"
          );
        } else {
          updateComponentStructureOrder(
            parentComponent.id,
            draggedComponentId,
            destinationId,
            "bottom"
          );
        }
      }

      // removeChildFromParent(e.dataTransfer.getData("id"), component.id);
    };

    const ondragover = (e) => {
      e.preventDefault();
    };

    // Editor Event Listeners
    editorDraghandle?.addEventListener("mousedown", onMouseDown);
    editorDraghandle?.addEventListener("mouseup", onMouseUp);

    // BuilderElement Event Listeners
    builderElement?.addEventListener("dragstart", onDragStart);
    builderElement?.addEventListener("dragend", onDragEnd);

    // Define drop targets events
    let dropTargets = Array.from(
      document.querySelectorAll(".builder-component")
    );
    dropTargets.forEach((dropTarget) => {
      dropTarget.addEventListener("drop", ondrop);
      dropTarget.addEventListener("dragenter", handleDragEnter);
      dropTarget.addEventListener("dragleave", handleDragLeave);
      dropTarget.addEventListener("dragover", handleDragOver);
    });

    return () => {
      // Unbind the event listeners on cleanup
      document.removeEventListener("dragover", ondragover);
      editorDraghandle?.removeEventListener("mousedown", onMouseDown);
      editorDraghandle?.removeEventListener("mouseup", onMouseUp);
      builderElement?.removeEventListener("dragend", onDragEnd);
      builderElement?.removeEventListener("dragstart", onDragStart);

      // Remove drop targets event listeners
      dropTargets.forEach((dropTarget) => {
        dropTarget.removeEventListener("drop", ondrop);
        dropTarget.removeEventListener("dragenter", handleDragEnter);
        dropTarget.removeEventListener("dragleave", handleDragLeave);
        dropTarget.removeEventListener("dragover", handleDragOver);
      });
    };
  }, [() => ref]);

  // If ROW then different styles
  return (
    <>
      {["header", "logo", "nav", "nav-item", "cart"].includes(
        componentType
      ) && <div style={{ marginTop: editorPos + "px" }}></div>}
      <div
        id={editorId ? editorId.toString() : ""}
        ref={ref}
        className={"global-editor global-editor__menu"}
        style={{
          top: ["row", "logo", "nav", "nav-item", "cart", "header"].includes(
            componentType
          )
            ? "0px"
            : "-" + editorPos + "px",
          width: "auto",
        }}
      >
        <div className="global-editor global-editor__top-menu">
          <div className="col-12 d-flex flex-nowrap">
            {!["header", "logo", "nav", "nav-item", "cart"].includes(
              componentType
            ) && (
              <div className="global-editor global-editor__top-menu__item editorDraghandle">
                <SwapVertIcon />
              </div>
            )}
            {["nav-item", "Button"].includes(componentType) && (
              <div
                className={`global-editor global-editor__top-menu__item  ${
                  activeMenu == "linkMenu"
                    ? "global-editor__top-menu__item--active"
                    : ""
                }`}
                onClick={() => setMenu("linkMenu")}
              >
                <LinkIcon />
              </div>
            )}
            {componentType === "header" && (
              <div
                className={`global-editor global-editor__top-menu__item  ${
                  activeMenu == "logoMenu"
                    ? "global-editor__top-menu__item--active"
                    : ""
                }`}
                onClick={() => setMenu("logoMenu")}
              >
                <HomeIcon />
              </div>
            )}
            {componentType === "header" && (
              <div
                className={`global-editor global-editor__top-menu__item  ${
                  activeMenu == "menuMenu"
                    ? "global-editor__top-menu__item--active"
                    : ""
                }`}
                onClick={() => setMenu("menuMenu")}
              >
                <MenuIcon />
              </div>
            )}
            {componentType === "header" && (
              <div
                className={`global-editor global-editor__top-menu__item  ${
                  activeMenu == "cartMenu"
                    ? "global-editor__top-menu__item--active"
                    : ""
                }`}
                onClick={() => setMenu("cartMenu")}
              >
                <ShoppingCartIcon />
              </div>
            )}
            {["cart"].includes(componentType) && (
              <div
                className={`global-editor global-editor__top-menu__item ${
                  activeMenu == "cartFontMenu"
                    ? "global-editor__top-menu__item--active"
                    : ""
                }`}
                onClick={() => setMenu("cartFontMenu")}
              >
                <FormatColorTextIcon />
              </div>
            )}
            {["text", "nav-item", "Button"].includes(componentType) && (
              <div
                className={`global-editor global-editor__top-menu__item ${
                  activeMenu == "fontMenu"
                    ? "global-editor__top-menu__item--active"
                    : ""
                }`}
                onClick={() => setMenu("fontMenu")}
              >
                <FormatColorTextIcon />
              </div>
            )}
            {componentType === "col" && (
              <div
                className={
                  "global-editor global-editor__top-menu__item" +
                  (activeMenu === "colMenu"
                    ? " global-editor__top-menu__item--active"
                    : "")
                }
                onClick={() => setMenu("colMenu")}
              >
                <ViewColumnIcon />
              </div>
            )}
            <div
              className={
                "global-editor global-editor__top-menu__item" +
                (activeMenu === "spacingMenu"
                  ? " global-editor__top-menu__item--active"
                  : "")
              }
              onClick={() => setMenu("spacingMenu")}
            >
              <FitScreenOutlinedIcon />
            </div>
            <div
              className={
                "global-editor global-editor__top-menu__item" +
                (activeMenu === "borderMenu"
                  ? " global-editor__top-menu__item--active"
                  : "")
              }
              onClick={() => setMenu("borderMenu")}
            >
              <BorderStyleOutlinedIcon className="active" />
            </div>
            {componentType === "logo" && (
              <div
                className={
                  "global-editor global-editor__top-menu__item" +
                  (activeMenu === "logoBgMenu"
                    ? " global-editor__top-menu__item--active"
                    : "")
                }
                onClick={() => setMenu("logoBgMenu")}
              >
                <ImageOutlinedIcon />
              </div>
            )}
            {componentType !== "logo" && (
              <div
                className={
                  "global-editor global-editor__top-menu__item" +
                  (activeMenu === "backgroundMenu"
                    ? " global-editor__top-menu__item--active"
                    : "")
                }
                onClick={() => setMenu("backgroundMenu")}
              >
                <ImageOutlinedIcon />
              </div>
            )}
            {componentType === "text" && (
              <div
                className={
                  "global-editor global-editor__top-menu__item" +
                  (activeMenu === "textShadowMenu"
                    ? " global-editor__top-menu__item--active"
                    : "")
                }
                onClick={() => setMenu("textShadowMenu")}
              >
                <SelectAllOutlinedIcon />
              </div>
            )}
            {componentType === "row" &&
              !inClass(
                "builder-footer",
                document.getElementById(id.toString())!
              ) && (
                <div
                  className={
                    "global-editor global-editor__top-menu__item" +
                    (activeMenu === "contentWidthMenu"
                      ? " global-editor__top-menu__item--active"
                      : "")
                  }
                  onClick={() => setMenu("contentWidthMenu")}
                >
                  <WidthWideOutlinedIcon />
                </div>
              )}

            {template && (
              <div
                className={"global-editor global-editor__top-menu__item"}
                onClick={() => {
                  setCreateComponentTemplate(component);
                }}
              >
                <DashboardIcon />
              </div>
            )}

            {!["logo", "nav", "cart"].includes(componentType) && (
              <div className="global-editor global-editor__top-menu__item flex-grow-1 text-end position-relative">
                <MoreHorizOutlined
                  data-bs-toggle="dropdown"
                  onClick={() => {
                    setShowDropdown(!showDropdown);
                  }}
                />
                <ul
                  className={
                    "dropdown-menu b-dropdown-menu dropdown-menu-end builder-dropdown " +
                    (showDropdown ? "show" : "")
                  }
                >
                  {(!["row", "header"].includes(componentType) ||
                    (componentType === "row" &&
                      !inClass(
                        "builder-footer",
                        document.getElementById(id.toString())!
                      ))) && (
                    <li>
                      <span
                        className="dropdown-item builder-dropdown__item"
                        onClick={() => {
                          duplicateComponent(component);
                        }}
                      >
                        Create a copy
                      </span>
                    </li>
                  )}

                  {(!["row", "header", "footer"].includes(componentType) ||
                    (componentType === "row" &&
                      !inClass(
                        "builder-footer",
                        document.getElementById(id.toString())!
                      ))) && (
                    <>
                      {!templateId ? (
                        <li>
                          <span
                            className="dropdown-item builder-dropdown__item"
                            onClick={() => {
                              setCreateComponentTemplate(component);
                            }}
                          >
                            Save template
                          </span>
                        </li>
                      ) : (
                        <li>
                          <span
                            className="dropdown-item builder-dropdown__item"
                            onClick={() => {
                              setShowConfirmTemplateUnlink(true);
                            }}
                          >
                            Unlink template
                          </span>
                        </li>
                      )}
                    </>
                  )}
                  <li>
                    <span
                      className="dropdown-item builder-dropdown__item"
                      onClick={() => setShowConfirmation(true)}
                    >
                      Remove
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        {activeMenu && (
          <div className="global-editor global-editor__bottom-menu b-p-1">
            {getBottomMenu()}
          </div>
        )}
        {showConfirmation && (
          <Confirmation
            id={id.toString()}
            onCancel={() => {
              setShowConfirmation(false);
            }}
            onConfirm={() => {
              // if (componentType === "row" && inClass("builder-footer", document.getElementById(id))) {
              //   deleteComponent(parseInt(document.getElementsByClassName("builder-footer")[0].id))
              // } else {
              deleteComponent(component);
              // }
            }}
            text="Are you sure you want to remove this element?"
          />
        )}
        {showConfirmTemplateUnlink && (
          <Confirmation
            id={id.toString()}
            onCancel={() => {
              setShowConfirmTemplateUnlink(false);
            }}
            onConfirm={() => {
              unlinkTemplate();
            }}
            confirmText="Unlink"
            text="Are you sure you want to unlink this element from template? After nulinking, changing this element will not change the template."
          />
        )}
        {createComponentTemplate && (
          <CreateTemplateModal
            createComponentTemplate={createComponentTemplate}
            template={template}
            setCreateComponentTemplate={setCreateComponentTemplate}
          />
        )}
      </div>

      <div className="add-new-element-buttons">
        <div className="p-0">
          {componentType === "col" && (
            <Dropdown onToggle={() => {}} drop="up">
              <Dropdown.Toggle>
                {(props) => (
                  <span
                    {...props}
                    className="add-new-element-button elementB inline-block"
                  >
                    <AddIcon />
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu offset={[0, -19]}>
                {(props, { show }) =>
                  createPortal(
                    <div {...props} className="builder-dropdown">
                      <SelectElementType component={component} />
                    </div>,
                    document.body
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
          )}
          {componentType === "row" && (
            <div
              className="add-new-element-button colB"
              style={{ display: "inline-block" }}
              onClick={() =>
                StructureAPI.addComponent(component.webpage_id, {
                  type: component.type,
                  sort_order: component.sort_order + 1,
                  attributes: [
                    { name: "classes", value: "col col-4", type: "attributes" },
                    { name: "paddingTop", value: "60px", type: "styles" },
                    { name: "paddingLeft", value: "60px", type: "styles" },
                    { name: "paddingBottom", value: "60px", type: "styles" },
                    { name: "paddingRight", value: "60px", type: "styles" },
                  ],
                  parent_id: component.id,
                }).then((x) => {
                  deleteComponentById(editorId);
                  addNewComponent({
                    id: editorId,
                    type: "editor",
                    sort_order: 1,
                    parent_id: x.payload[Object.keys(x.payload)[0]].id,
                    childElements: [],
                  } as any);
                  setEditableComponentId(
                    x.payload[Object.keys(x.payload)[0]].id
                  );
                })
              }
            >
              <AddIcon />
            </div>
          )}
          {["col", "row"].includes(componentType) && (
            <Dropdown onToggle={() => {}} drop="up">
              <Dropdown.Toggle>
                {(props) => (
                  <span
                    {...props}
                    className={
                      "inline-block add-new-element-button " +
                      (componentType === "col" ? "elementB" : "colB")
                    }
                  >
                    <LibraryAddIcon />
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu offset={[0, -19]}>
                {(props, { show }) =>
                  createPortal(
                    <div {...props} className="builder-dropdown">
                      <SelectTemplate
                        key={"childTemplate"}
                        component={component}
                        sort_order={component.sort_order}
                      />
                    </div>,
                    document.body
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <div className="p-0">
          {["col", "row"].includes(componentType) && (
            <div
              style={{ display: "inline-block" }}
              className={
                "add-new-element-button " +
                (componentType === "col" ? "colB" : "rowB")
              }
              onClick={() => {
                if (componentType === "col") {
                  StructureAPI.addComponent(component.webpage_id, {
                    type: component.type,
                    sort_order: component.sort_order + 1,
                    attributes: [
                      {
                        name: "classes",
                        value: "col col-4",
                        type: "attributes",
                      },
                      { name: "paddingTop", value: "60px", type: "styles" },
                      { name: "paddingLeft", value: "60px", type: "styles" },
                      { name: "paddingBottom", value: "60px", type: "styles" },
                      { name: "paddingRight", value: "60px", type: "styles" },
                    ],
                    parent_id: component.parent_id,
                  }).then((x) => {
                    deleteComponentById(editorId);
                    addNewComponent({
                      id: editorId,
                      type: "editor",
                      sort_order: 1,
                      parent_id: x.payload[Object.keys(x.payload)[0]].id,
                      childElements: [],
                    } as any);
                    setEditableComponentId(
                      x.payload[Object.keys(x.payload)[0]].id
                    );
                  });
                } else if (componentType === "row") {
                  StructureAPI.addComponent(component.webpage_id, {
                    type: component.type,
                    sort_order: component.sort_order + 1,
                    attributes: [
                      { name: "classes", value: "row", type: "attributes" },
                      { name: "paddingTop", value: "60px", type: "styles" },
                      { name: "paddingLeft", value: "60px", type: "styles" },
                      { name: "paddingBottom", value: "60px", type: "styles" },
                      { name: "paddingRight", value: "60px", type: "styles" },
                      {
                        name: "content_width_unit",
                        value: website?.content_width_unit,
                        type: "attributes",
                      },
                      {
                        name: "content_width_size",
                        value: website?.content_width_size,
                        type: "attributes",
                      },
                    ],
                    parent_id: component.parent_id,
                    childElements: [
                      {
                        type: "section",
                        sort_order: 1,
                        attributes: [
                          { name: "classes", value: "col", type: "attributes" },
                          { name: "paddingTop", value: "60px", type: "styles" },
                          {
                            name: "paddingLeft",
                            value: "60px",
                            type: "styles",
                          },
                          {
                            name: "paddingBottom",
                            value: "60px",
                            type: "styles",
                          },
                          {
                            name: "paddingRight",
                            value: "60px",
                            type: "styles",
                          },
                        ],
                      },
                    ],
                  }).then((x) => {
                    deleteComponentById(editorId);
                    addNewComponent({
                      id: editorId,
                      type: "editor",
                      sort_order: 1,
                      parent_id: x.payload[Object.keys(x.payload)[0]].id,
                      childElements: [],
                    } as any);
                    setEditableComponentId(
                      x.payload[Object.keys(x.payload)[0]].id
                    );
                  });
                }
              }}
            >
              <AddIcon />
            </div>
          )}
          {!["row", "col"].includes(componentType) && parentComponent && (
            <Dropdown onToggle={() => {}} drop="up">
              <Dropdown.Toggle>
                {(props) => (
                  <span
                    {...props}
                    className="add-new-element-button elementB inline-block"
                  >
                    <AddIcon />
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu offset={[0, -19]}>
                {(props, { show }) =>
                  createPortal(
                    <div {...props} className="builder-dropdown">
                      <SelectElementType component={parentComponent} />
                    </div>,
                    document.body
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
          )}

          {/* parentComponent && ['col', 'row'].includes(parentComponentType) &&
            <div className='position-relative dropright' style={{ display: 'inline-block' }}>
              <div className={'add-new-element-button '+(componentType === 'col' ? 'colB' : 'elementB')} data-bs-toggle='dropdown'>
                <LibraryAddIcon />
              </div>
              <SelectTemplate key={"parentTemplate"} component={parentComponent} sort_order={component.sort_order} />
            </div>
            */}

          <Dropdown onToggle={() => {}} drop="up">
            <Dropdown.Toggle>
              {(props: any) => (
                <span
                  {...props}
                  className={
                    "inline-block add-new-element-button " +
                    (componentType === "col"
                      ? "colB"
                      : componentType === "row"
                      ? "rowB"
                      : "elementB")
                  }
                >
                  <LibraryAddIcon />
                </span>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu offset={[0, -19]}>
              {(props: any, { show: any }) =>
                createPortal(
                  <div {...props} className="builder-dropdown">
                    <SelectTemplate
                      key={"parentTemplate"}
                      component={parentComponent}
                      sort_order={parentComponent?.sort_order ?? 9}
                    />
                  </div>,
                  document.body
                )
              }
            </Dropdown.Menu>
          </Dropdown>

          <div
            style={{ display: "inline-block" }}
            className={
              "add-new-element-button " +
              (componentType === "col"
                ? "colB"
                : componentType === "row"
                ? "rowB"
                : "elementB")
            }
            onClick={() => {
              duplicateComponent(component);
            }}
          >
            <ContentCopyIcon />
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(Editor);
