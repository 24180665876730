import React, { useState } from "react";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { findComponentById } from "../../../../../utils/Helpers";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import Select from "react-select";
import PagesAPI from "../../../../../store/reducers/builderReducers/pages/pagesApi";
import { MoreHorizOutlined } from "@mui/icons-material";
import { useAppSelector } from "../../../../../store/hooks";
import {
  NavItemComponent,
  Link,
  ButtonComponent,
} from "../../../../_default/interfaces/base";
import { Pages } from "../../../../../store/reducers/builderReducers/pages/pagesApi";

interface Props {
  id: number;
  triggerResize: () => void;
}

export default function LinkMenu({ id, triggerResize }: Props) {
  console.log("LinkMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as NavItemComponent & ButtonComponent;
  const webpages = useAppSelector(
    (state) => state.builder.pages.list as Pages["list"]
  );
  const dropdownItems = component.attributes.links ?? [];
  const [expanded, setExpanded] = useState(false);
  const [webpagesList, setWebpagesList] = useState(
    webpages.map((x) => {
      return { label: x.name, value: x.url };
    })
  );

  const [url, setUrl] = useState<null | string>(component.attributes.link?.url);
  const [target, setTarget] = useState<null | string>(
    component.attributes.link?.target
  );
  const [type, setType] = useState<null | string>(
    component.attributes.link?.type
  );
  const [buttonType, setButtonType] = useState<null | string>(
    component.attributes.type
  );

  const refType = React.useRef(type);
  const refUrl = React.useRef(url);
  const reftarget = React.useRef(target);
  const refbuttonType = React.useRef(buttonType);
  React.useEffect(() => {
    refType.current = type;
    refUrl.current = url;
    reftarget.current = target;
    refbuttonType.current = buttonType;
  }, [url, target, type, buttonType]);

  const closure = () => {
    console.log("save link");
    if (type || buttonType) {
      updateStructureComponent(id, "attributes", {
        ...component.attributes,
        link: {
          url: refUrl.current,
          type: refType.current,
          target: reftarget.current,
        },
        ...(component.type === "Button" && { type: refbuttonType.current }),
      });
    }
  };

  React.useLayoutEffect(() => {
    return closure;
  }, []);

  function addDropdownItem() {
    updateStructureComponent(id, "attributes.links", [
      ...dropdownItems,
      { url: "www.google.ee", type: "Web", target: "_blank" },
    ]);
  }

  function updateDropdownItem(i: number, params: Link & { text: string }) {
    var dropdownItems_ = [...dropdownItems];
    dropdownItems_[i] = { ...dropdownItems_[i], ...params };
    updateStructureComponent(id, "attributes.links", dropdownItems_);
  }

  function deleteDropdownItem(i: number) {
    var dropdownItems_ = [...dropdownItems];
    dropdownItems_.splice(i, 1);
    updateStructureComponent(id, "attributes.links", dropdownItems_);
  }

  let types = [
    { label: "Page", value: "Page" },
    { label: "Web", value: "Web" },
    { label: "None", value: "None" },
  ];

  if (component.type === "nav-item") {
    types = [
      ...types,
      { label: "Anchor", value: "Anchor" },
      { label: "Phone", value: "Phone" },
      { label: "Email", value: "Email" },
      { label: "Dropdown", value: "Dropdown" },
    ];
  }

  const linkOpen = [
    { label: "Open new tab", value: "_blank" },
    { label: "Open same tab", value: "_self" },
  ];

  const buttonTypes = [
    { label: "Button", value: "button" },
    { label: "Submit", value: "submit" },
  ];

  return (
    <div className="d-flex b-gap-3">
      <div className={"d-flex flex-wrap b-gap-3"}>
        {component.type === "Button" && (
          <Select
            onChange={(e) => {
              setButtonType(e!.value);
              if (e?.value === "submit") {
                setType(null);
                setTarget(null);
                setUrl(null);
              } else {
                setType("Page");
                setTarget("_self");
              }
            }}
            isSearchable={false}
            className="m-0 link-menu_input"
            styles={{
              ...TextEditorSelectStyle,
              container: (base) => ({
                ...base,
                minWidth: "30px",
              }),
            }}
            noOptionsMessage={({}) => ""}
            value={buttonTypes.find((x) => x.value === buttonType)}
            options={buttonTypes}
            placeholder=""
          ></Select>
        )}
        {((component.type === "Button" && buttonType == "button") ||
          component.type === "nav-item") && (
          <Select
            onChange={(e) => {
              setType(e!.value);
              if (e!.value === "Page") {
                setUrl(webpagesList[0].value);
              } else if (type === "Page") {
                setUrl("www.google.ee");
              }
            }}
            isSearchable={false}
            className="m-0 link-menu_input"
            styles={{
              ...TextEditorSelectStyle,
              container: (base) => ({
                ...base,
                minWidth: "30px",
              }),
            }}
            noOptionsMessage={({}) => ""}
            value={types.find((x) => x.value === type)}
            options={types}
            placeholder=""
          ></Select>
        )}
        {type === "Page" && (
          <Select
            onChange={(e) => setUrl(e!.value)}
            isSearchable={false}
            className="m-0 link-menu_input"
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={webpagesList.find((x) => x.value === url)}
            options={webpagesList}
            placeholder=""
          ></Select>
        )}
        {["Anchor", "Phone", "Email", "Web"].includes(type!) && (
          <input
            className="global-editor__input builder-input link-menu_input"
            size={5}
            value={url ?? ""}
            onChange={(e) => setUrl(e.target.value)}
          />
        )}
        {expanded && (
          <Select
            onChange={(e) => setTarget(e!.value)}
            isSearchable={false}
            className="m-0 link-menu_input"
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={linkOpen.find((x) => x.value === target)}
            options={linkOpen}
            placeholder=""
          ></Select>
        )}
      </div>
      <div className={"text-end flex-grow-1"}>
        {type !== "Dropdown" && (
          <MoreHorizOutlined
            style={{ fontSize: "18px" }}
            onClick={() => {
              setExpanded(!expanded);
              triggerResize();
            }}
          />
        )}
        {type === "Dropdown" && (
          <a
            className="hover-underline"
            style={{ color: "#fff" }}
            onClick={() => addDropdownItem()}
          >
            + Add
          </a>
        )}
      </div>
      {type === "Dropdown" &&
        dropdownItems.map((item, i) => (
          <div
            className={
              "col-12 d-flex b-gap-1 b-mb-1" + (i === 0 ? " b-mt-1" : "")
            }
            key={i}
          >
            <Select
              onChange={(e) => {
                updateDropdownItem(i, {
                  ...item,
                  type: e!.value,
                  url: e!.value === "Page" ? webpagesList[0].value : item.url,
                });
              }}
              isSearchable={false}
              className="m-0"
              styles={{
                ...TextEditorSelectStyle,
                container: (base) => ({
                  ...base,
                  minWidth: "30px",
                }),
              }}
              noOptionsMessage={({}) => ""}
              value={types.find((x) => x.value === item.type)}
              options={types.filter((x) => x.value !== "Dropdown")}
              placeholder=""
            ></Select>
            <input
              className="global-editor__input builder-input"
              placeholder="Label"
              size={5}
              value={item.text}
              onChange={(e) =>
                updateDropdownItem(i, { ...item, text: e.target.value })
              }
            />
            {["Anchor", "Phone", "Email", "Web"].includes(item.type) && (
              <input
                className="global-editor__input builder-input flex-grow-1"
                placeholder="path"
                size={5}
                value={item.url}
                onChange={(e) =>
                  updateDropdownItem(i, { ...item, url: e.target.value })
                }
              />
            )}
            {item.type === "Page" && (
              <Select
                onChange={(e) =>
                  updateDropdownItem(i, { ...item, url: e!.value })
                }
                maxMenuHeight={150}
                isSearchable={false}
                className="m-0"
                styles={TextEditorSelectStyle}
                noOptionsMessage={({}) => ""}
                value={webpagesList.find((x) => x.value === item.url)}
                options={webpagesList}
                placeholder=""
              ></Select>
            )}
            {item.type !== "None" && (
              <Select
                onChange={(e) =>
                  updateDropdownItem(i, { ...item, target: e!.value })
                }
                isSearchable={false}
                className="m-0"
                styles={TextEditorSelectStyle}
                noOptionsMessage={({}) => ""}
                value={linkOpen.find((x) => x.value === item.target)}
                options={linkOpen}
                placeholder=""
              ></Select>
            )}
            <span
              className="text-end flex-grow-1 pe-1 fw-bold clickable"
              style={{ fontWeight: "13px", minWidth: "10px" }}
              onClick={() => deleteDropdownItem(i)}
            >
              -
            </span>
          </div>
        ))}
    </div>
  );
}
