import React, { useState } from "react";
import { Delete } from "@mui/icons-material";

import "./DeleteConfirmModal.scss";

interface DeleteConfirmModalProps {
  onConfirm: () => void;
  title?: string;
  isOverflow?: boolean;
  text?: string;
}

export default function DeleteConfirmModal({
  text,
  onConfirm,
  title,
  isOverflow,
}: DeleteConfirmModalProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="delete-confirm-container">
      <div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
        <Delete
          style={{ fontSize: "17px", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        />

        <span style={{ marginLeft: "5px", marginTop: "2px" }}>
          {text ? text : ""}
        </span>
      </div>

      {isOpen && (
        <div className="delete-confirm-modal">
          <span className="delete-confirm-heading">
            {title ? title : "Are you sure you want to delete this?"}
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <button className="delete-confirm-delete" onClick={onConfirm}>
              Delete
            </button>
            <button
              className="delete-confirm-cancel"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {isOpen && isOverflow && (
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute" }}>
            <div
              className="delete-confirm-modal"
              onClick={(e) => e.stopPropagation()}
              style={{
                position: "fixed",
              }}
            >
              <div
                style={{
                  backgroundColor: "#333",
                  zIndex: 999,
                }}
              >
                <span className="delete-confirm-heading">
                  {title ? title : "Are you sure you want to delete this?"}
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <button className="delete-confirm-delete" onClick={onConfirm}>
                    Delete
                  </button>
                  <button
                    className="delete-confirm-cancel"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
