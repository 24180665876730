
import { Color } from './colorApi'
import { Set } from 'immutable'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: Set<Color> = Set([])

export const colorSlice = createSlice({
  name: 'colors',
  initialState,
  reducers: {
    UPDATE_COLORS: (state: any, action: PayloadAction<Color[]>) => {
        // console.log('UPDATE_COLORS')
        state = Set(action.payload)
        return state
    },
    ADD_COLOR: (state: any, action: PayloadAction<Color>) => {
        return Set([...state, action.payload])
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  UPDATE_COLORS,
  ADD_COLOR,
} = colorSlice.actions

export default colorSlice.reducer
