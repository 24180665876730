import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import { LanguageSetting } from "../../../../../store/reducers/builderReducers/languageSettings/languageSettingsApi";

interface Props {
  languageSetting: LanguageSetting;
  handleEdit: (obj: {}) => void;
}

export default function LanguagesSettingsFormView({
  handleEdit,
  languageSetting,
}: Props) {
  return (
    <>
      <div className="b-row b-mt-4">
        <div className="col-10 fw-bold">Settings</div>
        <div className="col-2 b-pt-1 text-end">
          <a
            className=" languages-button"
            onClick={() => handleEdit({ settings: true })}
          >
            Edit
          </a>
        </div>
      </div>
      <div className="b-row b-mt-3">
        <div className="col-1">
          {languageSetting!.useBrowserLanguage ? (
            <Check style={{ color: "rgb(72, 227, 72)" }} />
          ) : (
            <Close style={{ color: "#f53131" }} />
          )}
        </div>
        <div className="col-11 b-ps-3">
          <label>Set website language according to browser language</label>
        </div>
      </div>
    </>
  );
}
