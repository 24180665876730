import { useLayoutEffect, useRef } from 'react'

interface Props {
  className?: string,
  children: JSX.Element[] | JSX.Element,
  isOpen?: (v: boolean) => void
}

export default function Dropdown({ className, children, isOpen}: Props) {
  const ref = useRef<any>(null)

  useLayoutEffect(() => {
    var obs = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation: any) {
        if (mutation.target.classList.contains('show') && ref.current) {
          if (isOpen) isOpen(true)
          const bounds = ref.current.getBoundingClientRect()
          if (bounds.top < 0) {
            ref.current.style.inset = '0px auto auto 0px'
            ref.current.style.transform = 'translate(0px, 20px)'
          } else {
            ref.current.style.inset = 'auto auto 0px 0px'
            ref.current.style.transform = 'translate3d(0px, -17.6364px, 0px)'
          }
          //Remove if header ok
          setTimeout(() => {
            if (ref.current.getBoundingClientRect().top < 0) {
              ref.current.style.inset = '0px auto auto 0px'
              ref.current.style.transform = 'translate(0px, 20px)'
            }
          }, 1)
        } else {
          if (isOpen) isOpen(false)
        }
      })
    })
    obs.observe(ref.current, { attributes: true, attributeFilter: ['class'] })
  }, [])
  
  return (
    <div ref={ref} className={(className ?? '') + ' b-dropdown-menu dropdown-menu builder-dropdown'}>
      {children}
    </div>
  )
}
