import { useEffect } from 'react'
import { useAppSelector } from '../../store/hooks'
import { Helmet } from 'react-helmet'

export default function MetadataComponent({
  page_seo,
}) {
  const base_font_size = useAppSelector((state) => state.builder.website.base_font_size ?? '10px')

  useEffect(() => {
    document.body.style.fontSize = base_font_size
    document.getElementsByTagName('html')[0].style.fontSize = base_font_size
  })

  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{page_seo.seo_title}</title>
      <meta name='description' content={page_seo.seo_description} />
      <meta name="robots" content={page_seo.seo_no_index ? 'noindex, nofollow' : 'index, follow'} />
      <meta name="keywords" content={page_seo.seo_keyword} />
      <meta name="og:title" content={page_seo.facebook_title} />
      <meta name="og:description" content={page_seo.facebook_description} />
      <meta name="og:image" content={page_seo.image} />
    </Helmet>
  )
}
