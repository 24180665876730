import { pf } from "../utilities/helpers"
import { DeliveryMethod } from '../context/ShoppingCartContext'

interface Props {
  deliveryMethod: DeliveryMethod | null
  setShowDeliveryModal: (val: boolean) => void
  setModalDeliveryMethod: (val: DeliveryMethod | null) => void
}

export default function CheckoutDeliveryMethodButton({ deliveryMethod, setShowDeliveryModal, setModalDeliveryMethod }: Props) {
  return (
    <div className='col-xs-12 margin-bottom-10'>
      <button
        className='btn btn-round btn-primary-outline text-left checkoutDeliveryButton'
        style={{ width: '100%' }}
        onClick={() => {
          setShowDeliveryModal(true)
          setModalDeliveryMethod(deliveryMethod)
        }}>
        {deliveryMethod?.name}
        <span className='pull-right'> {pf(deliveryMethod!.deliveryMethodPrice.price)} &euro;</span>
      </button>
    </div>
  )
}
