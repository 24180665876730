import React, { useState } from "react";
import "./DropdownModal.scss";

import { MoreVert } from "@mui/icons-material";

export default function DropdownModal({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <MoreVert />
      </div>
      {isOpen && <div className="dropdown-modal">{children}</div>}
    </div>
  );
}
