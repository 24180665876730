import React, { useState } from 'react'
import { ProductCategory } from '../context/ShoppingCartContext'
import './Category.css'

interface Props {
  productCategory: ProductCategory
  productCategoryId: number | null
  setProductCategoryId: (val: number) => void
}

export default function CategoryComponent({ productCategory, productCategoryId, setProductCategoryId }: Props) {
  const [open, setOpen] = useState<boolean>(false)

  const renderChildren = (productCategory: ProductCategory) => {
    let result = productCategory.cashregisterChildren.map((productCategory) => {
      return (
        <a
          href={undefined}
          onClick={() => onCategoryClick(productCategory.id)}
          key={productCategory.id}
          className={`dropdown-item${productCategoryId === productCategory.id ? ' active' : ''}`}
          data-value={productCategory.id}>
          {productCategory.name}
        </a>
      )
    })
    if (React.isValidElement(result) || typeof result === 'string') {
      return result
    } else {
      return result
    }
  }

  const onCategoryClick = (value: number) => {
    setProductCategoryId(value)
    if (productCategory.id == value) {
      setOpen(!open)
    }
  }

  return (
    <li className='categoryDropdown'>
      <a
        // href={"#"+productCategory.id}
        onClick={() => {
          onCategoryClick(productCategory.id)
        }}
        key={productCategory.id}
        className={`category-name collapsed${productCategoryId === productCategory.id ? ' active' : ''}`}
        data-toggle='collapse'
        data-value={productCategory.id}
        aria-expanded='true'
        role='button'>
        {productCategory.name}
      </a>
      {productCategory.cashregisterChildren.length > 0 && (
        <div className={`collapse${open ? ' show' : ''}`} id={productCategory.id.toString()} role='menu'>
          <div className='panel-container'>{renderChildren(productCategory)}</div>
        </div>
      )}
    </li>
  )
}
