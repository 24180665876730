import { StylesConfig } from 'react-select'

export const DefaultReactSelectStyle: StylesConfig<any, false> = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  clearIndicator: (base) => ({
    ...base,
    padding: '0px',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0px',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: 'transparent',
    border: 0,
    minHeight: 'inherit',
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      borderColor: state.isFocused ? '#fff' : '#9b9b9b',
    },
  }),
  container: (base, state) => ({
    ...base,
    borderBottom: state.isFocused ? '2px solid #fff' : '1px solid #9b9b9b',
    marginBottom: state.isFocused ? '0px' : '1px',
  }),
  menu: (base, state) => ({
    ...base,
    backgroundColor: 'hsl(0deg 0% 28%)',
    width: 'max-content',
    minWidth: '100%',
    fontSize: '12px',
    '.MuiSvgIcon-root': {
      fontSize: '15px'
    }
  }),
  option: (base, state) => ({
    ...base,
    padding: '4px 8px',
    color: '#fff',
    backgroundColor: state.isFocused ? 'hsl(0deg 1% 19%)' : 'inherit',
    '&:active': {
      backgroundColor: state.isFocused ? 'hsl(0deg 1% 18%);' : 'inherit',
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: '#fff',
  }),
  input: (base) => ({
    ...base,
  }),
}

export const TextEditorSelectStyle: StylesConfig<any, false> = {
  ...DefaultReactSelectStyle,
  menuList: (styles, data) => {
    return {
      ...styles,
      overflowX: 'hidden',
    }
  },
  container: (styles, data) => {
    return {
      ...styles,
    }
  },
  singleValue: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.singleValue!(styles, data),
      overflow: 'visible'
    }
  },
  option: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.option!(styles, data),
      padding: '1px 2px',
      textAlign: 'center',
    }
  },
  control: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.control!(styles, data),
      minHeight: 'inherit',
      flexWrap: 'nowrap'
    }
  },
  valueContainer: (styles, data) => {
    return {
      ...styles,
      padding: '0px',
      minWidth: 'inherit',
      alignSelf: 'flex-start',
      overflow: 'hidden'
    }
  },
  menu: (styles, data) => {
    return {
      ...DefaultReactSelectStyle.menu!(styles, data),
      marginTop: '2px',
    }
  },
  dropdownIndicator: (styles, data) => {
    return {
      ...styles,
      height: '100%',
      padding: 0,
      alignSelf: 'flex-start',
      alignItems: 'center',
      width: '12px',
      color: '#fff',
      svg: {
        height: '12px',
      },
    }
  },
}

export const TextEditorColorPickerSelectStyle: StylesConfig<any, false> = {
  ...TextEditorSelectStyle,
  control: (styles, data) => {
    return {
      ...TextEditorSelectStyle.control!(styles, data),
      background: '#444444',
      height: '100%',
    }
  },
  container: (styles, data) => {
    return {
      ...TextEditorSelectStyle.container!(styles, data),
      minWidth: 'max-content',
    }
  },
}
