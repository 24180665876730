import { useEffect, useState } from "react";
import FileExplorer from "../BackgroundMenu/FileExplorer/FileExplorer";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Select from "react-select";
import { findComponentById } from "../../../../../utils/Helpers";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { useAppSelector } from "../../../../../store/hooks";
import { Document } from "../../../../../store/reducers/builderReducers/documents/documentsApi";
import { LogoComponent } from "../../../../_default/interfaces/base";

import "./LogoBgMenu.scss";

interface Props {
  id: number;
  triggerResize: () => void;
}

export default function LogoBgMenu({ id, triggerResize }: Props) {
  console.log("LogoBgMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as LogoComponent;
  const [fileExplorerOpen, setFileExplorerOpen] = useState(false);
  const [type, setType] = useState<any>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownPositionOpen, setIsDropdownPositionOpen] = useState(false);
  const [isDropdownOriginOpen, setIsDropdownOriginOpen] = useState(false);
  const [isDropdownSizeOpen, setIsDropdownSizeOpen] = useState(false);
  const [isDropdownAttachmentOpen, setIsDropdownAttachmentOpen] =
    useState(false);

  const positions = [
    { label: "Left Top", value: "left top" },
    { label: "Center Top", value: "center top" },
    { label: "Right Top", value: "right top" },
    { label: "Left Center", value: "left center" },
    { label: "Center Center", value: "center center" },
    { label: "Right Center", value: "right center" },
    { label: "Left Bottom", value: "left bottom" },
    { label: "Center Bottom", value: "center bottom" },
    { label: "Right Bottom", value: "right bottom" },
  ];

  const origins = ["border-box", "padding-box", "content-box"];

  const repeats = [
    { label: "Repeat", value: "repeat" },
    { label: "No repeat", value: "no-repeat" },
    { label: "Repeat X", value: "repeat-x" },
    { label: "Repeat Y", value: "repeat-y" },
    { label: "Space", value: "space" },
    { label: "Round", value: "round" },
  ];

  const desktopImageTypes = [
    { label: "Desktop", value: true },
    { label: "None", value: false },
  ];

  const mobileImageTypes = [
    { label: "Mobile", value: true },
    { label: "None", value: false },
  ];

  const sizes = ["px px"];

  const attachments = [
    { label: "Scroll", value: "scroll" },
    { label: "Fixed", value: "fixed" },
    { label: "Local", value: "local" },
  ];

  useEffect(() => {
    triggerResize();
  }, [component]);

  function extractUrl(url: string) {
    // let url = str.match(
    //   /(?:(?:http?|https|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi
    // )![0]

    if (url.startsWith("url(")) {
      url = url.substring(4, url.length - 1);
    }
    if (url.startsWith('"')) {
      return url.substring(1, url.length - 1);
    }
    if (url.startsWith("'")) {
      return url.substring(1, url.length - 1);
    }
    return url;
  }

  function getSelectedBackgroundSize(size: string) {
    if (size.includes("px") && size.includes("auto")) {
      return "px auto";
    } else if ((size.match(/px/g) || []).length == 2) {
      return "px px";
    } else if (size.includes("%") && size.includes("auto")) {
      return "% auto";
    } else if ((size.match(/%/g) || []).length == 2) {
      return "% %";
    } else {
      return size;
    }
  }

  function setPropByPath(path: any, val: any) {
    updateStructureComponent(id, path, val);
  }

  function onBgSelect(item: Document) {
    setPropByPath(
      "attributes.logo." + type + ".styles.backgroundImage",
      'url("' + item.url + '")'
    );
  }

  function renderBackgroundSizeOption(key: any, value: any, size: string) {
    const selectedSize = getSelectedBackgroundSize(value);
    if (selectedSize !== size) return size;

    if (selectedSize == "px px") {
      let pxSizes = value.match(/\-?\d*\.?\d+/g);
      if (!pxSizes || pxSizes.length != 2) {
        pxSizes = [14, 14];
      }
      return (
        <>
          <span
            onClick={() =>
              setPropByPath(
                "attributes.logo." + key + ".styles.backgroundSize",
                Number(pxSizes[0] ?? 14) -
                  1 +
                  "px " +
                  Number(pxSizes[1] ?? 14) +
                  "px"
              )
            }
          >
            -{" "}
          </span>{" "}
          <input
            className="global-editor__input builder-input"
            size={1}
            value={pxSizes[0] ?? 14}
            onChange={(ev) => {
              if (isNaN(ev.target.value as any) || ev.target.value == "")
                return;
              setPropByPath(
                "attributes.logo." + key + ".styles.backgroundSize",
                ev.target.value + "px " + Number(pxSizes[1] ?? 14) + "px"
              );
            }}
          />
          <span
            onClick={() =>
              setPropByPath(
                "attributes.logo." + key + ".styles.backgroundSize",
                Number(pxSizes[0] ?? 14) +
                  1 +
                  "px " +
                  Number(pxSizes[1] ?? 14) +
                  "px"
              )
            }
          >
            {" "}
            +{" "}
          </span>
          <span
            onClick={() =>
              setPropByPath(
                "attributes.logo." + key + ".styles.backgroundSize",
                Number(pxSizes[0] ?? 14) +
                  "px " +
                  (Number(pxSizes[1] ?? 14) - 1) +
                  "px"
              )
            }
          >
            {" "}
            -{" "}
          </span>{" "}
          <input
            className="global-editor__input builder-input"
            size={1}
            value={pxSizes[1] ?? 14}
            onChange={(ev) => {
              if (isNaN(ev.target.value as any) || ev.target.value == "")
                return;
              setPropByPath(
                "attributes.logo." + key + ".styles.backgroundSize",
                Number(pxSizes[0] ?? 14) + "px " + ev.target.value + "px"
              );
            }}
          />
          <span
            onClick={() =>
              setPropByPath(
                "attributes.logo." + key + ".styles.backgroundSize",
                Number(pxSizes[0] ?? 14) +
                  "px " +
                  (Number(pxSizes[1] ?? 14) + 1) +
                  "px"
              )
            }
          >
            {" "}
            +{" "}
          </span>
        </>
      );
    }
  }

  return (
    <>
      <div className="d-flex flex-wrap align-items-center">
        <Select
          className="b-mx-1 logo-menu_input"
          menuPosition="fixed"
          isSearchable={false}
          styles={TextEditorSelectStyle}
          noOptionsMessage={({}) => ""}
          value={
            desktopImageTypes[component.attributes.logo.Desktop.active ? 0 : 1]
          }
          onChange={(val) => {
            setPropByPath("attributes.logo.Desktop.active", val!.value);
          }}
          options={desktopImageTypes}
          placeholder=""
        ></Select>
        <Select
          className="b-mx-1 logo-menu_input"
          menuPosition="fixed"
          isSearchable={false}
          styles={TextEditorSelectStyle}
          noOptionsMessage={({}) => ""}
          value={
            mobileImageTypes[component.attributes.logo.Mobile.active ? 0 : 1]
          }
          options={mobileImageTypes}
          onChange={(val) => {
            setPropByPath("attributes.logo.Mobile.active", val!.value);
          }}
          placeholder=""
        ></Select>
        {fileExplorerOpen && (
          <FileExplorer
            setFileExplorerOpen={setFileExplorerOpen}
            onSelect={onBgSelect}
            id={id}
          />
        )}
      </div>
      {Object.entries(component.attributes.logo).map(
        ([key, value], index) =>
          value.active && (
            <div className=" align-items-start b-mb-1" key={key}>
              <div className="background-item b-mx-1">
                <img
                  src={extractUrl(value.styles.backgroundImage!)}
                  onClick={() => {
                    setType(key);
                    setFileExplorerOpen(true);
                  }}
                ></img>
              </div>
              <div className="d-flex w-100">
                <div
                  className="flex-row flex-wrap"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "5px",
                    marginTop: "10px",
                  }}
                >
                  <div className="background-item-option">
                    <div
                      className="clickable logo-menu_input"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      {
                        repeats.find(
                          (x) => x.value === value.styles.backgroundRepeat
                        )!.label
                      }
                      <ArrowDropDownIcon
                        style={{ marginLeft: "-2px", marginTop: "-1px" }}
                      />
                    </div>

                    {isDropdownOpen && (
                      <ul className="logo-menu-dropdown">
                        {repeats.map((x) => (
                          <li
                            onClick={() =>
                              setPropByPath(
                                "attributes.logo." +
                                  key +
                                  ".styles.backgroundRepeat",
                                x.value
                              )
                            }
                            key={x.value}
                          >
                            <span
                              className={
                                "dropdown-item builder-dropdown__item" +
                                (x.value === value.styles.backgroundRepeat
                                  ? " builder-dropdown__item--active"
                                  : "")
                              }
                            >
                              {x.label}
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="background-item-option">
                    <div
                      className="clickable logo-menu_input"
                      onClick={() =>
                        setIsDropdownPositionOpen(!isDropdownPositionOpen)
                      }
                    >
                      {
                        positions.find(
                          (x) => x.value === value.styles.backgroundPosition
                        )!.label
                      }
                      <ArrowDropDownIcon
                        style={{ marginLeft: "-2px", marginTop: "-1px" }}
                      />
                    </div>

                    {isDropdownPositionOpen && (
                      <ul className="logo-menu-dropdown">
                        <div className="d-grid background-grid-container">
                          {positions.map((x) => (
                            <div
                              key={x.value}
                              className={
                                "background-grid-item" +
                                (value.styles.backgroundPosition === x.value
                                  ? " background-grid-item--active"
                                  : "")
                              }
                              onClick={() => {
                                setPropByPath(
                                  "attributes.logo." +
                                    key +
                                    ".styles.backgroundPosition",
                                  x.value
                                );
                              }}
                            >
                              {x.label}
                            </div>
                          ))}
                        </div>
                      </ul>
                    )}
                  </div>
                  <div className="background-item-option">
                    <div
                      className="clickable logo-menu_input"
                      onClick={() => setIsDropdownSizeOpen(!isDropdownSizeOpen)}
                    >
                      {value.styles.backgroundSize}
                      <ArrowDropDownIcon
                        style={{ marginLeft: "-2px", marginTop: "-1px" }}
                      />
                    </div>

                    {isDropdownSizeOpen && (
                      <ul className="logo-menu-dropdown">
                        {sizes.map((x) => (
                          <li
                            key={x}
                            onClick={() => {
                              if (
                                getSelectedBackgroundSize(
                                  value.styles.backgroundSize!.toString()
                                ) != x
                              ) {
                                setPropByPath(
                                  "attributes.logo." +
                                    key +
                                    ".styles.backgroundSize",
                                  x
                                );
                              }
                            }}
                          >
                            <span
                              className={
                                "dropdown-item builder-dropdown__item" +
                                (getSelectedBackgroundSize(
                                  value.styles.backgroundSize!.toString()
                                ) === x
                                  ? " builder-dropdown__item--active"
                                  : "")
                              }
                            >
                              {renderBackgroundSizeOption(
                                key,
                                value.styles.backgroundSize,
                                x
                              )}
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="background-item-option">
                    <div
                      className="clickable logo-menu_input"
                      onClick={() =>
                        setIsDropdownOriginOpen(!isDropdownOriginOpen)
                      }
                    >
                      {origins.find((x) => x === value.styles.backgroundOrigin)}
                      <ArrowDropDownIcon
                        style={{ marginLeft: "-2px", marginTop: "-1px" }}
                      />
                    </div>

                    {isDropdownOriginOpen && (
                      <ul className="logo-menu-dropdown">
                        {origins.map((x) => (
                          <li
                            onClick={() =>
                              setPropByPath(
                                "attributes.logo." +
                                  key +
                                  ".styles.backgroundOrigin",
                                x
                              )
                            }
                            key={x}
                          >
                            <span
                              className={
                                "dropdown-item builder-dropdown__item" +
                                (x === value.styles.backgroundOrigin
                                  ? " builder-dropdown__item--active"
                                  : "")
                              }
                            >
                              {x}
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="background-item-option">
                    <div
                      className="clickable logo-menu_input"
                      onClick={() =>
                        setIsDropdownAttachmentOpen(!isDropdownAttachmentOpen)
                      }
                    >
                      {
                        attachments.find(
                          (x) => x.value === value.styles.backgroundAttachment
                        )!.label
                      }
                      <ArrowDropDownIcon
                        style={{ marginLeft: "-2px", marginTop: "-1px" }}
                      />
                    </div>

                    {isDropdownAttachmentOpen && (
                      <ul className="logo-menu-dropdown">
                        {attachments.map((x) => (
                          <li
                            onClick={() =>
                              setPropByPath(
                                "attributes.logo." +
                                  key +
                                  ".styles.backgroundAttachment",
                                x.value
                              )
                            }
                            key={x.value}
                          >
                            <span
                              className={
                                "dropdown-item builder-dropdown__item" +
                                (x.value === value.styles.backgroundAttachment
                                  ? " builder-dropdown__item--active"
                                  : "")
                              }
                            >
                              {x.label}
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
}
