import { useEffect, useRef, useCallback } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import './ProductsSortComponent.css'


export default function ProductsSortDropdownMenu({setOpen, sortFilter, setSortFilter, perPage, setPerPage}: any) {
    const popover = useRef<null | HTMLDivElement | any>(null);
    
    // SOURCE https://codesandbox.io/s/opmco?file=/src/useClickOutside.js:0-1192
    const useClickOutside = (ref: React.RefObject<HTMLDivElement | any>, handler: (e: any) => void) => {
        useEffect(() => {
        let startedInside = false
        let startedWhenMounted = false
    
        const listener = (event: any) => {
            // Do nothing if `mousedown` or `touchstart` started inside ref element
            if (startedInside || !startedWhenMounted) return
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) return
            
            function inClass(className: string, el: HTMLElement): HTMLElement | null {
                if (el?.classList?.contains(className)) {
                  return el
                } else if (el?.parentElement) {
                  return inClass(className, el.parentElement)
                } else {
                  return null
                }
              }
            // Do nothing if clicking in modal
            if (inClass('modal', event.target)) return
    
            handler(event)
        }
    
        const validateEventStart = (event: any) => {
            startedWhenMounted = true
            startedInside = !!(ref.current && ref.current.contains(event.target))
        }
    
        document.addEventListener('mousedown', validateEventStart)
        document.addEventListener('touchstart', validateEventStart)
        document.addEventListener('click', listener)
    
        return () => {
            document.removeEventListener('mousedown', validateEventStart)
            document.removeEventListener('touchstart', validateEventStart)
            document.removeEventListener('click', listener)
        }
        }, [ref, handler])
    }
    
    const close = useCallback(() => setOpen(false), []);
    useClickOutside(popover, close);
    
    const toggleSortFilter = ($name: any) => {
        if(sortFilter == $name){
            setSortFilter("");
        } else {
            setSortFilter($name);
        }
    }
      
    return (
        <div className="dropdown-menu filters pull-right" ref={popover}>
                <div className="dropdown-container-style">
                    <div className="sortFilter">
                        <b>Sorteeri</b>
                        <div className="container">
                            <div className="sortFilterRow" onClick={()=> toggleSortFilter("price_asc")}>
                                {sortFilter == "price_asc" && <CheckIcon className='checkedIcon' />}
                                <span className="sort-name margin-left-15">Odavamad enne</span>
                            </div>
                            <div className="sortFilterRow" onClick={()=> toggleSortFilter("price_desc")}>
                                {sortFilter == "price_desc" && <CheckIcon className='checkedIcon' />}
                                <span className="sort-name margin-left-15">Kallimad enne</span>
                            </div>
                            <div className="sortFilterRow" onClick={()=> toggleSortFilter("name_asc")}>
                                {sortFilter == "name_asc" && <CheckIcon className='checkedIcon' />}
                                <span className="sort-name margin-left-15">Nimetuse järgi: A-&gt;Z</span>
                            </div>
                            <div className="sortFilterRow" onClick={()=> toggleSortFilter("name_desc")}>
                                {sortFilter == "name_desc" && <CheckIcon className='checkedIcon' />}
                                <span className="sort-name margin-left-15">Nimetuse järgi: Z-&gt;A</span>
                            </div>
                        </div>
                    </div>
                    <div className="perPageFilter margin-top-15">
                        <b>Kuva</b>
                        <div className="container">
                            {[
                                30,
                                60,
                                90
                            ].map((perPageFilter: any) => {
                                console.log(perPage);
                                console.log(perPageFilter);
                                return <div 
                                key={perPageFilter}
                                onClick={()=> setPerPage(perPageFilter)}
                                className="perPageFilterRow">
                                    {perPage == perPageFilter && <CheckIcon className='checkedIcon' />}
                                    <span className="sort-name margin-left-15">{perPageFilter} toodet lehel</span>
                                </div>
                            })}
                            
                        </div>
                    </div>
                    {/* <button className="btn btn-primary margin-top-15 loadProducts" data-dismiss="modal">
                        RAKENDA
                    </button> */}
                </div>
            </div>
    )
}
