declare var defaultApiConfig: any;
declare var API: any;


export function fetchT<TResponse>(url: string, config: RequestInit = {}): Promise<TResponse> {
  const newConfig = {...defaultApiConfig, ...config, headers: {...defaultApiConfig.headers, ...config.headers}};

  return fetch(url, newConfig)
    .then((response) => response.json())
    .then((data) => data as TResponse)
}

export function fetchApi<TResponse>(url: string, config: RequestInit = {}, jsonType: boolean = true): Promise<TResponse> {
  const newConfig = {...defaultApiConfig, ...config, headers: {...defaultApiConfig.headers, ...config.headers}};

  if(!jsonType){
    return fetch(`${API.url}${url}`, newConfig)
    .then((data) => data as TResponse)
  }
  return fetch(`${API.url}${url}`, newConfig)
    .then((response) => response.json())
    .then((data) => data as TResponse)
    
}


export function fetchApiGet<TResponse>(url: string, params: {} = {}): Promise<TResponse> {
  return fetchApi(url, getHeaders(params))
}

export function fetchApiPost<TResponse>(url: string, params: {} = {}, jsonType: boolean = true): Promise<TResponse> {
  return fetchApi(url, postHeaders(params), jsonType)
}

export function fetchApiPut<TResponse>(url: string, params: {} = {}): Promise<TResponse> {
  return fetchApi(url, putHeaders(params))
}

export function fetchApiDelete<TResponse>(url: string, params: {} = {}): Promise<TResponse> {
  return fetchApi(url, deleteHeaders())
}

export const getHeaders = (params: {} = {}) => {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    params: params
  }
}

export const postHeaders = (payload: any) => {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
}

export const postFormHeaders = (payload: any) => {
  return {
    method: 'POST',
    body: payload,
  }
}

const putHeaders = (payload: any) => {
  return {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
}

const deleteHeaders = () => {
  return {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
}

// function saveVersion() {
//   return 'API: Save version.'
// }

// function loadVersion() {
//   return fetchApiGet(`/load`, getHeaders())
//     .then((res: any) => res.json())
//     .then((result) => result)
// }



export default API
