import {
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
  fetchApiDelete,
} from "../../../../api/api";
import { Language } from "../languageSettings/languageSettingsApi";
import {
  updateWebpageById,
  updateWebpages,
  deleteWebpageById,
  addNewWebpage,
} from "./pagesActions";

export interface Page {
  id: number;
  name: string;
  page_type: string;
  language: Language;
  homepage: boolean;
  language_webpages: Page[];
  page: string;
  seo_description: string | null;
  seo_keyword: string | null;
  seo_no_index: boolean;
  seo_title: string | null;
  status: string;
  created_at: string;
  url: string;
  updated_at: string;
  website_id: number;
  website_language_id: number;
}

export interface Pages {
  active: number | null;
  list: Page[];
}

const PagesAPI = {
  loadWebpages,
  saveWebpageById,
  addWebpage,
  deleteWebpage,
  duplicateWebpage,
};
export default PagesAPI;

export function loadWebpages() {
  return fetchApiGet<Pages["list"]>(`/webpages`, {}).then((res) =>
    updateWebpages(res)
  );
}

function deleteWebpage(webpage_id: number) {
  return fetchApiDelete(`/webpages/${webpage_id}`, {}).then(() => {
    deleteWebpageById(webpage_id);
  });
}

function saveWebpageById(id: number, data: Page) {
  return fetchApiPut<Page>(`/webpages/${id}`, data).then(() =>
    updateWebpageById({ id: id, data: data })
  );
}

function duplicateWebpage(id: number) {
  return fetchApiPost<Page>(`/webpages/${id}/duplicate`).then((res) => {
    addNewWebpage(res);
    return res;
  });
}

function addWebpage(data: { name: string; url: string }) {
  return fetchApiPost<Page>(`/webpages`, data).then((res) => {
    console.log(res);

    addNewWebpage(res);
    return res;
  });
}
