import Select from 'react-select'
import { updateStructureComponent } from '../../../../../store/reducers/builderReducers/structure/structureActions'
import { findComponentById } from '../../../../../utils/Helpers'
import { TextEditorSelectStyle } from '../../../ReactSelectStyle/ReactSelectStyle'
import { useAppSelector } from '../../../../../store/hooks'
import { HeaderComponent } from '../../../../_default/interfaces/base'

interface Props {
  id: number
}

export default function MenuMenu({ id }: Props) {
  console.log("MenuMenu");
  const component = useAppSelector((state) => state.builder.structure.components[id])! as HeaderComponent
  const NavLinks = [
    { value: 'navigation links', label: 'Navigation links' },
    { value: 'none', label: 'None' },
  ]

  const alignments = [
    { value: 'left', label: 'Left' },
    { value: 'center', label: 'Center' },
    { value: 'right', label: 'Right' },
  ]

  function onAlignmentChange(val: any) {
    updateStructureComponent(id, 'attributes.components.nav.alignment', val.value)
  }

  function onDisplayChange(val: any) {
    updateStructureComponent(id, 'attributes.components.nav.display', val.value === 'none' ? false : true)
  }

  return (
    <div className='d-flex' style={{ gap: '10px' }}>
      <Select
        onChange={onDisplayChange}
        isSearchable={false}
        className='m-0'
        styles={TextEditorSelectStyle}
        noOptionsMessage={({}) => ''}
        value={component.attributes.components.nav.display ? NavLinks[0] : NavLinks[1]}
        options={NavLinks}
        placeholder=''></Select>
      <Select
        onChange={onAlignmentChange}
        isSearchable={false}
        className='m-0'
        styles={{
          ...TextEditorSelectStyle,
          container: (base) => ({
            ...base,
            width: '50px',
          }),
        }}
        noOptionsMessage={({}) => ''}
        value={alignments.find((x) => x.value === component.attributes.components.nav.alignment)}
        options={alignments}
        placeholder=''></Select>
    </div>
  )
}
