import { useShoppingCart, LocationProduct } from './context/ShoppingCartContext'
import { pf } from './utilities/helpers'

interface Props {
  locationProduct: LocationProduct
}

export default function ProductComponent({ locationProduct }: Props) {
  const { increaseCartQuantity } = useShoppingCart()

  return (
    <div className='productcol'>
      <div className='web-product'>
        <a
          href={'/toode/' + locationProduct.id}
          className='product-img'
          style={{
            background: 'url(' + locationProduct.firstFileUrl + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
          <img src={locationProduct.firstFileUrl} alt={locationProduct.display_name + ' pilt.'} style={{ width: '100%' }}></img>
          <div className='product-info-overlay'>
            <div dangerouslySetInnerHTML={{ __html: locationProduct.shortDescription }} />
          </div>
        </a>
        <div className='padding-10 pb-20'>
          <div className='product-name'>
            <a href={'/toode/' + locationProduct.id}>{locationProduct.display_name}&nbsp;</a>
          </div>
          <div className='productPrice'>{pf(locationProduct.price_total)}&euro;</div>
          <div>
            <button className='btn btn-primary addProductToCart' onClick={() => increaseCartQuantity(locationProduct.id)}>
              LISA KORVI
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
