import { Typography } from './typographiesApi'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: Typography[] = []

export const typographiesSlice = createSlice({
  name: 'typographies',
  initialState,
  reducers: {
    CREATE_TYPOGRAPHY: (state, action: PayloadAction<Typography>) => {
      // console.log('CREATE_TYPOGRAPHY')
      return [...state, action.payload]
    },
    UPDATE_TYPOGRAPHY_BY_ID: (state: any, action: PayloadAction<{
      id: number
      data: Typography
    }>) => {
      return state.map((item: any) => {
        if(item.id === action.payload.id){
          return {...item, ...action.payload.data}
        }
        return item
      })
    },
    UPDATE_TYPOGRAPHIES: (state: any, action: PayloadAction<Typography[]>) => {
      return action.payload
    },
    REMOVE_TYPOGRAPHY_BY_ID: (state: any, action: PayloadAction<{ id: number }>) => {
      return state.filter((x: any) => x.id !== action.payload.id)
    },

  },
})

// Action creators are generated for each case reducer function
export const { 
  CREATE_TYPOGRAPHY,
  UPDATE_TYPOGRAPHY_BY_ID,
  UPDATE_TYPOGRAPHIES,
  REMOVE_TYPOGRAPHY_BY_ID,
 } = typographiesSlice.actions

export default typographiesSlice.reducer
