import { Pages } from "../../../../../store/reducers/builderReducers/pages/pagesApi";

interface Props {
  page: Pages["list"][0];
  handleEdit: (obj: {}) => void;
}

export default function PageDetailsLanguagesViewForm({
  page,
  handleEdit,
}: Props) {
  return (
    <>
      <div className="b-row b-mb-3">
        <div className="col-10 fw-bold">Languages</div>
        <div className="col-2 b-pt-1 text-end">
          <a
            className="pages-button"
            onClick={() => handleEdit({ languages: true })}
          >
            Edit
          </a>
        </div>
      </div>
      <div className="b-row b-mb-3">
        <div className="col-12 fs-small b-mb-2">Page language</div>
        <div className="col-12 b-mb-2 d-flex align-items-center">
          <img
            src={page.language ? page.language.icon : ""}
            height="21px"
            width="32px"
          ></img>
          <label className="b-ps-2">
            {page.language ? page.language.name : ""}
          </label>
        </div>
      </div>
      {page.language_webpages
        .filter((x) => x.id !== page.id)
        .map((webpage) => (
          <div key={webpage.id} className="b-row b-mb-3">
            {/*<div className='col-12 fs-small b-mb-2'>The same page in {languages.find((x) => x.id === webpage.website_language_id)?.name}</div>*/}
            <div className="col-12 b-mb-2">{webpage.name}</div>
          </div>
        ))}
    </>
  );
}
