import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext";
import store from "./store/store";
import Redirect from "./components/_default/utilities/Redirect";
// Pages
import Builder from "./pages/Builder";
import axios from "axios";

import "./sass/app.scss";

// let urlSplit = window.location.href.split('/')
// let websiteMatch = urlSplit[urlSplit.findIndex((x) => x === 'builder') + 1].match(/(\d+)/);
// const websiteId = websiteMatch ? websiteMatch[0] : '';

const urlParams = new URLSearchParams(window.location.search);

var Authorization;
var APIKey;
var websiteId;
var APIUrl;
var baseURL;
var backendURL;
if (urlParams) {
  Authorization = urlParams.get("Authorization");
  APIKey = urlParams.get("APIKey");
  websiteId = urlParams.get("websiteId");
  backendURL = urlParams.get("backendURL");

  window.websiteId = websiteId;
  APIUrl = `${backendURL}/builderApi/${websiteId}`;
  baseURL = `${backendURL}/webstoreApi`;
}

var axiosHeaders = {
  "Access-api-key": APIKey,
  "Access-Control-Allow-Origin": "*",
};

window.axios = axios.create({
  baseURL: baseURL,
  headers: axiosHeaders,
});

window.API = {
  url: APIUrl,
  backendURL: backendURL,
};

window.defaultApiConfig = {
  mode: "cors",
  headers: {
    Authorization: Authorization,
    "Access-api-key": APIKey,
    "Access-Control-Allow-Origin": "*",
  },
};

import Editor from "./components/BuilderComponents/Editors/Editor/Editor";
import { defaultComponents } from "./components/_default/interfaces/DefaultComponents";
// include editor in defaultComponents
defaultComponents.editor = Editor;
window.defaultComponents = defaultComponents;

export default function App() {
  return (
    <ShoppingCartProvider>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<Builder title="Builder" />} exact />;
          </Routes>
        </BrowserRouter>
      </Provider>
    </ShoppingCartProvider>
  );
}
