import store from "../../../store"
import { Editor } from './editorApi'
import {
    UPDATE_EDITOR,
} from './editorReducer'

export function updateEditor(payload: Editor) {
    return store.dispatch(
        UPDATE_EDITOR(payload)
    )
}