import React, { useState } from "react";
import WebsiteAPI from "../../../../../store/reducers/builderReducers/website/websiteApi";
import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";

interface Props {
  handleEdit: (v: boolean) => void;
  website: Website;
}

export default function WebsiteTrackingFormEdit({
  handleEdit,
  website,
}: Props) {
  const [cachedTracking, setCachedTracking] = useState(website);

  function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    WebsiteAPI.saveWebsite({
      ...website!,
      tracking: cachedTracking!.tracking,
    }).then(() => {
      handleEdit(false);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row b-mt-4">
        <div className="col-12 fw-bold">Tracking</div>
      </div>
      <div className="b-row b-mt-3">
        <div className="col-12">
          <label className="fs-small">Google Tag Manager Container ID</label>
          <input
            className="builder-input form-control"
            value={cachedTracking!.tracking}
            onChange={(x) =>
              setCachedTracking({
                ...cachedTracking!,
                tracking: x.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="b-mt-3">
        <button className="sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => handleEdit(false)}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
