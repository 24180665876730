import TextComponent from "../TextComponent"
import ProductsComponent from "../Products/ProductsComponent"
import ProductDetailComponent from "../ProductDetail/ProductDetailComponent"
import Checkout from "../Checkout/Checkout"
import ImageComponent from "../ImageComponent"
import ButtonComponent from "../ButtonComponent"
import NavItemComponent from "../NavItemComponent"
import SeparatorComponent from "../SeparatorComponent"
import SectionComponent from "../SectionComponent"
import FooterComponent from '../FooterComponent'
import HeaderComponent from '../HeaderComponent'
import InputComponent from "../InputComponent"
import FormComponent from "../FormComponent"

export var defaultComponents: { [key: string]: any } = {
    text: TextComponent,
    ProductsComponent: ProductsComponent,
    ProductDetailComponent: ProductDetailComponent,
    Checkout: Checkout,
    Products: ProductsComponent,
    image: ImageComponent,
    Button: ButtonComponent,
    // Link: LinkComponent,
    section: SectionComponent,
    separator: SeparatorComponent,
    NavItemComponent: NavItemComponent,
    footer: FooterComponent,
    header: HeaderComponent,
    input: InputComponent,
    form: FormComponent
}
