import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";

interface Props {
  handleEdit: (form: {}) => void;
  website: Website;
}

export default function WebsiteParametersFormView({
  handleEdit,
  website,
}: Props) {
  return (
    <div className="b-row b-mt-4">
      <div className="col-10 fw-bold">Page builder parameters</div>
      <div className="col-2 b-pt-1 text-end">
        <a
          className="website-edit-button"
          onClick={() => handleEdit({ parameters: true })}
        >
          Edit
        </a>
      </div>
      <div className="b-row b-mt-3 b-mb-4">
        <div className="col-12 b-mb-2 fs-small">Content width</div>
        <div className="col-12">
          {website?.content_width_size} {website?.content_width_unit}
        </div>
      </div>
    </div>
  );
}
