import { useState } from "react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import FlagIcon from "@mui/icons-material/Flag";
import SettingsIcon from "@mui/icons-material/Settings";
import SubSidebar from "./SubSidebar/SubSidebar";

export type SidebarMenus =
  | "pages"
  | "templates"
  | "color"
  | "font"
  | "flag"
  | "settings"
  | "";

export default function Sidebar() {
  const [selectedMenu, setSelectedMenu] = useState<SidebarMenus>("");

  function toggleActive(name: SidebarMenus) {
    setSelectedMenu(name == selectedMenu ? "" : name);
  }

  return (
    <>
      <div className="sidebar d-flex flex-column align-items-center">
        <ul className="b-ps-0">
          <li
            onClick={() => toggleActive("pages")}
            className={selectedMenu == "pages" ? "active" : ""}
          >
            <FormatListBulletedIcon />
          </li>
          <li
            onClick={() => toggleActive("templates")}
            className={selectedMenu == "templates" ? "active" : ""}
          >
            <DashboardIcon />
          </li>
          <li
            onClick={() => toggleActive("color")}
            className={selectedMenu == "color" ? "active" : ""}
          >
            <FormatColorFillIcon />
          </li>
          <li
            onClick={() => toggleActive("font")}
            className={selectedMenu == "font" ? "active" : ""}
          >
            <FontDownloadIcon />
          </li>
          <li
            onClick={() => toggleActive("flag")}
            className={selectedMenu == "flag" ? "active" : ""}
          >
            <FlagIcon />
          </li>
          <li
            onClick={() => toggleActive("settings")}
            className={selectedMenu == "settings" ? "active" : ""}
          >
            <SettingsIcon />
          </li>
        </ul>
      </div>
      <SubSidebar
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      />
    </>
  );
}
