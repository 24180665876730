import { AllComponents } from "../components/_default/interfaces/base";

// Updates object key value by object "id" value.
export let updateObjValueById =
  (id: string, key: string, value: any) => (obj: any) => {
    if (obj.id === ~~id) {
      obj[key].push(value);
      return true;
    } else if (obj.childElements) {
      return obj.childElements.some(updateObjValueById(id, key, value));
    }
  };

// Returns path as string or as an array.
export function getObjectPathByKeyValue(
  object: any,
  key: string,
  value: any,
  asString = true
) {
  let path: string[] = [];
  getObjPath(object, key, value);

  function getObjPath(_obj: any, _key: string, _val: any): any {
    if (!_obj || (typeof _obj !== "object" && !Array.isArray(_obj))) {
      return false;
    } else if (_obj.hasOwnProperty(_key) && _obj[_key] === _val) {
      return true;
    } else if (Array.isArray(_obj)) {
      let _parentKey = "";

      for (let i = 0; i < _obj.length; i++) {
        path.push(`${_parentKey}[${i}]`);
        const _subObj = getObjPath(_obj[i], _key, _val);
        if (_subObj) {
          return _subObj;
        }
        path.pop();
      }
    } else {
      for (const _k in _obj) {
        path.push(_k);
        const _subObj = getObjPath(_obj[_k], _key, _val);
        if (_subObj) {
          return _subObj;
        }
        path.pop();
      }
    }
    return false;
  }

  return asString
    ? path.join(".")
    : path.map((item) => item.replace("[", "").replace("]", ""));
}

// Returns path as string or as an array.
export function getObjectPathsByKeyValue(
  object: any,
  key: string,
  value: any,
  asString = true
) {
  let path: string[] = [];
  var paths: any = [];
  getObjPaths(object, key, value, []);

  console.log(JSON.stringify(paths));

  function getObjPaths(_obj: any, _key: string, _val: any, _path: any): any {
    if (!_obj || (typeof _obj !== "object" && !Array.isArray(_obj))) {
      return false;
    } else if (_obj.hasOwnProperty(_key) && _obj[_key] === _val) {
      paths.push([..._path]);
      return true;
    } else if (Array.isArray(_obj)) {
      let _parentKey = "";

      for (let i = 0; i < _obj.length; i++) {
        _path.push(`${i}`);
        const _subObj = getObjPaths(_obj[i], _key, _val, _path);
        // if (_subObj) {
        //   return _subObj
        // }
        _path.pop();
      }
    } else {
      for (const _k in _obj) {
        _path.push(_k);
        const _subObj = getObjPaths(_obj[_k], _key, _val, _path);
        // if (_subObj) {
        //   return _subObj
        // }
        _path.pop();
      }
    }
    return false;
  }

  return asString ? paths.join(".") : paths;
}

export function findComponentById(
  structure: any,
  id: number
): AllComponents | undefined {
  return structure.components.find((c: { id: number }) => c.id === id);
  // for (let i = 0; i < DOMStructure.length; i++) {
  //   if (DOMStructure[i].id === id) return DOMStructure[i]
  //   else if (DOMStructure[i].childElements.length) {
  //     let comp = findComponentById(DOMStructure[i].childElements, id)
  //     if (comp) return comp
  //   }
  // }
}

// Cuz col and row are different but have same type
export function getComponentType(component: any) {
  if (component === null) {
    return "col";
  }

  if (component.type === "section") {
    if (component.attributes.classes.includes("row")) {
      return "row";
    } else {
      return "col";
    }
  }
  return component.type;
}

// 'element', 'row' or 'col'
export function getTemplateCompType(component: any) {
  let comp = getComponentType(component);
  if (!["row", "col"].includes(comp)) {
    return "element";
  }
  return comp;
}

export function inClass(
  className: string,
  el: HTMLElement
): HTMLElement | null {
  if (el?.classList?.contains(className)) {
    return el;
  } else if (el?.parentElement) {
    return inClass(className, el.parentElement);
  } else {
    return null;
  }
}

export function setComponentPropertyRecursively(
  component: any,
  property: string,
  value: any
) {
  component[property] = value;
  component.childElements.forEach((comp: any) => {
    setComponentPropertyRecursively(comp, property, value);
  });
  return component;
}

export function dateDiffInDays(f_date: number, s_date: number) {
  return Math.abs(Math.round((s_date - f_date) / (1000 * 60 * 60 * 24)));
}
