import { useShoppingCart } from "../context/ShoppingCartContext"


export default function CheckoutPaymentPanelConfirmed({ }) {

    const {
        orderPaymentMethod,
    } = useShoppingCart()

    var maksekeskusPaymentMethod: any = null;
    if ((orderPaymentMethod as any)?.maksekeskusPaymentMethod) {
      maksekeskusPaymentMethod = (orderPaymentMethod as any).api_client.maksekeskus_payment_methods.find(
        (i: any) => i.id == (orderPaymentMethod as any).maksekeskusPaymentMethod
      )
    }

    return (
      <div className='panel' id='checkoutPaymentmethodPanel'>
        <div className='panel-heading'>
          <div className='panel-title'>
            <span className='badge badge-square badge-primary gifticum-panel-title-number'>4</span>
            <span className='gifticum-panel-title-text'>MAKSE</span>
          </div>
        </div>
        <div className='panel-body'>
          <div className='row paymentMethodSelected'>
            <div className='col-xs-12 selectedPaymentMethodButtonConainer'>
              {orderPaymentMethod?.getMaksekeskusApiActive ? (
                <button className='btn btn-round btn-primary-outline paymentMethodMaksekeskus'>
                  {orderPaymentMethod.name}
                  {maksekeskusPaymentMethod ? (
                    <img src={maksekeskusPaymentMethod.logo_url} style={{ float: 'right', maxWidth: '65px', marginBottom: '-5px' }} />
                  ) : (
                    ''
                  )}
                </button>
              ) : (
                <button className='btn btn-round btn-primary-outline paymentMethodButton'>
                  {orderPaymentMethod?.image ? <img src={orderPaymentMethod.imageUrl} height='25px' /> : <span>{orderPaymentMethod?.name}</span>}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )


}
