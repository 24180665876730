import { useState, useEffect } from 'react';
import ContentRenderer from '../../_default/ContentRenderer'
import { useAppSelector } from '../../../store/hooks'
import AddHeaderOrFooter from './AddHeaderOrFooter'

export default function BuilderContent() {
  console.log("BuilderContent");
  const website = useAppSelector((state) => state.builder.website)
  
  useEffect(() => {
    document.body.style.fontSize = website.base_font_size
    document.getElementsByTagName('html')[0].style.fontSize = website.base_font_size
  }, [website.base_font_size])

  return (
    <div id='BuilderContent' className='builder__content-container'>
      <AddHeaderOrFooter typeName='header' />
      <ContentRenderer />
      <AddHeaderOrFooter typeName='footer' />
    </div>
  )
}