import { useState, useEffect } from 'react';
import { addHeader, addFooter } from '../../../utils/Components'
import ContentRenderer from '../../_default/ContentRenderer'
import { useAppSelector } from '../../../store/hooks'
import { AllComponents } from '../../_default/interfaces/base';

export default function AddHeaderOrFooter({ typeName }: any) {
    console.log("AddHeaderOrFooter");
    const structure = useAppSelector((state) => state.builder.structure)

    let component: AllComponents | null = null;
    Object.keys(structure.components).map(key => {
        const c = structure.components[key]
        if (c && c.type === typeName) {
            component = c
        }
    })
    if(component){
        return <></>
    }
    
    var onClick = () => addHeader()
    if(typeName === 'footer'){
        onClick = () => addFooter()
    }
    return (
        <div className={`add-${typeName}`} onClick={onClick}>{`+ Add ${typeName}`}</div>
    )
}