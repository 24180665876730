import { AllComponents } from "./interfaces/base";
import { useAppSelector } from "../../store/hooks";
import ErrorFallbackComponent from "./ErrorFallbackComponent";
import { useLocation } from "react-router-dom";
import { logErrorToBackend } from "./utilities/helpers";
import ContentRenderWebpageComponent from "./ContentRenderWebpageComponent";
import "./Default.css";
import { useEffect, useRef, useState } from "react";
import CookiePolicyComponent from "./CookiePolicyComponent";

declare var defaultComponents: any;

export default function ContentRenderer() {
  const fontUrl = useAppSelector((state) => state.builder.font_url.url);
  const webpageComponents = useAppSelector(
    (state) => state.builder.structure.webpageComponents
  );

  const location = useLocation();

  if (
    !window.location.href.includes("/builder/") &&
    location.key === "default" &&
    location.pathname !== "/"
  ) {
    const pageNotFoundData = {
      name: "PageNotFound",
      message: location.pathname,
      stack:
        "Page with pathname " +
        location.pathname +
        " was not found. In ContentRenderer.tsx",
      componentStack: location.pathname,
      href: window.location.href,
    };
    logErrorToBackend(pageNotFoundData);
  }

  // Read cookie

  const renderComponents = () => {
    return webpageComponents?.map((componentId: number) => {
      return (
        <ContentRenderWebpageComponent
          key={componentId}
          componentId={componentId}
        />
      );
    });
  };
  // Empty dependency array to run this effect only once on mount

  return (
    <>
      {fontUrl && (
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link href={fontUrl} rel="stylesheet" />
        </>
      )}
      <div className="ContentRenderer">{renderComponents()}</div>
      <CookiePolicyComponent />
    </>
  );
}
