import { useState, useEffect } from 'react'
import CategoryComponent from './CategoryComponent'
import { ProductCategory } from '../context/ShoppingCartContext'

interface Props {
  productCategoryId: number | null
  setProductCategoryId: (val: number) => void
}

export default function CategoriesComponent({ productCategoryId, setProductCategoryId }: Props) {
  const [productCategories, setProductCategories] = useState<ProductCategory[]>([])

  useEffect(() => {
    fetch(
      "/webstoreApi/loadProductCategories",
      {
        method: 'GET',
        cache: "no-cache",
        headers: { 'Content-Type': 'application/json' },
      }
    )
    .then((res) => res.json())
    .then((res) => {
      setProductCategories(res);
    })
  }, [])

  return (
    <div key={'categories'} className='panel categories margin-top-15'>
      <div className='panel-heading'>
        <h5>
          <a href={undefined} className='d-block d-md-none cats-title' id='cats-title-m' data-toggle='collapse'>
            Tooted
          </a>
          <a href={undefined} className='d-none d-md-block cats-title'>
            Tooted
          </a>
        </h5>
      </div>
      <div className='panel-collapse collapse in show' id='categories'>
        <ul>
          {productCategories!.map((productCategory) => {
            return (
              <CategoryComponent
                key={productCategory.id}
                productCategory={productCategory}
                productCategoryId={productCategoryId}
                setProductCategoryId={setProductCategoryId}
              />
            )
          })}
        </ul>
      </div>
    </div>
  )
}
