import "./EditorModal.scss"
import TemplateDetailsGeneralEditForm from "../../Sidebar/SubSidebar/Templates/TemplateDetailsGeneralEditForm"
interface Props {
    createComponentTemplate: any
    setCreateComponentTemplate: any
    template: any
}
export default function CreateTemplateModal({
    createComponentTemplate,
    setCreateComponentTemplate,
    template
}: Props) {
    const newTemplate = {
        id: 0,
        website_id: 0,
        name: "",
        use: "",
        webpage_component_id: createComponentTemplate.id,
        type: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        component: null,
    }

    return (
        <div className='editor-modal editor-modal__container'>
            <div className='editor-modal editor-modal__overlay'></div>
            <div className='editor-modal editor-modal__form'>
                <div className='container p-0'>
                <TemplateDetailsGeneralEditForm setGeneralEdit={setCreateComponentTemplate} template={template ?? newTemplate} setEditTemplate={() => void 0} />
                </div>
            </div>
        </div>
    )
}
