import Modal from "react-bootstrap/Modal"
import TermsContent from "./TermsContent"
import { useForm } from "react-hook-form"
import { useShoppingCart } from "../context/ShoppingCartContext"
import RenderChildren from "../RenderChildren";
import { useAppSelector } from "../../../store/hooks"


interface Props {
  show: boolean
  setShow: (val: boolean) => void
}

export default function CheckoutConfirmModal({ show, setShow }: Props) {
  const { checkoutConfirm } = useShoppingCart()
  const templates = useAppSelector((state) => state.builder.templates)
  const template = templates.find((x: any) => x.use === "checkoutTerms")
  console.log("CheckoutConfirmModal")
  console.log([...templates])
  console.log(template)
  let termschildren: any = []
  if(template){
    termschildren = [
      {
        id: 'terms',
        child_id: template.webpage_component_id,
      }
    ]
    
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  })

  const onSubmit = () => {
    checkoutConfirm()
    setShow(false)
  }

  const onError = (data: any) => {
    console.log(data)
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} contentClassName='modal-600 checkoutConfirmModal'>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className='badge badge-square badge-primary me-3'>5</span>
          <span className='fw-bold'>TINGIMUSTEGA TUTVUMINE</span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Body>
          <div className='terms-section'>
            {/* <TermsContent /> */}
            <RenderChildren key={"termschildren"} childWebpageComponentRelations={termschildren}/>
          </div>
          <div className='mt-2 links-green'>
            Andmete salvestamiseks tuleb nõustuda{' '}
            <a href='/terms' target='_blank' className='color-primary'>
              kasutustingimustega
            </a>
            ,{' '}
            <a href='/privacy' target='_blank' className='color-primary'>
              küpsiste- ja privaatsuspoliitikaga
            </a>
            .
          </div>
        </Modal.Body>
        <Modal.Footer className=''>
          <button type='submit' className='btn btn-primary w-100'>
            NÕUSTUN TINGIMUSTEGA JA SALVESTAN
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
