import { useAppSelector } from "../../../../../store/hooks";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Dropdown from "../../../Dropdown/Dropdown";
import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { useEffect } from "react";
import { SectionComponent } from "../../../../_default/interfaces/base";

interface Props {
  id: number;
}

export default function ContentWidthMenu({ id }: Props) {
  console.log("ContentWidthMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as SectionComponent;

  const options = ["%", "px"];

  function renderWidthOption() {
    switch (component.attributes.content_width_unit) {
      case "%":
        return (
          <input
            type="text"
            style={{ width: "30px" }}
            className="form-input builder-input "
            onChange={(e) => changeProp("content_width_size", e.target.value)}
            value={
              component.attributes.content_width_size > 100
                ? 100
                : component.attributes.content_width_size
            }
          />
        );
      case "px":
        return (
          <input
            type="text"
            style={{ width: "35px" }}
            className="form-input builder-input"
            onChange={(e) => changeProp("content_width_size", e.target.value)}
            value={component.attributes.content_width_size}
          />
        );
    }
  }

  useEffect(() => {
    renderWidthOption();
  }, [component.attributes]);

  function changeProp(key: string, val: any) {
    updateStructureComponent(id, "attributes." + key, val);
  }

  return (
    <div className="d-flex flex-wrap align-items-center">
      <div className="b-mx-1 content-width-menu_input">
        <span>{renderWidthOption()}</span>
      </div>
      <div className="dropup">
        <span data-bs-toggle="dropdown">
          {component.attributes.content_width_unit}
          <ArrowDropDownIcon viewBox="6 6 12 12" style={{ fontSize: "11px" }} />
        </span>
        <Dropdown>
          {options.map((x) => (
            <div
              key={x}
              className={
                "dropdown-item builder-dropdown__item" +
                (component.attributes.content_width_unit === x
                  ? " builder-dropdown__item--active"
                  : "")
              }
              onClick={() => changeProp("content_width_unit", x)}
            >
              {x}
            </div>
          ))}
        </Dropdown>
      </div>
    </div>
  );
}
