import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";

interface Props {
  handleEdit: (v: boolean) => void;
  website: Website;
}

export default function WebsiteTrackingFormEdit({
  handleEdit,
  website,
}: Props) {
  return (
    <div className="b-row b-mt-4">
      <div className="col-10 fw-bold">Page builder color</div>
      <div className="col-2 b-pt-1 text-end">
        <a
          className="hover-underline website-edit-button"
          onClick={() => handleEdit(true)}
        >
          Edit
        </a>
      </div>
      <div className="col-12 b-mt-3 d-flex b-py-1">
        <svg
          className="color-box"
          style={{ backgroundColor: website!.row_color }}
        ></svg>
        <label className="b-ps-3 align-self-center">Row</label>
      </div>
      <div className="col-12 d-flex b-py-1">
        <svg
          className="color-box"
          style={{ backgroundColor: website!.column_color }}
        ></svg>
        <label className="b-ps-3 align-self-center">Column</label>
      </div>
      <div className="col-12 d-flex b-py-1">
        <svg
          className="color-box"
          style={{ backgroundColor: website!.element_color }}
        ></svg>
        <label className="b-ps-3 align-self-center">Element</label>
      </div>
    </div>
  );
}
