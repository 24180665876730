import { useState } from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

// Sauce https://www.codemzy.com/blog/react-drag-drop-file-upload
export default function DragAndDropUpload({ icon = 'upload', onChange }: { icon: string; onChange: (e: any) => void }) {
  const [dragActive, setDragActive] = useState(false)
  let uploadElement: any

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // Triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      console.log(e.dataTransfer)
    }
  }

  return (
    <div className={'builder-upload builder-upload-drag' + (dragActive ? ' drag-active' : '')}>
      <div
        className='builder-upload builder-upload-btn'
        role='button'
        onClick={() => uploadElement.click()}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}>
        <input type='file' onChange={(e) => onChange(e)} ref={(input) => (uploadElement = input)} style={{ display: 'none' }} multiple />
        <div className='builder-upload-drag-container'>
          <div className='builer-upload-drag-icon text-center'>
            {icon == 'upload' && <FileUploadIcon />}
            {icon == 'cloud' && <CloudUploadIcon />}
          </div>
          <p className='builder-upload-text b-mb-2 text-center'>Click or drag file to this area to upload</p>
        </div>
      </div>
    </div>
  )
}
