import { useState, useEffect } from 'react'

import './Checkout.css'
import '../ShoppingCart/ShoppingCart.css'
import { DeliveryMethod, useShoppingCart } from '../context/ShoppingCartContext'
import CheckoutProductsPanel from './CheckoutProductsPanel'
import CheckoutBuyerPanel from './CheckoutBuyerPanel'
import CheckoutDeliveryPanel from './CheckoutDeliveryPanel'
import CheckoutPaymentPanel from './CheckoutPaymentPanel'
import BuyerModal from './BuyerModal'
import CheckoutDeliveryModal from './CheckoutDeliveryModal'
import CheckoutConfirm from './CheckoutConfirm'
import { CheckoutComponent  } from "../interfaces/base"
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAppSelector } from '../../../store/hooks'
import RenderChildren from "../RenderChildren";
import styled from '@emotion/styled';
const Div = styled('div')(
  null,
  props => function(props: any){
    return {...props['data-style']}
  }
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#359E3F',
    },
  },
})

export default function Checkout({ id, attributes, childElements, childWebpageComponentRelations }: CheckoutComponent) {
  const editorId = useAppSelector((state) => state.textEditor?.get('id'))

  const [showBuyerModal, setShowBuyerModal] = useState(false)
  const [showDeliveryModal, setShowDeliveryModal] = useState(false)
  const [modalDeliveryMethod, setModalDeliveryMethod] = useState<DeliveryMethod | null>(null)

  const { confirmedOrder, cartItems, getOrder } = useShoppingCart()

  useEffect(() => {
    if (!confirmedOrder || !confirmedOrder.uuid) {
      getOrder()
    }
  }, [cartItems])

  return (
    <ThemeProvider theme={theme}>
      <Div
        id={id.toString()}
        key={id}
        data-style={attributes && attributes.styles}
        className={
          attributes &&
          attributes.classes +
            (attributes.classes != 'container' && attributes.classes != 'container-fluid' ? ' builder-component builder-component__checkout' : '') +
            (editorId === id ? ' builder-component--active' : '')
        }
        data-component-type='section'>
        <RenderChildren key={id + "children"} childWebpageComponentRelations={childWebpageComponentRelations}/>
        {confirmedOrder && confirmedOrder.uuid ? (
          <CheckoutConfirm />
        ) : (
          <div
            className='page animsition withoutsidemenu light-gray-background-2 gifticum-page checkout'
            style={{
              animationDuration: '800ms',
              opacity: '1',
            }}>
            <div className='page-header container gifticum-page-header'>
              <nav
                className='margin-bottom-0'
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'none',
                  boxShadow: 'none',
                }}>
                <div
                  className='container-fluid gifticum-page-header-title'
                  style={{
                    color: 'black',
                  }}>
                  TELLIMUSE VORMISTAMINE
                </div>
              </nav>
            </div>

            <div className='page-content container padding-top-0'>
              <div className='row'>
                <div className='col-lg-7'>
                  <CheckoutProductsPanel />
                </div>
                <div className='col-lg-5'>
                  <CheckoutBuyerPanel setShowBuyerModal={setShowBuyerModal} />
                  <CheckoutDeliveryPanel setModalDeliveryMethod={setModalDeliveryMethod} setShowDeliveryModal={setShowDeliveryModal} />
                  <CheckoutPaymentPanel
                    setShowBuyerModal={setShowBuyerModal}
                    setModalDeliveryMethod={setModalDeliveryMethod}
                    setShowDeliveryModal={setShowDeliveryModal}
                  />
                </div>
              </div>
            </div>
            {showBuyerModal && <BuyerModal show={showBuyerModal} setShow={setShowBuyerModal} />}
            {showDeliveryModal && <CheckoutDeliveryModal deliveryMethod={modalDeliveryMethod} show={showDeliveryModal} setShow={setShowDeliveryModal} />}
          </div>
        )}
      </Div>
    </ThemeProvider>
  )
}
