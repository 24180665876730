import { useShoppingCart } from "../context/ShoppingCartContext"

export default function CheckoutBuyerPanelConfirmed({ }) {
    const {
        buyer
    } = useShoppingCart()

    return (
        <div className="panel" id="checkoutBuyerPanel">
            <div className="panel-heading">
                <div className="panel-title">
                    <span className="badge badge-square badge-primary gifticum-panel-title-number">2</span>
                    <span className="gifticum-panel-title-text">
                        OSTJA
                    </span>
                </div>
            </div>
            <div className="panel-body">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="personName">{buyer.firstname} {buyer.surname}</div>
                        <div className="personPhone">{buyer.phone}</div>
                        <div className="personEmail">{buyer.email}</div>
                    </div>
                </div>
            </div>
        </div>
    )


}
