import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";

interface Props {
  setFormModes: (obj: { globalSettings: boolean }) => void;
  website: Website;
}

export default function GlobalSettingsFormView({
  website,
  setFormModes,
}: Props) {
  return (
    <>
      <div className="b-row b-mt-4">
        <div className="col-10 fw-bold">Website: Global settings</div>
        <div className="col-2 b-pt-1 text-end">
          <a
            className="hover-underline typograhpy-button"
            onClick={() => setFormModes({ globalSettings: true })}
          >
            Edit
          </a>
        </div>
        <div className="col-12 b-mt-3">
          Base font size: {website.base_font_size}
        </div>
      </div>
    </>
  );
}
