import "./Checkout.css"
import "../ShoppingCart/ShoppingCart.css"
import { useShoppingCart } from "../context/ShoppingCartContext"
import CheckoutProductsPanelConfirmed from "./CheckoutProductsPanelConfirmed"
import CheckoutBuyerPanelConfirmed from "./CheckoutBuyerPanelConfirmed"
import CheckoutDeliveryPanelConfirmed from "./CheckoutDeliveryPanelConfirmed"
import CheckoutPaymentPanelConfirmed from "./CheckoutPaymentPanelConfirmed"

import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme({
    palette: {
        primary: {
            main: "#359E3F",
        },
    },
});

export default function CheckoutConfirm({ }) {
    return (
      <ThemeProvider theme={theme}>
        <div
          className='page animsition withoutsidemenu light-gray-background-2 gifticum-page checkout'
          style={{
            animationDuration: '800ms',
            opacity: '1',
          }}>
          <div className='page-header container gifticum-page-header'>
            <nav
              className='margin-bottom-0'
              style={{
                backgroundColor: 'transparent',
                borderColor: 'none',
                boxShadow: 'none',
              }}>
              <div
                className='container-fluid gifticum-page-header-title'
                style={{
                  color: 'black',
                }}>
                TELLIMUSE ANDMED
              </div>
            </nav>
          </div>

          <div className='page-content container padding-top-0'>
            <div className='row'>
              <div className='col-lg-7'>
                <CheckoutProductsPanelConfirmed />
              </div>
              <div className='col-lg-5'>
                <CheckoutBuyerPanelConfirmed />
                <CheckoutDeliveryPanelConfirmed />
                <CheckoutPaymentPanelConfirmed />
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    )
}
