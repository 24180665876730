import Website from './Website/Website'
import Pages from './Pages/Pages'
import Colors from './Colors/Colors'
import Languages from './Languages/Languages'
import Templates from './Templates/Templates'
import Typography from './Typography/Typography'
import { SidebarMenus } from '../Sidebar'

export default function SubSidebar({ selectedMenu = '', setSelectedMenu }: { selectedMenu: SidebarMenus, setSelectedMenu: React.Dispatch<React.SetStateAction<SidebarMenus>> }) {
  function getMenu() {
    let Menu
    if (selectedMenu == 'pages') {
      Menu = <Pages setSelectedMenu={setSelectedMenu}/>
    } else if (selectedMenu == 'templates') {
      Menu = <Templates />
    } else if (selectedMenu == 'color') {
      Menu = <Colors />
    } else if (selectedMenu == 'font') {
      Menu = <Typography />
    } else if (selectedMenu == 'flag') {
      Menu = <Languages />
    } else if (selectedMenu == 'settings') {
      Menu = <Website />
    }
    return Menu
  }

  return <div className={'subsidebar' + (selectedMenu == '' ? ' d-none' : '')}>{getMenu() ? getMenu() : ''}</div>
}
