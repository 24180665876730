import {useState} from "react"
import { useShoppingCart } from "../context/ShoppingCartContext"
import { pf } from "../utilities/helpers"

export default function ProductPanel({ locationProduct }: any) {

  const { increaseCartQuantity } = useShoppingCart()

  const [tab, setTab] = useState('descriptionTab')

  return (
    <div className="panel" id="productPanel">
      <div className="panel-body container">
          <div className="col-xs-12">
              <div className="imagesContainer">
                  <h3 className="product-name">{locationProduct.display_name}</h3>
                  <div className="web-product-image">
                      <img src={locationProduct.firstFileUrl} alt="Image" style={{width: "100%"}} />
                  </div>
                  <div className="web-product-images">
                  {
                    locationProduct.product && locationProduct.product.product_files.length > 1 && locationProduct.product.product_files.map((productFile: any) => {
                      return (
                        <div style={{
                          float: "left",
                          width: "calc(100% / " +locationProduct.product.product_files.length+")",
                          padding: "5px",
                        }}>
                          <img src={productFile.url} alt="Image" style={{width: "100%"}} />
                        </div>
                      )
                    })
                  }

                  </div>
                      
              </div>
              <div className="dataContainer">
                  <h3 className="product-name inline-block">{locationProduct.display_name}</h3>
                  <div className="productPrice h4">{pf(locationProduct.price_total)} &euro;</div>
                  <div>
              <button className="btn btn-primary addProductToCart" onClick={() => increaseCartQuantity(locationProduct.id)}>LISA OSTUKORVI</button>
                  </div>
                  <div className="pt-15">
                      <div className="nav-tabs-horizontal" data-plugin="tabs">
                          <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                              <li className="nav-item" role="presentation">
                                <a className={`nav-link${tab == 'descriptionTab' ? ' active' :''}`} onClick={()=>{setTab('descriptionTab')}} href={undefined} aria-controls="descriptionTab" role="tab">Kirjeldus</a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a className={`nav-link${tab == 'deliveryTab' ? ' active' :''}`} onClick={()=>{setTab('deliveryTab')}} href={undefined} aria-controls="deliveryTab" role="tab">Tarne</a>
                              </li>
                          </ul>
                          <div className="tab-content pt-20">
                              <div className={`tab-pane text-left${tab == 'descriptionTab' ? ' active' :''}`} id="descriptionTab" role="tabpanel">
                                <div dangerouslySetInnerHTML={{ __html: locationProduct.product?.description }}></div>
                              </div>

                              <div className={`tab-pane text-left${tab == 'deliveryTab' ? ' active' :''}`} id="deliveryTab" role="tabpanel">
                                  { locationProduct.deliveryTime && (
                                      <div className="deliveryTime">Tarneaeg laos: {locationProduct.deliveryTime.days} päeva</div>
                                    )}
                                  { locationProduct.deliveryTimeWithOrdering && (
                                      <div className="deliveryTimeWithOrdering">Tarneaeg tellimisel: {locationProduct.deliveryTimeWithOrdering.days} päeva</div>
                                    )}
                                  <div>
                                      { locationProduct.deliveryInfo }
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>  
      </div>
    </div>
  )
}
