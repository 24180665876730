import { TextField } from "@mui/material"
import Modal from "react-bootstrap/Modal"
import { useForm, Controller } from "react-hook-form"
import { useShoppingCart } from "../context/ShoppingCartContext"

interface Props {
  show: boolean
  setShow: (val: boolean) => void
}

export default function BuyerModal({ show, setShow }: Props) {
  const { buyer, setBuyer } = useShoppingCart()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      firstname: buyer.firstname ?? '',
      surname: buyer.surname ?? '',
      phone: buyer.phone ?? '',
      email: buyer.email ?? '',
    },
  })

  const onSubmit = (data: any) => {
    setBuyer({ ...buyer, ...data })
    setShow(false)
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} contentClassName='modal-600 buyerModal'>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className='badge badge-square badge-primary me-3'>2</span>
          <span className='fw-bold'>OSTJA</span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-6'>
              <Controller
                name='firstname'
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ style: { color: '#333', fontSize: '14px'}}}
                    fullWidth
                    label='Eesnimi'
                    variant='standard'
                    value={field.value}
                    onChange={field.onChange}
                    inputRef={field.ref}
                    error={errors.firstname?.type == 'required'}
                  />
                )}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='surname'
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ style: { color: '#333', fontSize: '14px'}}}
                    fullWidth
                    label='Perenimi'
                    variant='standard'
                    value={field.value}
                    onChange={field.onChange}
                    inputRef={field.ref}
                    error={errors.surname?.type == 'required'}
                  />
                )}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='email'
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ style: { color: '#333', fontSize: '14px'}}}
                    fullWidth
                    label='E-post'
                    variant='standard'
                    value={field.value}
                    onChange={field.onChange}
                    inputRef={field.ref}
                    error={errors.email?.type == 'required'}
                  />
                )}
              />
            </div>
            <div className='col-md-6'>
              <Controller
                name='phone'
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ style: { color: '#333', fontSize: '14px'}}}
                    fullWidth
                    label='Mobiil'
                    variant='standard'
                    value={field.value}
                    onChange={field.onChange}
                    inputRef={field.ref}
                    error={errors.phone?.type == 'required'}
                  />
                )}
              />
            </div>
          </div>
          <div className='mt-2 links-green'>
            Andmete salvestamiseks tuleb nõustuda{' '}
            <a href='/terms' target='_blank' className='color-primary'>
              kasutustingimustega
            </a>
            ,{' '}
            <a href='/privacy' target='_blank' className='color-primary'>
              küpsiste- ja privaatsuspoliitikaga
            </a>
            .
          </div>
        </Modal.Body>
        <Modal.Footer className=''>
          <button type='submit' className='btn btn-primary w-100'>
            NÕUSTUN TINGIMUSTEGA JA SALVESTAN
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
