import CheckIcon from "@mui/icons-material/Check";
import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";

interface Props {
  handleEdit: (v: boolean) => void;
  website: Website;
}

export default function WebsiteTrackingFormEdit({
  handleEdit,
  website,
}: Props) {
  return (
    <div className="b-row b-mt-4">
      <div className="col-10">
        <div className="col-12 fw-bold">Tracking</div>
      </div>
      <div className="col-2 b-pt-1 text-end" onClick={() => handleEdit(true)}>
        <a className="website-edit-button">Edit</a>
      </div>
      <div className="col-12 b-mt-2 d-flex align-items-center">
        {website!.tracking != "" ? (
          <CheckIcon style={{ color: "#48e348" }} className="b-me-2" />
        ) : (
          ""
        )}
        {website!.tracking}
      </div>
    </div>
  );
}
