import HomeIcon from '@mui/icons-material/Home';

export default function ProductBreadcrumbs({ locationProduct }: any) {
  var parentCategories = locationProduct.productCategories.filter((pc: any) => {
    return pc.parent_id == null;
  })
  var productParentCategoriesArr: any = [];
  function recursiveCategoryChildren(parentCategory: any, depth: number){
    const childCategories = locationProduct.productCategories.filter((pc: any) => {
      return pc.parent_id == parentCategory.id;
    })
    childCategories.forEach((childParentCategory: any) => {
      if(!productParentCategoriesArr[depth]){
        productParentCategoriesArr[depth] = [];
      }
      productParentCategoriesArr[depth].push(childParentCategory);
      recursiveCategoryChildren(childParentCategory, depth + 1);
    });
    parentCategory.childCategories = childCategories;
  }
  parentCategories.forEach((parentCategory: any) => {
    if (productParentCategoriesArr.length === 0) {
      productParentCategoriesArr[0] = []
    }
    productParentCategoriesArr[0].push(parentCategory);
    recursiveCategoryChildren(parentCategory, 1);
  });
  console.log(productParentCategoriesArr)
  return (
    <div className="container">
      <div className="page-header page-detail-page-header">
        <div className="product-detail-page-nav">
          <a className="breadcrumb icon" href="/" role="button"><HomeIcon/> </a>
          <b className="web-category-link-sepparator">/</b>
          {
            productParentCategoriesArr
            && productParentCategoriesArr.length
            && productParentCategoriesArr.map((categories: any, index: string) => {
              return categories.map((category: any) => {
                return (<a key={category.id} className="breadcrumb" href={'/tooted?category='+category.id} role="button">{category.name} </a>)
              }).reduce((prev: any, curr: any) => [prev, ' , ', curr])
            }).reduce((prev: any, curr: any) => [prev, (
                          <b key={curr[0].key+""+curr[2].key} className="web-category-link-sepparator">/</b>
                          ), curr])
          }


        </div>
      </div>
    </div>
  )
}
