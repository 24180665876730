import CartComponent from "./CartComponent"
import LogoComponent from "./LogoComponent"
import NavComponent from "./NavComponent"
import { HeaderComponent as HeaderComponent_, NavComponent as NavComponent_, LogoComponent as LogoComponent_, CartComponent as CartComponent_, NavItemComponent, AllComponents } from './interfaces/base'
import { useAppSelector } from "../../store/hooks"
import RenderChildren from "./RenderChildren";
import styled from '@emotion/styled';
const Header = styled('header')(
  null,
  props => function(props: any){
    return {...props['data-style']}
  }
);
;

export default function HeaderComponent({ id, attributes, childElements, childWebpageComponentRelations }: HeaderComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"))
  const structure = useAppSelector((state) => state.builder.structure)
  console.log("HeaderComponent");
  let childComponents: AllComponents[] = [];
  childWebpageComponentRelations.map(cr => {
    const c = structure.components[cr.child_id];
    if(c){
      childComponents.push(c)
    }
  });

  const logoComponent = childComponents.find((x) => x && x.type === "logo") as LogoComponent_
  const navComponent = childComponents.find((x) => x && x.type === "nav") as NavComponent_
  const cartComponent = childComponents.find((x) => x && x.type === "cart") as CartComponent_

  // TODO: make better
  let visibleComps = (Object.keys(attributes.components) as ['nav', 'logo', 'cart']).filter((item) => attributes.components[item].display != "none")
  let headerJustify = "space-between"
  // let compStyles = {
  //   nav: {
  //     order: attributes.components["nav"].alignment === "left" ? 1 : attributes.components["nav"].alignment === "center" ? 2 : 3,
  //   },
  //   logo: {
  //     order: attributes.components["logo"].alignment === "left" ? 1 : attributes.components["logo"].alignment === "center" ? 2 : 3,
  //   },
  //   cart: {
  //     order: attributes.components["cart"].alignment === "left" ? 1 : attributes.components["cart"].alignment === "center" ? 2 : 3,
  //   },
  // }

  if (new Set(visibleComps.map((item) => attributes.components[item].alignment)).size === visibleComps.length) {
    headerJustify = "space-between"
  }
  
  console.log(attributes.components)
  
  return (
    <Header
      id={id.toString()}
      data-style={attributes.styles ?? ""}
      data-component-type='header'
      className={"builder-component builder-component__header " + (attributes.classes ?? "") + (editorId === id ? " builder-component--active" : "")}
    >
      <RenderChildren key={id + "children"} childWebpageComponentRelations={childWebpageComponentRelations}/>
      <nav className='navbar navbar-expand-lg'>
        <div className='container-fluid' style={{ justifyContent: headerJustify }}>
          {/* @ts-expect-error Server Component */}
          {logoComponent && attributes.components.logo.type !== 'none' && (<LogoComponent {...logoComponent} />)}
          {navComponent && attributes.components.nav.display && ( <NavComponent {...navComponent} /> )}
          {cartComponent && attributes.components.cart.display && ( <CartComponent {...cartComponent} /> )}
        </div>
      </nav>
    </Header>
  )
}
