import { fetchApi } from '../../../../api/api'

export interface ApiClient {
  id: number
  name: string
  location_id: number | null
  password_client: boolean
  personal_access_client: boolean
  provider: null
  redirect: string
  revoked: boolean
  server_id: number | null
  updated: string
  updated_at: string
  created_at: string
  user_id: number
  website_id: number
  comment: string | null
}

import {
  setApiClients,
} from './api_clientsActions'
const ApiClientsAPI = {
    loadAPIClients
}
export default ApiClientsAPI

export function loadAPIClients() {
    return fetchApi<ApiClient[]>(`/apis`).then((res: any) => setApiClients(res))
}