import { Website } from '../../../reducers/builderReducers/website/websiteApi'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: Website = {
  id: 0,
  name: '',
  base_font_size: '',
  row_color: '',
  element_color: '',
  description: '',
  column_color: '',
  content_width_unit: '',
  content_width_size: 0,
  status: '',
  tracking: '',
  type: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
  use_browser_language: false,
  parent_custom_file_id: 0,
  favicon: undefined
}

export const websiteSlice = createSlice({
  name: 'website',
  initialState,
  reducers: {
    UPDATE_WEBSITE: (state, action: PayloadAction<Website>) => {
      state = action.payload
      var r = document.querySelector(':root') as any;
      r.style.setProperty('--builder-row-color', state.row_color)
      r.style.setProperty('--builder-col-color', state.column_color)
      r.style.setProperty('--builder-element-color', state.element_color)
      return state
    },

  },
})

// Action creators are generated for each case reducer function
export const { UPDATE_WEBSITE } = websiteSlice.actions

export default websiteSlice.reducer