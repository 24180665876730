import { useState } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import WebsiteFaviconFormEdit from "./WebsiteFaviconFormEdit";

export default function WebsiteFaviconForm() {
  const website = useAppSelector((state) => state.builder.website);
  const [editing, setEditing] = useState(false);
  return (
    <div className="b-row b-mt-4">
      <div className="col-10 fw-bold">Page favicon</div>
      {editing ? (
        <WebsiteFaviconFormEdit setEditing={setEditing} website={website} />
      ) : (
        <>
          <div className="col-2 b-pt-1 text-end">
            <a
              className="website-edit-button"
              onClick={() => setEditing(!editing)}
            >
              Edit
            </a>
          </div>
          <div className="b-row b-mt-3 b-mb-4">
            <div className="b-mx-1">
              {website.favicon && (
                <img height={50} width={50} src={website.favicon.url}></img>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
