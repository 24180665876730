import { createContext, ReactNode, useContext, useState } from "react"
import ShoppingCart from "../ShoppingCart/ShoppingCart"
import { useLocalStorage } from "../hooks/useLocalStorage"

type ShoppingCartProviderProps = {
  children: ReactNode
}

type CartItem = {
  id: number
  quantity: number
}

export interface ProductCategory {
  id: number
  name: string
  state_id: number
  display_color: string
  order_by: number
  web_state_id: number
  cashregisterChildren: ProductCategory[]
}

export interface LocationProduct {
  id: number
  shortDescription: string
  firstFileUrl: string
  display_name: string
  price_total: number
  unit_shortname: string
  productCategories: ProductCategory[]
  inStock: boolean
}

type Order = {
  id: number | null
  uuid: string | null
  delivery_time: string | null
}

type Person = {
  id: number
  firstname: string
  surname: string
  phone: string
  email: string
}

export type PaymentMethod = {
  id: number
  name: string
  value: string
  sort_order: number
  state: number
  description: string
  image: string
  api_client_id: number
  getMaksekeskusApiActive: {
    id: number
    name: string
    pivot: {
      payment_method_id: number
      attribute_id: number
      value: string
    }
  }
  imageUrl: string
  pivot: {
    person_id: number
    payment_method_id: number
    location_id: number
  }
  api_client: {
    id: 2
    type: string
    status: string
    name: null | string
    comment: string
    url: string
    public_key: string
    secret: string
    client_id: string
    environment: string
    last_connection: string
    created_at: string
    updated_at: string
    deleted_at: null | string
    maksekeskus_payment_methods: Array<{
      id: number
      payment_method_id: number | null
      api_client_id: number
      name: string
      url: string
      country: string
      logo_url: string
      type: string
      created_at: string
      updated_at: string
      deleted_at: null | string
    }>
  }
}

interface DeliveryMethodPrice {
  id: number
  delivery_method_id: number
  name: string
  price: number
  price_neto: string
  vat: number
  weight_min: number
  weight_max: number
  sort_order: number
  created_at: string
  updated_at: string
  deleted_at: null | string
}

export type DeliveryMethod = {
  id: number
  name: string
  description: string
  sort_order: number
  created_at: string
  updated_at: string
  deleted_at: null | string
  type: string
  deliveryMethodPrice: DeliveryMethodPrice
  isParcelMachine: boolean
  getDeliveryLocationsUrl: string
}

type OrderDeliveryMethod = {
  id: number
  order_id: number
  delivery_method_id: number
  delivery_method_price_id: number
  address: string
  city: string
  postal_code: string
  parcelMachineName: string | null
  parcelDescription: string
  buyer_is_receiver: number
  receiverFirstname: string
  receiverSurname: string
  receiverEmail: string
  receiverPhone: string
  price_neto: number
  price: number
  vat: string
  comment: string | null
  delivery_method_name: string
  delivery_method_type: string
  delivery_method_description: string
  delivery_method_price_name: string
  weight: number
  weight_min: number
  weight_max: number
  estimated_delivery_at: string
  delivered_at: null
  created_at: string
  updated_at: string
  deleted_at: string | null
}

type ShoppingCartContext = {
  openCart: () => void
  closeCart: () => void
  toggleCart: () => void
  getItemQuantity: (id: number) => number
  increaseCartQuantity: (id: number) => void
  decreaseCartQuantity: (id: number) => void
  setCartQuantity: (id: number, quantity: number) => void
  removeFromCart: (id: number) => void
  getOrder: () => void
  cartQuantity: number
  cartItems: CartItem[]
  order: Order
  buyer: Person
  setBuyer: (value: React.SetStateAction<Person>) => void
  paymentMethods: PaymentMethod[]
  orderPaymentMethod: PaymentMethod | null
  setOrderPaymentMethod: (value: React.SetStateAction<PaymentMethod | null>) => void
  deliveryMethods: DeliveryMethod[]
  confirmDeliveryData: (data: Object) => void
  orderDeliveryMethod: OrderDeliveryMethod | null
  setOrderDeliveryMethod: (value: React.SetStateAction<OrderDeliveryMethod | null>) => void
  locationProducts: LocationProduct[]
  setLocationProducts: (value: React.SetStateAction<LocationProduct[]>) => void
  checkoutConfirm: () => void
  clearData: () => void
  setConfirmedOrder: (value: React.SetStateAction<Order>) => void
  confirmedOrder: Order
  cartItemsConfirmed: CartItem[]
}

const ShoppingCartContext = createContext({} as ShoppingCartContext)

export function useShoppingCart() {
  return useContext(ShoppingCartContext)
}
export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
  const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethod[]>([])
  const [orderDeliveryMethod, setOrderDeliveryMethod] = useState<OrderDeliveryMethod | null>(Object)
  const [orderPaymentMethod, setOrderPaymentMethod] = useState<PaymentMethod | null>(Object)

  const [confirmedOrder, setConfirmedOrder] = useState<Order>(Object)
  const [cartItemsConfirmed, setCartItemsConfirmed] = useState<CartItem[]>([])

  const STORAGE_EXP_LENGTH = 86400000
  const [cartExpire, setCartExpire] = useLocalStorage<Date>('shopping-cart-expire', new Date(Date.now() + STORAGE_EXP_LENGTH))
  const [cartItems, setCartItems] = useLocalStorage<CartItem[]>('shopping-cart', [])
  const [buyer, setBuyer] = useLocalStorage<Person>('buyer', Object)
  const [locationProducts, setLocationProducts] = useLocalStorage<LocationProduct[]>('locationProducts', [])
  const [order, setOrder] = useLocalStorage<Order>('order', Object)

  if (new Date(cartExpire) <= new Date()) {
    console.log('Cart expired')
    clearData()
  }

  function clearData() {
    setCartExpire(new Date(Date.now() + STORAGE_EXP_LENGTH))
    setCartItems([])
    setBuyer(Object)
    // setLocationProducts([])
    setOrder(Object)
    setOrderPaymentMethod(null)
    setOrderDeliveryMethod(null)
    setDeliveryMethods([])
    setPaymentMethods([])
  }

  function checkoutConfirm() {
    // @ts-ignore
    window.axios.post('checkoutConfirm', {
        order: order,
        orderPaymentMethod: orderPaymentMethod,
        buyer: buyer,
      })
      .then((res: any) => {
        if (res.data.maksekeskusRedirect) {
          window.location.href = res.data.maksekeskusRedirect
        } else {
          setConfirmedOrder(res.data.order)
          setCartItemsConfirmed(cartItems)

          setCartItems([])
          setOrder({ id: null, uuid: null, delivery_time: null })
        }
      })
      .catch((error: any) => {
        console.log(error)
        alert('Midagi läks valesti!')
      })
  }

  function confirmDeliveryData(data: Object) {
    setCartExpire(new Date(Date.now() + STORAGE_EXP_LENGTH))
    // @ts-ignore
    window.axios.post('confirmDeliveryData', {
        ...data,
        order: order,
      })
      .then((res: any) => {
        if (res.data.order) {
          setOrder(res.data.order)
        } else {
          setOrder(Object)
        }
        setOrderDeliveryMethod(res.data.orderDeliveryMethod)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  function getOrder() {
    setCartExpire(new Date(Date.now() + STORAGE_EXP_LENGTH))
    // @ts-ignore
    window.axios.post('getOrder', {
        cartItems: cartItems,
        order: order,
      })
      .then((res: any) => {
        if (res.data.order) {
          setOrder(res.data.order)
          
          let cartHasItemsThatNoLongerExist = false;
          cartItems.map((item) => {
            if(!res.data.order.order_products.find((order_product: any) => item.id === order_product.location_product_id)){
              cartHasItemsThatNoLongerExist = true;
            }
          });
          if(cartHasItemsThatNoLongerExist){
          setCartItems((currItems) => {
            return currItems.filter((item) => {
              return res.data.order.order_products.find((order_product: any) => item.id === order_product.location_product_id)
            })
          })
        }
        }
        if (res.data.paymentMethods) {
          setPaymentMethods(res.data.paymentMethods)
        }
        if (res.data.deliveryMethods) {
          setDeliveryMethods(res.data.deliveryMethods)
        }
        if (res.data.orderDeliveryMethod) {
          setOrderDeliveryMethod(res.data.orderDeliveryMethod)
        }
        if (res.data.locationProducts) {
          setLocationProducts((currItems) => {
            let newItems = [...currItems]
            res.data.locationProducts.map((locationProduct: LocationProduct) => {
              if (currItems.find((item) => item.id === locationProduct.id) == null) {
                newItems = [...newItems, locationProduct]
              } else {
                newItems = newItems.map((item) => {
                  if (item.id === locationProduct.id) {
                    return { ...item, ...locationProduct }
                  } else {
                    return item
                  }
                })
              }
            })
            return newItems
          })
        }

        if (res.data.orderCompleted) {
          setConfirmedOrder(res.data.order)
          setCartItemsConfirmed(cartItems)
          if (res.data.orderPaymentMethod) {
            setOrderPaymentMethod(res.data.orderPaymentMethod)
          }

          setCartItems([])
          setOrder({ id: null, uuid: null, delivery_time: null })
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const cartQuantity = cartItems.reduce((quantity, item) => item.quantity + quantity, 0)

  const openCart = () => setIsOpen(true)
  const closeCart = () => setIsOpen(false)
  function getItemQuantity(id: number) {
    return cartItems.find((item) => item.id === id)?.quantity || 0
  }
  function toggleCart() {
    setIsOpen((curr) => !curr)
  }
  function increaseCartQuantity(id: number) {
    setCartExpire(new Date(Date.now() + STORAGE_EXP_LENGTH))
    setCartItems((currItems) => {
      if (currItems.find((item) => item.id === id) == null) {
        return [...currItems, { id, quantity: 1 }]
      } else {
        return currItems.map((item) => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity + 1 }
          } else {
            return item
          }
        })
      }
    })
    openCart()
  }
  function decreaseCartQuantity(id: number) {
    setCartExpire(new Date(Date.now() + STORAGE_EXP_LENGTH))
    setCartItems((currItems) => {
      if (currItems.find((item) => item.id === id)?.quantity === 1) {
        return currItems
        // return currItems.filter(item => item.id !== id)
      } else {
        return currItems.map((item) => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity - 1 }
          } else {
            return item
          }
        })
      }
    })
  }
  function setCartQuantity(id: number, quantity: number) {
    setCartExpire(new Date(Date.now() + STORAGE_EXP_LENGTH))
    setCartItems((currItems) => {
      if (quantity <= 0) {
        return currItems.filter((item) => item.id !== id)
      } else {
        return currItems.map((item) => {
          if (item.id === id) {
            return { ...item, quantity: quantity }
          } else {
            return item
          }
        })
      }
    })
  }
  function removeFromCart(id: number) {
    setCartExpire(new Date(Date.now() + STORAGE_EXP_LENGTH))
    setCartItems((currItems) => {
      return currItems.filter((item) => item.id !== id)
    })
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        setCartQuantity,
        removeFromCart,
        openCart,
        closeCart,
        toggleCart,
        cartItems,
        cartQuantity,
        locationProducts,
        setLocationProducts,
        clearData,
        buyer,
        setBuyer,
        order,
        getOrder,
        deliveryMethods,
        orderDeliveryMethod,
        setOrderDeliveryMethod,
        confirmDeliveryData,
        paymentMethods,
        orderPaymentMethod,
        setOrderPaymentMethod,
        checkoutConfirm,
        setConfirmedOrder,
        confirmedOrder,
        cartItemsConfirmed,
      }}>
      {children}
      <ShoppingCart isOpen={isOpen} />
    </ShoppingCartContext.Provider>
  )
}