import { fetchApiGet, fetchApiPost, fetchApiPut } from '../../../../api/api'
import {
    updateLanguageSettings,
} from './languageSettingsActions'

export interface Language {
  id: number
  locale: string
  name: string
  icon: string
  order: number
  created_at: string
  updated_at: string
  deleted_at: string | null
  website_id: number
}

export interface LanguageSetting {
  useBrowserLanguage: boolean
  activeLanguages: Array<Language>
}


const LanguageSettingsAPI = {
    saveLanguageSettings,
    loadLanguageSettings,
}
export default LanguageSettingsAPI

export function loadLanguageSettings() {
  return fetchApiGet<LanguageSetting>(`/languageSettings`, {}).then((res) => updateLanguageSettings(res))
}

export function saveLanguageSettings(data: LanguageSetting) {
  return fetchApiPut(`/languageSettings/`, data).then(() => updateLanguageSettings(data))
}
