import React from "react";
import { useState, useEffect, useRef } from "react";
import Header from "../components/BuilderComponents/Header/Header";
import Sidebar from "../components/BuilderComponents/Sidebar/Sidebar";
import BuilderContent from "../components/BuilderComponents/BuilderContent/BuilderContent";
import { setActivePage } from "../store/reducers/builderReducers/pages/pagesActions";
import WebsiteAPI from "../store/reducers/builderReducers/website/websiteApi";
import TypographiesAPI from "../store/reducers/builderReducers/typographies/typographiesApi";
import StructureAPI from "../store/reducers/builderReducers/structure/structureApi";
import ReusableComponentsAPI from "../store/reducers/builderReducers/reusableComponents/reusableComponentsApi";
import PagesAPI from "../store/reducers/builderReducers/pages/pagesApi";
import LanguageSettingsAPI from "../store/reducers/builderReducers/languageSettings/languageSettingsApi";
import FontOptionsAPI from "../store/reducers/builderReducers/font_options/font_optionsApi";
import FontUrlAPI from "../store/reducers/builderReducers/font_url/font_urlApi";
import ColorAPI from "../store/reducers/builderReducers/color/colorApi";
import { updateEditor } from "../store/reducers/builderReducers/editor/editorActions";
import { useAppSelector } from "../store/hooks";
import { Pages } from "../store/reducers/builderReducers/pages/pagesApi";
import { inClass } from "../utils/Helpers";
import {
  addNewComponent,
  deleteComponentById,
} from "../store/reducers/builderReducers/structure/structureActions";
import { setEditableComponentId } from "../store/reducers/textEditor/textEditorActions";
import TemplateApi from "../store/reducers/builderReducers/templates/templateApi";

export const editorId = -11;

export default function Builder() {
  const [sidebarActive, toggleSidebar] = useState(true);
  const components = useAppSelector(
    (state) => state.builder.structure.components
  );
  const editorComponent = components[editorId]!;
  // const components = structure.components;
  const pages = useAppSelector((state) => state.builder.pages.list);
  const activePageId = useAppSelector(
    (state) => state.builder.pages.active as Pages["active"]
  );
  // const fontUrl = useAppSelector((state) => state.builder.font_url.url)
  const prevActivePageId: any = useRef();
  const templates = useAppSelector((state) => state.builder.templates);
  // console.log("Builder");
  // console.log("Builder activePageId " + activePageId);

  // On first load
  useEffect(() => {
    PagesAPI.loadWebpages();
    TypographiesAPI.loadTypographies();
    FontUrlAPI.getFontUrl();
    FontOptionsAPI.getFontOptions();
    ColorAPI.loadColors();
    WebsiteAPI.loadWebsite();
    LanguageSettingsAPI.loadLanguageSettings();
    ReusableComponentsAPI.loadReusableComponents();
    if (!templates.size) {
      TemplateApi.loadTemplates();
    }
  }, []);

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    // console.log("handleClick");
    let clickedElement = e.target as HTMLElement;
    let clickedElementId = parseInt(clickedElement.id, 10);

    const builderComponent = inClass("builder-component", clickedElement);
    if (builderComponent && !Number.isNaN(clickedElementId)) {
      let parentComponentId = inClass(
        "builder-component",
        clickedElement.parentElement!
      );

      updateEditor({
        component_id: parseInt(builderComponent.id, 10),
        component_parent_id: parentComponentId?.id
          ? parseInt(parentComponentId?.id!)
          : null,
      });
    }

    if (
      (!inClass("global-editor", clickedElement) && // is editor component
        !inClass("builder-component--active", clickedElement)) || // is builder component and not active
      (clickedElement.classList.contains("builder-component") &&
        !clickedElement.classList.contains("builder-component--active")) // is builder component and not active
    ) {
      // const editorComponent = structure.components[editorId]
      if (inClass("builder-component", clickedElement) && clickedElementId) {
        // if(document.getElementsByClassName('global-editor__menu').length){
        if (editorComponent) {
          // console.log("remove editor");
          deleteComponentById(editorId);
        }
        // console.log("add editor");

        addNewComponent({
          id: editorId,
          uuid: null,
          type: "editor",
          sort_order: 1,
          parent_id: clickedElementId,
          childElements: [],
          childWebpageComponentRelations: [],
          version: 0,
          webpage_id: pages[0].website_id,
          attributes: {
            classes: "",
            styles: {},
            typography_id: 1,
          },
        });

        setEditableComponentId(clickedElementId);
      } else if (
        !inClass("react-select-container", clickedElement) &&
        !inClass("builder-dropdown", clickedElement)
      ) {
        // console.log("unsetEditElementRef");
        unsetEditElementRef(e);
        if (editorComponent) {
          // console.log("remove editor");
          deleteComponentById(editorId);
        }
      }
    }
  }

  function unsetEditElementRef(e: React.MouseEvent<HTMLElement>) {
    const component = inClass("builder-component", e.target as HTMLElement);
    const modal = inClass("modal", e.target as HTMLElement);
    if (!component && !modal) {
      setEditableComponentId(null);
    }
  }

  // When pages are loaded, get current pages components
  useEffect(() => {
    if (pages && pages.length) {
      // console.log(prevActivePageId.current)
      // console.log(activePageId)
      // console.log(components.length)
      // if ( activePageId && activePageId !== prevActivePageId.current) {
      //   console.log("loadWebpageComponents2")
      //   prevActivePageId.current = activePageId
      //   StructureAPI.loadWebpageComponents(activePageId)
      // } else if (!components.length) {
      //   console.log("loadWebpageComponents")
      //   StructureAPI.loadWebpageComponents(pages[0].id)
      //   prevActivePageId.current = pages[0].id
      //   setActivePage(pages[0].id)
      // }
      if (!activePageId) {
        setActivePage(pages[0].id);
      }
    }
  }, [pages]);

  return (
    <div
      className="d-flex flex-column h-100 w-100"
      onMouseDown={(e) => handleClick(e)}
    >
      <Header sidebarActive={sidebarActive} toggleSidebar={toggleSidebar} />
      <div className="app-layout">
        {sidebarActive && <Sidebar />}
        <div className="app-content">
          <BuilderContent></BuilderContent>
        </div>
      </div>
    </div>
  );
}
