import { useState } from "react";
import PageDetailsGeneralEditForm from "./PageDetailsGeneralEditForm";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PageDetailsLanguagesViewForm from "./PageDetailsLanguagesViewForm";
import PageDetailsLanguagesEditForm from "./PageDetailsLanguagesEditForm";
import PageDetailsSeoEditForm from "./PageDetailsSeoEditForm";
import { deleteWebpageById } from "../../../../../store/reducers/builderReducers/pages/pagesActions";
import { useAppSelector } from "../../../../../store/hooks";
import { Pages } from "../../../../../store/reducers/builderReducers/pages/pagesApi";
import { Language } from "../../../../../store/reducers/builderReducers/languageSettings/languageSettingsApi";
import PageDetailsSeoViewForm from "./PageDetailsSeoViewForm";

interface Props {
  page: Pages["list"][0];
  setEditableId: (id: number | null) => void;
  webpages: Pages["list"];
}

export default function PageDetails({ page, setEditableId, webpages }: Props) {
  const website = useAppSelector((state) => state.builder.website)!;
  const languages = useAppSelector(
    (state) => state.builder.languageSettings.activeLanguages as Language[]
  );

  const [formEditModes, setFormEditModes] = useState({
    general: !page.id ? true : false,
    languages: false,
    seo: false,
  });

  function setFormModes(newMode: {}) {
    setFormEditModes((modes) => ({
      ...modes,
      ...newMode,
    }));
  }

  function unsetEditableId() {
    setEditableId(null);
    if (!page.id) deleteWebpageById(0); // If new webpage, delete
  }

  return (
    <div className="b-container">
      <div className="b-row align-items-center b-mb-3">
        <div className="col-2">
          <div
            className="back-btn d-flex align-items-center justify-content-center"
            onClick={() => unsetEditableId()}
          >
            <ChevronLeftIcon />
          </div>
        </div>
        <div className="col-8 d-flex flex-column">
          <div className="fw-bold">Page: {page.name}</div>
          <div className="fs-small">{website.name + "/" + page.url}</div>
        </div>
        {!formEditModes.general && (
          <div className="col-2 text-end">
            <a
              className="pages-button"
              onClick={() => setFormModes({ general: true })}
            >
              Edit
            </a>
          </div>
        )}
      </div>
      {formEditModes.general && (
        <PageDetailsGeneralEditForm
          handleEdit={setFormModes}
          website={website}
          page={page}
          setEditableId={setEditableId}
        />
      )}
      {formEditModes.languages ? (
        <PageDetailsLanguagesEditForm
          handleEdit={setFormModes}
          page={page}
          languages={languages}
          webpages={webpages}
        />
      ) : page.id ? (
        <PageDetailsLanguagesViewForm handleEdit={setFormModes} page={page} />
      ) : null}
      {formEditModes.seo ? (
        <PageDetailsSeoEditForm
          page={page}
          handleEdit={setFormModes}
          website={website}
        />
      ) : page.id ? (
        <PageDetailsSeoViewForm
          page={page}
          handleEdit={setFormModes}
          website={website}
        />
      ) : null}
    </div>
  );
}
