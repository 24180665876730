import { LanguageSetting } from "../../../../../store/reducers/builderReducers/languageSettings/languageSettingsApi";

interface Props {
  languageSetting: LanguageSetting;
  handleEdit: (obj: {}) => void;
}

export default function LanguagesActiveFormView({
  languageSetting,
  handleEdit,
}: Props) {
  return (
    <>
      <div className="b-row b-mb-3">
        <div className="col-10 fw-bold">Website: Languages</div>
        <div className="col-2 b-pt-1 text-end">
          <a
            className="hover-underline languages-button"
            onClick={() => handleEdit({ languagesActive: true })}
          >
            Edit
          </a>
        </div>
      </div>
      {languageSetting!.activeLanguages.map((lang) => (
        <div key={lang.id} className="b-row">
          <div className="col-12 b-mb-2 d-flex align-items-center">
            <img src={lang.icon} height="21px" width="32px"></img>
            <label className="b-ps-3">{lang.name}</label>
          </div>
        </div>
      ))}
    </>
  );
}
