import Modal from 'react-bootstrap/Modal'
import { DeliveryMethod, useShoppingCart } from '../context/ShoppingCartContext'
import { pf } from '../utilities/helpers'

interface Props {
  show: boolean
  setShow: (val: boolean) => void
  setModalDeliveryMethod: (val: DeliveryMethod | null) => void
  setShowDeliveryModal: (val: boolean) => void
}

export default function CheckoutSelectDeliveryMethodModal({ show, setShow, setModalDeliveryMethod, setShowDeliveryModal }: Props) {
  const { deliveryMethods } = useShoppingCart()

  return (
    <Modal show={show} onHide={() => setShow(false)} contentClassName='modal-600'>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className='badge badge-square badge-primary me-3'>3</span>
          <span className='fw-bold'>VALI TARNEVIIS</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {deliveryMethods
          ? deliveryMethods.map((dm) => (
              <div key={dm.id} className='col-xs-12 margin-bottom-10'>
                <button
                  className='btn btn-round btn-primary-outline text-left checkoutDeliveryButton'
                  style={{ width: '100%' }}
                  onClick={() => {
                    setShow(false)
                    setModalDeliveryMethod(dm)
                    setShowDeliveryModal(true)
                  }}>
                  {dm.name}
                  <span className='pull-right'> {pf(dm.deliveryMethodPrice.price)} &euro;</span>
                </button>
              </div>
            ))
          : ''}
      </Modal.Body>
    </Modal>
  )
}
