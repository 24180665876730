import { useShoppingCart } from "../context/ShoppingCartContext"
import { CartItemRow } from "../ShoppingCart/CartItemRow"
import { pf } from "../utilities/helpers"

export default function CheckoutProductsPanel() {

    const {
        locationProducts,
        cartItems
    } = useShoppingCart()


    return (
        <div className="panel" id="checkoutProductsPanel">
            <div className="panel-heading">
                <div className="panel-title">
                    <span className="badge badge-square badge-primary gifticum-panel-title-number">1</span>
                    <span className="gifticum-panel-title-text">
                        TOOTED
                    </span>
                </div>
            </div>
            <div className="panel-body">
                <div className="row">
                    <div className="col-xs-12 padding-top-20">
                        <table className="table tablerow-no-border cartTable">
                            <tbody>
                                {cartItems.map(item => (
                                    <CartItemRow key={item.id} {...item} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-xs-12 padding-top-20">
                        <h4>KOKKU <span className="pull-right"><span className="cartTotal">
                            {pf(
                                cartItems.reduce((total, cartItem) => {
                                    const item = locationProducts.find(i => i.id === cartItem.id)
                                    return total + (item?.price_total || 0) * cartItem.quantity
                                }, 0)
                            )}
                        </span> &euro;</span></h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
