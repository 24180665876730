import Modal from "react-bootstrap/Modal"
import { useForm } from "react-hook-form"
import { PaymentMethod, useShoppingCart } from '../context/ShoppingCartContext'

interface Props {
  show: boolean
  setShow: (val: boolean) => void
  paymentMethod: PaymentMethod | null
}

export default function CheckoutMaksekeskusModal({ show, setShow, paymentMethod }: Props) {
  const { setOrderPaymentMethod } = useShoppingCart()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'all',
  })

  const onSubmit = (data: any) => {
    setOrderPaymentMethod({ ...paymentMethod, ...data })
    setShow(false)
  }

  const onErrors = (data: any) => {
    console.log(data)
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false)
      }}
      contentClassName='modal-600 paymentMethodModal'>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className='badge badge-square badge-primary me-3'>4</span>
          <span className='fw-bold'>MAKSEVIIS</span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <Modal.Body>
          <div className='row margin-top-5 padding-10'>
            <button className='btn btn-round btn-primary-outline col-xs-12 text-left'>
              <span className='mk-payment-title'>{paymentMethod?.name}</span>
            </button>
          </div>

          <div className='row margin-top-10'>
            <div className='col-xs-12' style={{fontSize: '15px'}}>Kliki selle maksekanali logo peale, mida soovid kasutada.</div>
          </div>

          <div className='row margin-top-20'>
            <div className='col-xs-12'>
              <div className='mk-payment-options'>
                {paymentMethod?.api_client && paymentMethod.api_client.maksekeskus_payment_methods
                  ? paymentMethod.api_client.maksekeskus_payment_methods.map((mpm) => (
                      <label key={mpm.id} className='mk-payment-options__option'>
                        <input {...register('maksekeskusPaymentMethod', { required: true })} type='radio' value={mpm.id} style={{ display: 'none' }} />
                        <img src={mpm.logo_url} />
                      </label>
                    ))
                  : ''}
                {errors.maksekeskusPaymentMethod && <p>This is required</p>}
              </div>
            </div>
          </div>

          <div className='mt-2 links-green'>
            Andmete salvestamiseks tuleb nõustuda{' '}
            <a href='/terms' target='_blank' className='color-primary'>
              kasutustingimustega
            </a>
            ,{' '}
            <a href='/privacy' target='_blank' className='color-primary'>
              küpsiste- ja privaatsuspoliitikaga
            </a>
            .
          </div>
        </Modal.Body>
        <Modal.Footer className=''>
          <button type='submit' className='btn btn-primary w-100'>
            NÕUSTUN TINGIMUSTEGA JA SALVESTAN
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
