import { Color } from "../../../../../store/reducers/builderReducers/color/colorApi";

interface Props {
  colors: Color[];
  handleEdit: (obj: {}) => void;
}
export default function ColorsFormView({ handleEdit, colors }: Props) {
  let colorElements: JSX.Element[] = [];
  colors?.forEach((color) => {
    colorElements.push(
      <div key={color.id} className="col-12 d-flex b-py-1">
        <svg
          className="color-box"
          style={{ backgroundColor: color.color }}
        ></svg>
        <label className="b-ps-3 align-self-center">{color.name}</label>
      </div>
    );
  });

  return (
    <>
      <div className="b-row">
        <div className="col-10 fw-bold">Website: Colors</div>
        <div className="col-2 b-pt-1 text-end">
          <a
            className="colors-button"
            onClick={() => handleEdit({ colors: true })}
          >
            Edit
          </a>
        </div>
      </div>
      <div className="b-row b-mt-3">{colorElements}</div>
    </>
  );
}
