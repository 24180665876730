import React from "react"
import { useShoppingCart } from "../context/ShoppingCartContext"
import { CartItemRow } from "./CartItemRow"
import CloseIcon from '@mui/icons-material/Close';
import "./ShoppingCart.css"
import { pf } from "../utilities/helpers";

type ShoppingCartProps = {
  isOpen: boolean
}

export default function ShoppingCart({ isOpen }: ShoppingCartProps) {
  const {
    closeCart,
    locationProducts,
    cartItems
  } = useShoppingCart()
  const cartSidepanel = React.useRef() as React.MutableRefObject<HTMLInputElement>

  const closeCartSidepanel = (e: any) => {
    if (cartSidepanel.current && !cartSidepanel.current.contains(e.target)) {
      closeCart()
    }
  }
  document.addEventListener('mousedown', closeCartSidepanel)
  return (
    <div ref={cartSidepanel} className="cart-sidePanel">
      <div className="sidePanels">
        <div id="cartSidepanel" className={`sidePanel${isOpen ? ' visible' : ''}`}>
          <div style={{ fontSize: '21px', fontWeight: '400' }}>
            <h3 className="panel-title inline-block">OSTUKORV</h3>
            <CloseIcon className="btn-pure clickable padding-5 pull-right" onClick={() => closeCart()} />
          </div>
          <div className="" style={{ height: 'calc(100% - 59px)', overflowX: 'auto' }}>
            <div className="table-container padding-top-20" style={{ overflowX: 'auto', maxHeight: 'calc(100% - 120px)', minHeight: '150px !important' }}>
              <table className="table tablerow-no-border cartTable">
                <tbody>
                  {cartItems.map(item => (
                    <CartItemRow key={item.id} {...item} />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="padding-top-20">
              <h4>KOKKU <span className="pull-right"><span className="cartTotal">{
                pf(
                  cartItems.reduce((total, cartItem) => {
                    const item = locationProducts.find(i => i.id === cartItem.id)
                    return total + (item?.price_total || 0) * cartItem.quantity
                  }, 0)
                )
              }</span> &euro;</span></h4>
            </div>
            <div className="padding-top-20">
              <a className="btn btn-round btn-primary" href="/checkout" style={{ width: '100%', color: 'white' }}>VORMISTA TELLIMUS</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
