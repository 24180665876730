import { FormEvent, useState } from "react";
import LanguageSettingsAPI from "../../../../../store/reducers/builderReducers/languageSettings/languageSettingsApi";
import { LanguageSetting } from "../../../../../store/reducers/builderReducers/languageSettings/languageSettingsApi";

interface Props {
  languageSetting: LanguageSetting;
  handleEdit: (obj: {}) => void;
}

export default function LanguagesSettingsFormEdit({
  handleEdit,
  languageSetting,
}: Props) {
  const [cacheUseBrowserLanguage, setCacheUseBrowserLanguage] = useState(
    languageSetting!.useBrowserLanguage
  );

  function handleSubmit(evt: FormEvent) {
    evt.preventDefault();
    LanguageSettingsAPI.saveLanguageSettings({
      ...languageSetting!,
      useBrowserLanguage: cacheUseBrowserLanguage,
    }).then(() => {
      handleEdit({ settings: false });
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row b-mt-4">
        <div className="col-12 b-pb-1 fw-bold">Settings</div>
      </div>
      <div className="b-row b-mt-3">
        <div className="col-12">
          <div className="form-check b-form-check form-switch large">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="websiteLang"
              checked={cacheUseBrowserLanguage}
              onChange={(evt) => {
                setCacheUseBrowserLanguage(evt.target.checked);
              }}
            />
            <label className="form-check-label" htmlFor="websiteLang">
              Set website language according to browser language
            </label>
          </div>
        </div>
      </div>
      <div className="b-mt-3">
        <button className="sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => handleEdit({ settings: false })}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
