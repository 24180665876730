import React, { useEffect, useState } from "react";
import PagesAPI from "../../../../../store/reducers/builderReducers/pages/pagesApi";
import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";
import { Pages } from "../../../../../store/reducers/builderReducers/pages/pagesApi";

interface Props {
  page: Pages["list"][0];
  website: Website;
  handleEdit: (obj: {}) => void;
}

export default function PageDetailsSeoEditForm({
  page,
  website,
  handleEdit,
}: Props) {
  const [cachedPageInfo, setCachedPageInfo] = useState({
    seo_no_index: page.seo_no_index,
    seo_keyword: page.seo_keyword ?? "",
    seo_title: page.seo_title ?? "",
    seo_description: page.seo_description ?? "",
  });

  function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    PagesAPI.saveWebpageById(page.id, { ...page, ...cachedPageInfo }).then(
      () => {
        handleEdit({ seo: false });
      }
    );
  }

  function changeHeight(evt: React.ChangeEvent<HTMLTextAreaElement>) {
    console.log(evt);
    evt.target.style.height = evt.target.scrollHeight + "px";
  }

  useEffect(() => {
    document
      .getElementById("seoDescription")
      ?.dispatchEvent(new Event("input", { bubbles: true }));
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row b-mb-3">
        <div className="col-12 b-pb-1 fw-bold">SEO</div>
      </div>
      <div className="b-row b-mt-3 b-mb-4">
        <div className="col-12">
          <div className="form-check b-form-check form-switch large">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="noindex"
              checked={cachedPageInfo.seo_no_index}
              onChange={(evt) => {
                setCachedPageInfo({
                  ...cachedPageInfo,
                  seo_no_index: evt.target.checked,
                });
              }}
            />
            <label className="form-check-label b-pt-1" htmlFor="noindex">
              Noindex, nofollow
            </label>
          </div>
        </div>
      </div>
      {!cachedPageInfo.seo_no_index && (
        <>
          <div className="b-row b-mb-4">
            <div className="col-12 b-mb-2 fs-small">Keyword</div>
            <div className="col-12">
              <input
                className="form-input builder-input"
                value={cachedPageInfo.seo_keyword}
                onChange={(evt) =>
                  setCachedPageInfo({
                    ...cachedPageInfo,
                    seo_keyword: evt.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="b-row b-mb-4">
            <div className="col-12 b-mb-2 fs-small">SEO title</div>
            <div className="col-12">
              <input
                className="form-input builder-input"
                value={cachedPageInfo.seo_title}
                onChange={(evt) =>
                  setCachedPageInfo({
                    ...cachedPageInfo,
                    seo_title: evt.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="b-row b-mb-4">
            <div className="col-12 b-mb-2 fs-small">SEO URL displayed</div>
            <div className="col-12">
              <input
                className="form-input builder-input"
                value={website!.name + "/" + page.url}
                readOnly
              />
            </div>
          </div>
          <div className="b-row b-mb-4">
            <div className="col-12 b-mb-2 fs-small">SEO description</div>
            <div className="col-12">
              <textarea
                id="seoDescription"
                className="form-input builder-input"
                style={{ overflow: "hidden", height: "0px" }}
                value={cachedPageInfo.seo_description}
                onInput={changeHeight}
                maxLength={155}
                b-rows={1}
                onChange={(evt) =>
                  setCachedPageInfo({
                    ...cachedPageInfo,
                    seo_description: evt.target.value,
                  })
                }
              ></textarea>
            </div>
          </div>
        </>
      )}
      <div className="b-my-3">
        <button className="sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => handleEdit({ seo: false })}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
