import React, { FocusEvent, KeyboardEvent, MouseEvent, useEffect } from "react";
import { useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { NavItemComponent as NavItemComponent_ } from "./interfaces/base";
import { Link } from "react-router-dom";
import RenderChildren from "./RenderChildren";
import styled from "@emotion/styled";
const Li = styled("li")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function NavItemComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: NavItemComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  // const [mouseUp, setMouseUp] = useState<MouseEvent<HTMLAnchorElement, any> | null>(null)
  // const [keyDown, setKeyDown] = useState<KeyboardEvent | null>(null)
  // const [onBlur, setOnBlur] = useState<FocusEvent | null>(null)
  const [styles, setStyle] = useState(attributes ? attributes.styles : {});
  const typographies = useAppSelector((state) => state.builder.typographies);
  const classes = attributes ? attributes.classes : {};
  const colors = useAppSelector((state) => state.builder.colors);

  useEffect(() => {
    const compTypography = typographies?.find(
      (x: any) => x.id === attributes.typography_id
    );

    if (compTypography) {
      setStyle({
        ...styles,
        ...{
          fontSize: compTypography.font_size + compTypography.font_size_unit,
          fontFamily: compTypography.font_family,
          fontStyle: compTypography.font_style,
          fontWeight: compTypography.font_weight,
          letterSpacing: compTypography.letter_spacing,
          lineHeight: compTypography.line_height,
          color: colors?.find((x: any) => x.id === compTypography.color_id)
            ?.color,
        },
      });
    }
  }, [typographies, attributes]);

  function generateLink(link: any) {
    return link.type === "Page" ? (
      <Link
        className="nav-link"
        key={link.text + link.type + link.url}
        to={editorId == id ? "#" : ("/" + link.url).replaceAll("//", "/")} // Avoid redirecting when editing
        target={link.type !== "None" ? link.target : ""}
        contentEditable={editorId == id}
        dangerouslySetInnerHTML={{ __html: link?.text }}
      ></Link>
    ) : (
      <a
        className="nav-link"
        key={link.text + link.type + link.url}
        href={
          link.type !== "None"
            ? (link.type === "Email"
                ? "mailto:"
                : link.type === "Phone"
                ? "tel:"
                : "") + link.url
            : "#"
        }
        target={link.type !== "None" ? link.target : ""}
        contentEditable={editorId == id}
        dangerouslySetInnerHTML={{ __html: link?.text }}
      ></a>
    );
  }

  console.log("attributes", attributes);

  if (!attributes) {
    return null;
  }

  // If dropdown use div instead of a
  const tag = React.createElement(
    `${attributes.link.type == "Dropdown" ? "div" : "a"}`,
    {
      className: "nav-link",
      dangerouslySetInnerHTML: { __html: attributes.text },
      contentEditable: editorId == id,
    }
  );

  return (
    <Li
      id={id.toString()}
      data-component-type="nav-item"
      className={
        "nav-item nav-link " +
        (classes ?? "") +
        (attributes && attributes.link && attributes.link.type === "Dropdown"
          ? " position-relative"
          : "") +
        " builder-component builder-component__nav-item" +
        (editorId === id ? " builder-component--active" : "")
      }
      data-style={{ ...styles, zIndex: editorId === id ? 9 : "auto" }}
    >
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />
      {attributes &&
        attributes.link &&
        attributes.link.type != "Dropdown" &&
        generateLink({
          type: attributes.link.type,
          url: attributes.link.url,
          target: attributes.link.target,
          text: attributes.text,
        })}
      {attributes && attributes.link && attributes.link.type === "Dropdown" && (
        <>
          {tag}
          <div className="nav-subnav">
            {attributes.links &&
              attributes.links!.map((item) => generateLink(item))}
          </div>
        </>
      )}
    </Li>
  );
}
