import React, { useState } from "react";
import WebsiteGeneralFormView from "./WebsiteGeneralFormView";
import WebsiteGeneralFormEdit from "./WebsiteGeneralFormEdit";
import WebsiteTrackingFormEdit from "./WebsiteTrackingFormEdit";
import WebsiteTrackingFormView from "./WebsiteTrackingFormView";
import WebsiteColorFormEdit from "./WebsiteColorFormEdit";

import WebsiteColorFormView from "./WebsiteColorFormView";
import WebsiteAPI from "../../../../../store/reducers/builderReducers/website/websiteApi";
import ApiClientsAPI from "../../../../../store/reducers/builderReducers/api_clients/api_clientsApi";
import { dateDiffInDays } from "../../../../../utils/Helpers";
import { useAppSelector } from "../../../../../store/hooks";
import WebsiteParametersFormView from "./WebsiteParametersFormView";
import WebsiteParametersFormEdit from "./WebsiteParametersFormEdit";
import WebsiteFaviconForm from "./WebsiteFaviconForm";

interface WebsiteFormModes {
  general: boolean;
  tracking: boolean;
  color: boolean;
  parameters: boolean;
}

export default function Website() {
  React.useEffect(() => {
    ApiClientsAPI.loadAPIClients();
  }, []);
  const website = useAppSelector((state) => state.builder.website);
  const api_clients = useAppSelector((state) => state.builder.api_clients);

  const [formEditModes, setFormEditModes] = useState<WebsiteFormModes>({
    general: false,
    tracking: false,
    color: false,
    parameters: false,
  });

  function setFormModes(newMode: {}) {
    setFormEditModes((modes) => ({
      ...modes,
      ...newMode,
    }));
  }

  return (
    <div className="b-container">
      {formEditModes.general ? (
        <WebsiteGeneralFormEdit
          handleEdit={(b: boolean) => setFormModes({ general: b })}
          website={website}
        />
      ) : (
        <WebsiteGeneralFormView
          handleEdit={(b: boolean) => setFormModes({ general: b })}
          website={website}
        />
      )}
      {formEditModes.tracking ? (
        <WebsiteTrackingFormEdit
          handleEdit={(b: boolean) => setFormModes({ tracking: b })}
          website={website}
        />
      ) : (
        <WebsiteTrackingFormView
          handleEdit={(b: boolean) => setFormModes({ tracking: b })}
          website={website}
        />
      )}
      {api_clients.size > 0 && (
        <div className="b-row b-mt-4">
          <div className="col-12">
            <div className="col-12 fw-bold">API</div>
          </div>
          {api_clients.map((x: any) => {
            let dayDiff = dateDiffInDays(
              new Date(x.updated).getTime(),
              new Date().getTime()
            );
            return (
              <React.Fragment key={x.id}>
                <div className="col-12 b-mt-2">
                  <div
                    title={x.name}
                    className="inline-block text-truncate"
                    style={{ maxWidth: "calc(100% - 140px)" }}
                  >
                    {x.name}
                  </div>
                  <div
                    className={
                      "fs-small float-end api-status" +
                      (dayDiff < 2
                        ? " success"
                        : dayDiff < 5
                        ? " failed"
                        : " disabled")
                    }
                  >
                    <span>{x.updated}</span>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      )}
      {formEditModes.color ? (
        <WebsiteColorFormEdit
          handleEdit={(b: boolean) => setFormModes({ color: b })}
          website={website}
        />
      ) : (
        <WebsiteColorFormView
          handleEdit={(b: boolean) => setFormModes({ color: b })}
          website={website}
        />
      )}
      {formEditModes.parameters ? (
        <WebsiteParametersFormEdit
          handleEdit={setFormModes}
          website={website}
        />
      ) : (
        <WebsiteParametersFormView
          handleEdit={setFormModes}
          website={website}
        />
      )}

      <WebsiteFaviconForm />
    </div>
  );
}
