import React, { useState } from "react"
import MonitorIcon from "@mui/icons-material/Monitor"
import CloseIcon from "@mui/icons-material/Close"
import HomeIcon from "@mui/icons-material/Home"
import store from '../../../store/store'
import StructureAPI from '../../../store/reducers/builderReducers/structure/structureApi'
import WebsiteApi from '../../../store/reducers/builderReducers/website/websiteApi'
import { NotificationManager } from 'react-notifications'
import NotificationContainer from 'react-notifications/lib/NotificationContainer'
import { useAppSelector } from '../../../store/hooks'

interface Props {
  sidebarActive: boolean
  toggleSidebar: (val: boolean) => void
}

export default function Header({ sidebarActive, toggleSidebar }: Props) {
  // const [resolution, setResolution] = useState(7)
  // const map = [380, 720, 1080, 1400, 1600, 1920, 2048, 3840]
  const structure = useAppSelector((state) => state.builder.structure)
  const website = useAppSelector((state) => state.builder.website)
  const webpage_id = useAppSelector((state) => state.builder.pages.active)

  const userPerson = { 
    "firstname": "Kasutajanimi",
    "avatar": "https://d2.itprojektid.ee/assets/images/default_avatar.png" 
  };

  const handleSave = () => {
    StructureAPI.updateWebpageComponents(webpage_id, structure)
      .then(() => NotificationManager.success('Components saved!', null, 2000))
      .catch((er) => {
        NotificationManager.error('An error occurred!', er, 2000)
      })
  }

  const [publishing, setPublishing] = useState(0)
  const handlePublish = () => {
    setPublishing(1)
    WebsiteApi.publishWebsite()
      .then(() => NotificationManager.success('Website published!', null, 5000))
      .catch((er) => {
        console.log(er)
        NotificationManager.error('An error occurred!', null, 5000)
      })
      .finally(() => setPublishing(0))
  }

  return (
    <nav className='header navbar navbar-expand'>
      <NotificationContainer />
      <div className='navbar-header'></div>
      <div className='collapse navbar-collapse h-100' id='navbar'>
        <div className='col d-flex align-items-center'>
          <div className={'home-btn' + (sidebarActive ? '' : ' active')} onClick={() => toggleSidebar(!sidebarActive)}>
            <HomeIcon />
          </div>
          <label className='website-title b-ps-4 fw-bold'>{website?.name}</label>
        </div>
        {/* <div className='resolution-slider' style={{ width: '30%' }}>
          <div className='col d-flex align-items-center me-2'>
            <MonitorIcon style={{ fontSize: '18px' }} className='me-2' />
            <input
              className='form-range'
              type='range'
              value={resolution}
              onChange={(evt) => setResolution(parseInt(evt.target.value))}
              min={0}
              max={7}
              step='1'
            />
            <label className='ps-3 fs-small'>{map[resolution]}px</label>
          </div>
        </div> */}
        <div className='col h-100 d-flex align-items-center justify-content-end'>
          <button className='b-btn btn-primary h-100 rounded-0 fw-bold' style={{ width: '100px' }} onClick={handleSave}>
            Save
          </button>
          <button
            className='b-btn h-100 rounded-0 fw-bold publish-btn'
            style={{ width: '100px', color: '#fff' }}
            onClick={handlePublish}
            disabled={publishing === 1}>
            Publish
            {publishing === 1 && <div className='loader loader-circle' style={{ verticalAlign: 'text-top', fontSize: 'inherit' }}></div>}
          </button>
          <div className='b-ms-4 b-me-5 d-flex align-items-center user-container'>
            <div className='position-relative avatar'>
              {/*@ts-ignore*/}
              <img src={userPerson.avatar} className='b-me-4' style={{ height: '30px', width: '30px', borderRadius: '14px' }}></img>
              <i className='avatar-online'></i>
            </div>
            {/*@ts-ignore*/}
            <label>{userPerson.firstname}</label>
          </div>
          <a className='b-btn btn-dark h-100 rounded-0' style={{ color: '#fff' }} href={"/websites/" + website.id}>
            <CloseIcon />
          </a>
        </div>
      </div>
    </nav>
  )
}
