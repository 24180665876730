import { useEffect, useState } from 'react'
import { useLayoutEffect } from 'react'
import { DefaultReactSelectStyle, TextEditorSelectStyle } from '../../../../ReactSelectStyle/ReactSelectStyle'
import Select from 'react-select'
import { useAppSelector } from '../../../../../../store/hooks'
import { Pages } from '../../../../../../store/reducers/builderReducers/pages/pagesApi'
interface Props {
  id: number
  highlightedText: string
  setLinkModalActive: (v: boolean) => void
  setRange: () => void
}

type LinkType = 'page' | 'web' | 'phone' | 'email' | 'anchor'

export default function LinkForm({ highlightedText, setLinkModalActive, setRange, id }: Props) {
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const [selectedType, setSelectedType] = useState<LinkType>('web')
  const webpages = useAppSelector((state) => state.builder.pages.list as Pages['list'])
  const [prefix, setPrefix] = useState('')
  const [hyperlink, setHyperlink] = useState('')
  const linkOpenTypes = [
    { label: 'New browser tab', value: '_blank' },
    { label: 'Current tab', value: '_self' },
  ]
  const [webpagesList, setWebpagesList] = useState(
    webpages.map((x) => {
      return { label: x.name, value: x.url }
    })
  )
  const [linkOpenType, setLinkOpenType] = useState<any>(linkOpenTypes[0])

  useEffect(() => {
    setHyperlink(prefix)
  }, [prefix])
  
  useEffect(() => {
    setWebpagesList(
      webpages.map((x) => {
        return { label: x.name, value: x.url }
      })
    )
  }, [webpages])

  useLayoutEffect(() => {
    setWrapperHeight(document.getElementById(id.toString())!.getBoundingClientRect().height)
    const resizeListener = () => {
      setWrapperHeight(document.getElementById(id.toString())!.getBoundingClientRect().height)
    }
    window.addEventListener('resize', resizeListener)
    return () => window.removeEventListener('resize', resizeListener)
  })

  function createHyperlink() {
    setRange()
    if (['web', 'page'].includes(selectedType)) {
      document.execCommand('insertHTML', false, "<a target='" + linkOpenType.value + "' href='" + hyperlink + "'>" + highlightedText + '</a>')
    } else {
      document.execCommand('insertHTML', false, "<a href='" + hyperlink + "'>" + highlightedText + '</a>')
    }
    setLinkModalActive(false)
  }

  function updateSelectedType(type: LinkType) {
    if (type === selectedType) return
    setSelectedType(type)
    if (type === 'web') setPrefix('')
    else if (type === 'phone') setPrefix('tel:')
    else if (type === 'email') setPrefix('mailto:')
    else if (type === 'anchor') setPrefix('#')
    else if (type === 'page') {
      setPrefix('')
      if (!webpagesList.find((x) => x.value === hyperlink)) {
        setHyperlink(webpagesList[0].value)
      }
    }
  }

  function getHyperlinkTitle() {
    switch (selectedType) {
      case 'web':
        return 'Web URL'
      case 'page':
        return 'Page'
      case 'phone':
        return 'Phone nr'
      case 'email':
        return 'Email'
      case 'anchor':
        return 'Element ID'
    }
  }

  return (
    <div
      className='link-form link-form__container'
      style={{
        height: 'calc(100% + ' + wrapperHeight + 'px)',
        minHeight: ['web', 'page'].includes(selectedType) ? '305px' : '244px',
      }}>
      <div className='link-form link-form__overlay'></div>
      <div className='link-form link-form__form'>
        <div className='row'>
          <div className='col-12 fw-bold' style={{ fontSize: '14px' }}>
            Save link
          </div>
          <div className='col-12 link-form link-form__link-options mt-2'>
            <div
              className={'link-form link-form__link-options-item' + (selectedType == 'page' ? ' link-form__link-options-item--active' : '')}
              onClick={() => updateSelectedType('page')}>
              Page
            </div>
            <div
              className={'link-form link-form__link-options-item' + (selectedType == 'web' ? ' link-form__link-options-item--active' : '')}
              onClick={() => updateSelectedType('web')}>
              Web
            </div>
            <div
              className={'link-form link-form__link-options-item' + (selectedType == 'phone' ? ' link-form__link-options-item--active' : '')}
              onClick={() => updateSelectedType('phone')}>
              Phone
            </div>
            <div
              className={'link-form link-form__link-options-item' + (selectedType == 'email' ? ' link-form__link-options-item--active' : '')}
              onClick={() => updateSelectedType('email')}>
              Email
            </div>
            <div
              className={'link-form link-form__link-options-item' + (selectedType == 'anchor' ? ' link-form__link-options-item--active' : '')}
              onClick={() => updateSelectedType('anchor')}>
              Anchor
            </div>
          </div>
        </div>
        <div className='row text-left'>
          <div className='col-12 mt-2'>
            <label className='fs-small'>Text</label>
          </div>
          <div className='col-12 mt-2'>
            <input className='form-input builder-input' value={highlightedText} readOnly />
          </div>
          <div className='col-12 mt-2'>
            <label className='fs-small'>{getHyperlinkTitle()}</label>
          </div>
          {selectedType !== 'page' && (
            <div className='col-12 mt-2'>
              <input
                className='form-input builder-input'
                value={hyperlink}
                onChange={(evt) => {
                  setHyperlink(prefix + evt.target.value.substring(prefix.length))
                }}
              />
            </div>
          )}
          {selectedType === 'page' && (
            <div className='col-12 b-mt-2 b-mb-1'>
              <Select
                onChange={(e) => {
                  setHyperlink(e.value)
                }}
                isSearchable={false}
                className='m-0'
                styles={TextEditorSelectStyle}
                noOptionsMessage={({}) => ''}
                value={webpagesList.find((x) => x.value === hyperlink)}
                options={webpagesList}
                placeholder=''></Select>
            </div>
          )}
          {['web', 'page'].includes(selectedType) && (
            <div className='col-12 mt-2'>
              <div className='fs-small'>Open</div>
              <div>
                <Select
                  isSearchable={false}
                  options={linkOpenTypes}
                  value={linkOpenType}
                  styles={DefaultReactSelectStyle}
                  onChange={(e) => setLinkOpenType(e)}></Select>
              </div>
            </div>
          )}
        </div>
        <div className='my-3 text-left'>
          <button className='btn btn-primary' type='submit' onClick={createHyperlink}>
            Save
          </button>
          <a className='b-px-3 hover-underline' style={{ color: '#fff' }} onClick={() => setLinkModalActive(false)}>
            Cancel
          </a>
        </div>
      </div>
    </div>
  )
}
