import NavItemComponent from "./NavItemComponent";
import ErrorFallbackComponent from "./ErrorFallbackComponent";
import {
  NavComponent as NavComponent_,
  NavItemComponent as NavItemComponent2,
} from "./interfaces/base";
import { useAppSelector } from "../../store/hooks";
import RenderChildren from "./RenderChildren";
import "./Nav.css";
import styled from "@emotion/styled";
import { useEffect } from "react";
const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);
export default function NavComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: NavComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const structure = useAppSelector((state) => state.builder.structure);
  console.log("NavComponent");

  console.log(structure);

  return (
    <Div
      id={id.toString()}
      data-component-type="nav"
      className={
        (attributes.classes ?? "") +
        " builder-component builder-component__nav" +
        (editorId === id
          ? " builder-component--active"
          : " collapse navbar-collapse")
      }
      data-style={{ flexGrow: 0, ...attributes.styles }}
    >
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations.filter(
          (componentRelation) => {
            const childComponent =
              structure.components[componentRelation.child_id];
            if (childComponent && childComponent.type == "editor") {
              return true;
            }
            return false;
          }
        )}
      />
      <ul className="navbar-nav mb">
        {childWebpageComponentRelations.map((componentRelation) => {
          const childComponent =
            structure.components[componentRelation.child_id];
          if (childComponent && childComponent.type !== "editor") {
            return (
              <ErrorFallbackComponent
                key={childComponent.id + "_errorBoundary_" + Math.random()}
              >
                <NavItemComponent
                  {...(childComponent as any)}
                  key={childComponent.id}
                />
              </ErrorFallbackComponent>
            );
          }
        })}
      </ul>
    </Div>
  );
}
