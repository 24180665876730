import { useShoppingCart } from '../context/ShoppingCartContext'
import { TextField } from '@mui/material'

interface Props {
  setShowBuyerModal: (val: boolean) => void
}

export default function CheckoutBuyerPanel({ setShowBuyerModal }: Props) {
  const { buyer } = useShoppingCart()

  return (
    <div className='panel' id='checkoutBuyerPanel'>
      <div className='panel-heading'>
        <div className='panel-title'>
          <span className='badge badge-square badge-primary gifticum-panel-title-number'>2</span>
          <span className='gifticum-panel-title-text'>OSTJA</span>
          {Object.keys(buyer).length != 0 && (
            <div className='pull-right color-brown clickable' style={{fontSize: '14px'}} onClick={() => setShowBuyerModal(true)}>
              Muuda
            </div>
          )}
        </div>
      </div>
      <div className='panel-body'>
        {Object.keys(buyer).length != 0 && (
          <div className='row'>
            <div className='col-xs-12'>
              <div className='personName'>
                {buyer.firstname} {buyer.surname}
              </div>
              <div className='personPhone'>{buyer.phone}</div>
              <div className='personEmail'>{buyer.email}</div>
            </div>
          </div>
        )}
        {Object.keys(buyer).length == 0 && (
          <div>
            <TextField InputLabelProps={{ style: { color: '#333', fontSize: '14px'}}} fullWidth label='E-post' variant='standard' value='' onClick={() => setShowBuyerModal(true)} />
          </div>
        )}
      </div>
    </div>
  )
}
