import { useState } from 'react'
import ProductsSortDropdownMenu from './ProductsSortDropdownMenu';
import FilterListIcon from '@mui/icons-material/FilterList';
import './ProductsSortComponent.css'


export default function ProductsSortComponent({sortFilter, setSortFilter, perPage, setPerPage}: any) {
    const [open, setOpen] = useState<boolean>(false)
    const toggleOpen = () => {
        setOpen(!open);
    };
    
    var title = 'Sorteeri';
    var titleArray: any = [];
    if(sortFilter) {
        switch (sortFilter) {
            case "price_asc":
                titleArray.push('Odavamad enne');
                break;
            case "price_desc":
                titleArray.push('Kallimad enne');
                break;
            case "name_desc":
                titleArray.push('Nimetuse järgi: Z->A');
                break;
            case "name_asc":
                titleArray.push('Nimetuse järgi: A->Z');
                
                break;
        
            default:
                break;
        }
    }
    if(perPage != 30) {
        titleArray.push(perPage + ' toodet lehel');
    }
    
    if(titleArray.length){
        title = titleArray.join(', ')
    }
      
    return (
        <div className={"ProductsSortComponent dropdown filterDropdown margin-top-15 " + (open ? 'open' : '')}>
            <button
            onClick={toggleOpen}
            className="btn btn-primary-outline filterDropdownButton"
            >
                <span id="filterTitle">{title}</span> <FilterListIcon style={{
                    verticalAlign: "initial",
                    fontSize: "14px",
                    top: '3px',
                    position: 'relative',
                }} />
            </button>
            {open && <ProductsSortDropdownMenu setOpen={setOpen} sortFilter={sortFilter} setSortFilter={setSortFilter} perPage={perPage} setPerPage={setPerPage}/> }
        </div>
    )
}
