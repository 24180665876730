import { FormEvent, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import useClickOutside from "../Website/useClickOutside";
import DeleteIcon from "@mui/icons-material/Delete";
import ColorAPI from "../../../../../store/reducers/builderReducers/color/colorApi";
import { Color } from "../../../../../store/reducers/builderReducers/color/colorApi";
import DeleteConfirmModal from "../../../Modal/DeleteConfirmModal";
import TypographiesAPI from "../../../../../store/reducers/builderReducers/typographies/typographiesApi";
import { useAppSelector } from "../../../../../store/hooks";
import { Typography as TypographyInterface } from "../../../../../store/reducers/builderReducers/typographies/typographiesApi";
import { updateComponentText } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import ts from "typescript";

interface Props {
  colors: Color[];
  handleEdit: (obj: {}) => void;
}

export default function ColorsFormEdit({ colors, handleEdit }: Props) {
  const builderElements = useAppSelector((state) => state.builder);

  const typographies = useAppSelector(
    (state) =>
      JSON.parse(JSON.stringify(state.builder.typographies))?.sort(
        (a: TypographyInterface, b: TypographyInterface) =>
          a.name.localeCompare(b.name)
      ) as TypographyInterface[]
  );

  const [cachedColors, setCachedColors] = useState(colors);
  const [popoverOpen, setPopoverOpen] = useState<{
    id: number | null;
    ref: any;
  }>({
    id: null,
    ref: useRef(null),
  });
  useClickOutside(popoverOpen.ref, () => setPopoverOpenId(null));

  function setPopoverOpenId(id: number | null) {
    setPopoverOpen((x) => {
      return { ...x, id: id };
    });
  }

  function setCachedColorsColorByIndex(value: string, index: number) {
    setCachedColors((x) =>
      x.map((val, i) => (i !== index ? val : { ...val, color: value }))
    );
  }

  function setCachedColorsNameByIndex(value: string, index: number) {
    setCachedColors((x) =>
      x.map((val, i) => (i !== index ? val : { ...val, name: value }))
    );
  }

  function deleteColorById(id: number) {
    setCachedColors((colors) => colors.filter((x) => x.id != id));
  }

  function handleDeleteColor(color) {
    // Color

    console.log(color);

    const typographys = builderElements.typographies.filter(
      (typo) => typo.color_id === color.id
    );

    console.log(
      typographys,
      "ALL THE TYPOGRAHIES THAT HAVE THAT COLOR WE WANT TO DELETE"
    );

    // Find all the components, that have this typography id

    const elements = builderElements.structure.components;

    Object.keys(elements).forEach((key) => {
      if (elements[key].attributes.typography_id === typographys[0].id) {
        //@ts-ignore
        if (elements[key].attributes.text.startsWith("<font")) {
          //@ts-ignore
          console.log(elements[key].attributes.text);

          return;
        } else {
          //@ts-ignore
          console.log(elements[key].attributes.text);

          updateComponentText(
            elements[key].id,
            // @ts-ignore
            `<font color="${color.color}">${elements[key].attributes.text}</font>`
          );
        }
      }

      // console.log(
      //   elements[key].attributes.typography_id === typographies[0].id
      // );
    });

    // console.log(elements);

    deleteColorById(color.id);
  }

  function addNewColor() {
    setCachedColors([
      ...cachedColors,
      {
        id: "new_" + cachedColors[cachedColors.length - 1]?.id + 1,
        color: "#fff",
        name: "Untitled",
        sort_order: 1,
      } as any,
    ]);
  }

  let colorElements: JSX.Element[] = [];
  cachedColors?.forEach((color, index) => {
    colorElements.push(
      <div className="b-row b-py-1" key={color.id}>
        <div className="col-12 d-flex align-items-center">
          <div className="picker position-relative">
            <div
              className="color-box"
              style={{ backgroundColor: color.color }}
              onClick={() => setPopoverOpenId(color.id)}
            />
            {color.id === popoverOpen.id && (
              <div className="popover" ref={popoverOpen.ref}>
                <HexColorPicker
                  color={color.color}
                  onChange={(newColor) =>
                    setCachedColorsColorByIndex(newColor, index)
                  }
                />
              </div>
            )}
          </div>
          <input
            className="b-px-4 d-flex align-items-center color-picker-value text-center"
            value={color.color}
            onChange={(evt) =>
              setCachedColorsColorByIndex(evt.target.value, index)
            }
          />
          <input
            className="builder-input b-mx-3 align-self-center"
            value={color.name}
            onChange={(evt) =>
              setCachedColorsNameByIndex(evt.target.value, index)
            }
          />

          <DeleteConfirmModal
            onConfirm={() => {
              handleDeleteColor(color);
            }}
          />
          {/* <DeleteIcon
            className="delete-icon"
            onClick={() => deleteColorById(color.id)}
          /> */}
        </div>
      </div>
    );
  });

  async function handleSubmit(evt: FormEvent) {
    evt.preventDefault();
    var colors = cachedColors;
    // Delete id so backend generates correctly
    colors.forEach((el: any) => {
      if (el.id.toString().includes("new_")) {
        delete el.id;
      }
    });

    ColorAPI.saveColors(colors).then(() => {
      handleEdit({ colors: false });
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row">
        <div className="col-9 b-mb-3 b-pb-1 fw-bold">Website: Colors</div>
        <div className="col-3 b-pt-1 text-end">
          <a className="hover-underline colors-button" onClick={addNewColor}>
            + Add
          </a>
        </div>
      </div>
      {colorElements}
      <div className="b-mt-3">
        <button className=" sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => handleEdit({ colors: false })}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
