import { useState } from "react"
import { PaymentMethod, useShoppingCart } from '../context/ShoppingCartContext'
import CheckoutMaksekeskusModal from './CheckoutMaksekeskusModal'

interface Props {
  paymentMethod: PaymentMethod
  setShowParentModal?: (val: boolean) => void
}

export default function CheckoutPaymentMethodButton({ paymentMethod, setShowParentModal = () => {} }: Props) {
  const [showModal, setShowModal] = useState(false)

  const { setOrderPaymentMethod } = useShoppingCart()

  var maksekeskusPaymentMethod: any = null
  if ((paymentMethod as any).maksekeskusPaymentMethod) {
    maksekeskusPaymentMethod = (paymentMethod as any).api_client.maksekeskus_payment_methods.find(
      (i: any) => i.id == (paymentMethod as any).maksekeskusPaymentMethod
    )
  }

  if (paymentMethod.getMaksekeskusApiActive) {
    return (
      <>
        <button
          className='btn btn-round btn-primary-outline paymentMethodMaksekeskus'
          onClick={() => {
            setShowParentModal(false)
            setShowModal(true)
          }}>
          {paymentMethod.name}
          {maksekeskusPaymentMethod ? <img src={maksekeskusPaymentMethod.logo_url} style={{ float: 'right', maxWidth: '65px', marginBottom: '-5px' }} /> : ''}
        </button>
        {showModal && <CheckoutMaksekeskusModal paymentMethod={paymentMethod} show={showModal} setShow={setShowModal} />}
      </>
    )
  }
  return (
    <button
      className='btn btn-round btn-primary-outline paymentMethodButton'
      onClick={() => {
        setShowParentModal(false)
        setOrderPaymentMethod(paymentMethod)
      }}>
      {paymentMethod.image ? <img src={paymentMethod.imageUrl} height='25px' /> : <span>{paymentMethod.name}</span>}
    </button>
  )
}
