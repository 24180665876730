import StructureAPI from "../../../../../store/reducers/builderReducers/structure/structureApi";
import { useAppSelector } from "../../../../../store/hooks";
import { AllComponents } from "../../../../_default/interfaces/base";
import { useEffect, useState } from "react";
import {
  addNewComponent,
  deleteComponentById,
} from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { editorId } from "../../../../../pages/Builder";
import { setEditableComponentId } from "../../../../../store/reducers/textEditor/textEditorActions";
import { MoreHorizOutlined } from "@mui/icons-material";
interface Props {
  component: AllComponents;
}

export default function SelectElementType({ component }: Props) {
  const typographies = useAppSelector((state) => state.builder.typographies);
  const website = useAppSelector((state) => state.builder.website);
  const [style, setStyle] = useState<React.CSSProperties>();
  const [expanded, setExpanded] = useState(false);

  const elementTypes = [
    { label: "Text", value: "text" },
    { label: "Products", value: "ProductsComponent" },
    { label: "Product", value: "ProductDetailComponent" },
    { label: "Checkout", value: "Checkout" },
    { label: "Button", value: "Button" },
    { label: "ContactForm", value: "form" },
    { label: "Section", value: "section" },
    // 'Link',
    // 'NavItemComponent',
  ].sort((a, b) => a.label.localeCompare(b.label));

  const quickTypes = elementTypes.filter((x) =>
    ["text", "Button"].includes(x.value)
  );

  const observer = new ResizeObserver((entries) => {
    setStyle({ maxWidth: `${entries[0].target.clientWidth}px` });
  });
  useEffect(() => {
    observer.observe(document.getElementById(component.id.toString())!);
  }, []);

  function onTypeClick(type: string) {
    let textAttributes: any[] = [];
    if (type == "section") {
      StructureAPI.addComponent(component.webpage_id, {
        type: type,
        sort_order: component.sort_order + 1,
        attributes: [
          { name: "classes", value: "row", type: "attributes" },
          { name: "paddingTop", value: "60px", type: "styles" },
          { name: "paddingLeft", value: "60px", type: "styles" },
          { name: "paddingBottom", value: "60px", type: "styles" },
          { name: "paddingRight", value: "60px", type: "styles" },
          {
            name: "content_width_unit",
            value: website?.content_width_unit,
            type: "attributes",
          },
          {
            name: "content_width_size",
            value: website?.content_width_size,
            type: "attributes",
          },
        ],
        parent_id: component.id,
      }).then((x: any) => {
        StructureAPI.addComponent(component.webpage_id, {
          type: "section",
          sort_order: 1,
          attributes: [
            { name: "classes", value: "col", type: "attributes" },
            { name: "paddingTop", value: "60px", type: "styles" },
            { name: "paddingLeft", value: "60px", type: "styles" },
            { name: "paddingBottom", value: "60px", type: "styles" },
            { name: "paddingRight", value: "60px", type: "styles" },
          ],
          parent_id: x.payload[Object.keys(x.payload)[0]].id,
        });
      });
      return;
    }

    if (type == "NavItemComponent") {
      type = "nav-item";
      // {
      //   type: 'nav-item',
      //   sort_order: 1,
      //   attributes: [

      //   ],
      // },
      textAttributes = [
        {
          type: "attributes",
          name: "text",
          value: "NavItemComponent",
        },
        {
          type: "attributes",
          name: "link",
          value: { url: "/", type: "Web", target: "" },
        },
      ];
    } else if (type == "form") {
      // At the moment only 1 form
      StructureAPI.addComponent(component.webpage_id, {
        type: "form",
        sort_order: component.sort_order + 1,
        attributes: [
          {
            type: "styles",
            name: "marginTop",
            value: "10px",
          },
          {
            type: "attributes",
            name: "action",
            value: "/webstoreApi/submitContactForm",
          },
        ],
        parent_id: component.id,
        childElements: [
          {
            type: "text",
            sort_order: 1,
            attributes: [
              {
                type: "attributes",
                name: "text",
                value:
                  '<span style="font-weight: normal;"><font style="font-size: 17px;">Nimi</font></span>',
              },
            ],
          },
          {
            type: "input",
            sort_order: 2,
            attributes: [
              { type: "attributes", name: "placeholder", value: "Teie nimi" },
              { type: "attributes", name: "name", value: "name" },
              { type: "attributes", name: "required", value: true },
            ],
          },
          {
            type: "text",
            sort_order: 3,
            attributes: [
              {
                type: "attributes",
                name: "text",
                value:
                  '<span style="font-weight: normal;"><font style="font-size: 17px;">Email address</font></span>',
              },
              { type: "styles", name: "marginTop", value: "15px" },
            ],
          },
          {
            type: "input",
            sort_order: 4,
            attributes: [
              {
                type: "attributes",
                name: "placeholder",
                value: "E-posti aadress",
              },
              { type: "attributes", name: "name", value: "email" },
              { type: "attributes", name: "required", value: true },
            ],
          },
          {
            type: "text",
            sort_order: 5,
            attributes: [
              {
                type: "attributes",
                name: "text",
                value:
                  '<span style="font-weight: normal;"><font style="font-size: 17px;">Sinu sõnum</font></span>',
              },
              { type: "styles", name: "marginTop", value: "15px" },
            ],
          },
          {
            type: "input",
            sort_order: 6,
            attributes: [
              { type: "attributes", name: "placeholder", value: "" },
              { type: "attributes", name: "text_area", value: true },
              { type: "attributes", name: "name", value: "content" },
              { type: "attributes", name: "required", value: true },
            ],
          },
          {
            type: "Button",
            sort_order: 7,
            attributes: [
              { type: "attributes", name: "text_area", value: true },
              { type: "attributes", name: "buttonType", value: "submit" },
              {
                type: "attributes",
                name: "text",
                value:
                  '<font style="font-size: 15px;" color="#ffffff">Saada</font>',
              },
              {
                type: "styles",
                name: "backgroundColor",
                value: "rgb(53, 158, 63)",
              },
              { type: "styles", name: "borderBottomWidth", value: "0px" },
              { type: "styles", name: "borderLeftWidth", value: "0px" },
              { type: "styles", name: "borderRightWidth", value: "0px" },
              { type: "styles", name: "borderTopWidth", value: "0px" },
              { type: "styles", name: "marginTop", value: "15px" },
              { type: "styles", name: "paddingTop", value: "10px" },
              { type: "styles", name: "paddingBottom", value: "10px" },
              { type: "styles", name: "paddingRight", value: "15px" },
              { type: "styles", name: "paddingLeft", value: "15px" },
              { type: "styles", name: "width", value: "100%" },

              { type: "styles", name: "display", value: "flex" },
              {
                type: "attributes",
                name: "typography_id",
                value: typographies.find((x: any) => x.tag == "p")?.id ?? null,
              },
            ],
          },
        ],
      });
      return;
    } else if (type === "button") {
      textAttributes = [
        {
          type: "attributes",
          name: "type",
          value: "button",
        },
        {
          type: "attributes",
          name: "link",
          value: { url: "", type: "Page", target: "_self" },
        },
        {
          type: "attributes",
          name: "text",
          value: '<font color="#fff">Click me</font>',
        },
        {
          type: "attributes",
          name: "typography_id",
          value: typographies.find((x: any) => x.tag == "p")?.id ?? null,
        },
        {
          type: "styles",
          name: "marginTop",
          value: "10px",
        },
        {
          type: "styles",
          name: "borderTopWidth",
          value: "1px",
        },
        {
          type: "styles",
          name: "borderBottomWidth",
          value: "1px",
        },
        {
          type: "styles",
          name: "borderLeftWidth",
          value: "1px",
        },
        {
          type: "styles",
          name: "borderRightWidth",
          value: "1px",
        },
        {
          type: "styles",
          name: "borderTopStyle",
          value: "solid",
        },
        {
          type: "styles",
          name: "borderBottomStyle",
          value: "solid",
        },
        {
          type: "styles",
          name: "borderLeftStyle",
          value: "solid",
        },
        {
          type: "styles",
          name: "borderRightStyle",
          value: "solid",
        },
        {
          type: "styles",
          name: "borderTopColor",
          value: "#616161",
        },
        {
          type: "styles",
          name: "borderBottomColor",
          value: "#616161",
        },
        {
          type: "styles",
          name: "borderLeftColor",
          value: "#616161",
        },
        {
          type: "styles",
          name: "borderRightColor",
          value: "#616161",
        },
        {
          type: "styles",
          name: "borderTopLeftRadius",
          value: "6px",
        },
        {
          type: "styles",
          name: "borderTopRightRadius",
          value: "6px",
        },
        {
          type: "styles",
          name: "borderBottomLeftRadius",
          value: "6px",
        },
        {
          type: "styles",
          name: "borderBottomRightRadius",
          value: "6px",
        },
        {
          type: "styles",
          name: "paddingTop",
          value: "4px",
        },
        {
          type: "styles",
          name: "paddingBottom",
          value: "4px",
        },
        {
          type: "styles",
          name: "paddingLeft",
          value: "16px",
        },
        {
          type: "styles",
          name: "display",
          value: "flex",
        },
        {
          type: "styles",
          name: "paddingRight",
          value: "16px",
        },
        {
          type: "styles",
          name: "backgroundColor",
          value: "#616161",
        },
      ];
    } else {
      textAttributes = [
        {
          type: "attributes",
          name: "text",
          value: "text",
        },
        {
          type: "attributes",
          name: "typography_id",
          value: typographies.find((x: any) => x.tag == "p")?.id ?? null,
        },
        {
          type: "styles",
          name: "marginTop",
          value: "10px",
        },
      ];
    }

    StructureAPI.addComponent(component.webpage_id, {
      type,
      sort_order: component.sort_order + 1,
      attributes: textAttributes,
      parent_id: component.id,
    }).then((x) => {
      deleteComponentById(editorId);
      addNewComponent({
        id: editorId,
        type: "editor",
        sort_order: 1,
        parent_id: x.payload[Object.keys(x.payload)[0]].id,
        childElements: [],
      } as any);
      setEditableComponentId(x.payload[Object.keys(x.payload)[0]].id);
      setTimeout(() => {
        let y = document
          .getElementById(x.payload[Object.keys(x.payload)[0]].id.toString())
          ?.querySelectorAll("[contenteditable]")[0] as HTMLElement;
        if (y) {
          let s = window.getSelection();
          let r = document.createRange();
          r.selectNodeContents(y);
          r.collapse(false);
          s?.removeAllRanges();
          s?.addRange(r);
        }
      }, 500);
    });
  }

  return (
    <ul className="m-0 p-0">
      <div className="select-element-type__container" style={style}>
        <div
          className={
            "select-element-type__list" + (expanded ? "" : " flex-nowrap")
          }
        >
          {(expanded ? elementTypes : quickTypes).map((x) => (
            <div
              key={x.value}
              className="select-element-type__list__item"
              onClick={() => onTypeClick(x.value)}
            >
              {x.label}
            </div>
          ))}
        </div>
        <MoreHorizOutlined
          style={{ fontSize: "22.5px", alignSelf: "center", marginLeft: "5px" }}
          onClick={() => setExpanded(!expanded)}
        />
      </div>
    </ul>
  );
}
