import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Pages } from "../../../../../store/reducers/builderReducers/pages/pagesApi";
import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";

interface Props {
  page: Pages["list"][0];
  handleEdit: (obj: {}) => void;
  website: Website;
}

export default function PageDetailsSeoViewForm({
  page,
  handleEdit,
  website,
}: Props) {
  return (
    <>
      <div className="b-row b-mb-3">
        <div className="col-10 fw-bold">SEO</div>
        <div className="col-2 b-pt-1 text-end">
          <a className="pages-button" onClick={() => handleEdit({ seo: true })}>
            Edit
          </a>
        </div>
      </div>
      <div className="b-row b-mb-4">
        <div className="col-12 d-flex align-items-center">
          {page.seo_no_index ? (
            <CheckIcon style={{ color: "#48e348" }} className="b-me-2" />
          ) : (
            <CloseIcon style={{ color: "rgb(255 40 40)" }} className="b-me-2" />
          )}
          <label>Noindex, nofollow</label>
        </div>
      </div>
      {!page.seo_no_index && (
        <>
          <div className="b-row b-mb-4">
            <div className="col-12 b-mb-2 fs-small">Keyword</div>
            <div className="col-12">{page.seo_keyword}</div>
          </div>
          <div className="b-row b-mb-4">
            <div className="col-12 b-mb-2 fs-small">Google search preview</div>
            <div className="col-12">
              <div className="col-12 google-preview">
                <div className="title">{page.seo_title}</div>
                <div className="url">
                  {website!.name.replaceAll("/", "") +
                    (page.url !== "" ? "/" : "")}
                  <label style={{ fontWeight: "bold" }}>{page.url}</label>
                </div>
                <div className="description">{page.seo_description}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
