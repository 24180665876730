export function pf(number: number) {
  var decimals = 2;
  var pow = Math.pow(10, decimals);
  return Number(Math.round(number * pow) / pow).toFixed(decimals);
}

type LogErrorProps = {
  name: string | any,
  message: string | any,
  stack: string | any,
  componentStack: string | any,
  href: string | any,
}
var logStack: any = [];
var logStackTimeout: any;
export function logErrorToBackend(data: LogErrorProps) {
  logStack.push(data);
  if(logStackTimeout){
    clearTimeout(logStackTimeout);
  }
  logStackTimeout = setTimeout(() => {
    logErrorStackToBackend();
  }, 3000);
}
function logErrorStackToBackend() {
  if(logStack.length){
    const data: any = {
      logStack: logStack,
    }
    logStack = [];
    // @ts-ignore
    window.axios.post('logError', data)
    .then((res: any) => {
  
    })
    .catch((error: any) => {
      console.log(error)
      // alert('Midagi läks valesti!')
    })
  }
}