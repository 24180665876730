import { fetchApiGet, fetchApiPost, fetchApiPut, fetchApiDelete } from '../../../../api/api'
import {
    updateTypographies,
    updateTypographyById,
    removeTypographyById,
    createTypography,
} from './typographiesActions'

export interface Typography {
  id: number
  name: string
  tag: string
  uuid: string
  website_id: number
  font_family: string
  font_size: string
  font_size_unit: string
  font_style: string
  font_weight: string
  letter_spacing: string
  line_height: string
  color_id: number
  link_color_id: number
  link_hover_color_id: number
  transition_duration: string
  transition_effect: string
}

const TypographiesAPI = {
    addTypography,
    loadTypographies,
    saveTypographyById,
    deleteTypographyById,
}
export default TypographiesAPI

function loadTypographies() {
  return fetchApiGet<Typography[]>(`/typographies`, {}).then((res) => updateTypographies(res))
}

function addTypography(data: Omit<Typography, 'id' | 'uuid'>) {
  return fetchApiPost<Typography>(`/typographies`, data).then((d) => {
    createTypography(d)
  })
}

function saveTypographyById(data: Typography) {
  return fetchApiPut(`/typographies/${data.id}`, data).then((d) => {
    updateTypographyById({ id: data.id, data: data })
  })
}

function deleteTypographyById(id: number) {
  return fetchApiDelete(`/typographies/${id}`, {}).then(() => {
    removeTypographyById(id)
  })
}