import store from "../../../store";
import { Pages, Page } from "./pagesApi";
import StructureAPI from "../structure/structureApi";
import {
  SET_WEBPAGE_ACTIVE,
  ADD_WEBPAGE,
  DELETE_WEBPAGE_BY_ID,
  UPDATE_WEBPAGE_BY_ID,
  UPDATE_WEBPAGES,
} from "./pagesReducer";

export function setActivePage(payload: Pages["active"]) {
  console.log(payload);

  if (payload) {
    StructureAPI.loadWebpageComponents(payload);
  }
  return store.dispatch(SET_WEBPAGE_ACTIVE(payload));
}

export function updateWebpages(payload: Pages["list"]) {
  return store.dispatch(UPDATE_WEBPAGES(payload));
}

export function updateWebpageById(payload: { id: number; data: Page }) {
  return store.dispatch(UPDATE_WEBPAGE_BY_ID(payload));
}

export function addNewWebpage(payload: Page) {
  return store.dispatch(ADD_WEBPAGE(payload));
}

export function deleteWebpageById(id: number) {
  return store.dispatch(
    DELETE_WEBPAGE_BY_ID({
      id,
    })
  );
}
