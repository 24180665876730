import API from "../api/api";
import StructureAPI, {
  loadWebpageComponents,
} from "../store/reducers/builderReducers/structure/structureApi";
import { useSelector } from "react-redux";
import { Typography } from "../store/reducers/builderReducers/typographies/typographiesApi";
import {
  FooterComponent,
  HeaderComponent,
} from "../components/_default/interfaces/base";
import { AllComponents } from "../components/_default/interfaces/base";
import store from "../store/store";

interface ReusableComponents {
  footers: FooterComponent[];
  headers: HeaderComponent[];
}

export function addHeader() {
  const state = store.getState();
  const typographies = state.builder.typographies;
  const reusableComponents = state.builder.reusableComponents;
  const pages = state.builder.pages;
  if (!pages.active) {
    return false;
  }
  if (reusableComponents.headers.length) {
    StructureAPI.addExistingComponent(pages.active, {
      id: reusableComponents.headers[0].id,
      parent_id: null,
      sort_order: -1,
    }).then(() => {
      loadWebpageComponents(pages.active!);
    });
    return true;
  }
  StructureAPI.addComponent(pages.active, {
    type: "header",
    sort_order: -1,
    attributes: [
      {
        type: "attributes",
        name: "components",
        value: {
          logo: { alignment: "left", type: "logo" },
          nav: { alignment: "center", display: "block" },
          cart: { alignment: "right", display: "block" },
        },
      },
    ],
    childElements: [
      {
        type: "logo",
        sort_order: 1,
        attributes: [
          {
            type: "attributes",
            name: "logo",
            value: {
              Desktop: {
                active: true,
                styles: {
                  backgroundImage: "url(/assets/images/prana-logo.svg)",
                  backgroundPosition: "center top",
                  backgroundSize: "50px 50px",
                  backgroundRepeat: "repeat",
                  backgroundOrigin: "padding-box",
                  backgroundAttachment: "scroll",
                },
              },
              Mobile: {
                active: true,
                styles: {
                  backgroundImage: "url(/assets/images/prana-logo.svg)",
                  backgroundPosition: "center top",
                  backgroundSize: "50px 50px",
                  backgroundRepeat: "repeat",
                  backgroundOrigin: "padding-box",
                  backgroundAttachment: "scroll",
                },
              },
            },
          },
        ],
      },
      {
        type: "nav",
        sort_order: 2,
        attributes: [
          { name: "paddingTop", value: "20px", type: "styles" },
          { name: "paddingLeft", value: "20px", type: "styles" },
          { name: "paddingBottom", value: "20px", type: "styles" },
          { name: "paddingRight", value: "20px", type: "styles" },
        ],
        childElements: [
          {
            type: "nav-item",
            sort_order: 1,
            attributes: [
              {
                type: "attributes",
                name: "text",
                value: "Avaleht",
              },
              {
                type: "attributes",
                name: "link",
                value: { url: "/", type: "Web", target: "" },
              },
            ],
          },
          {
            type: "nav-item",
            sort_order: 2,
            attributes: [
              {
                type: "attributes",
                name: "text",
                value: "Products",
              },
              {
                type: "attributes",
                name: "link",
                value: {
                  url: "https://google.ee",
                  type: "Web",
                  target: "_blank",
                },
              },
            ],
          },
        ],
      },
      {
        type: "cart",
        sort_order: 3,
        attributes: [
          { type: "styles", name: "display", value: "flex" },
          { type: "styles", name: "alignItems", value: "center" },
          { type: "styles", name: "borderLeftWidth", value: "1px" },
          { type: "styles", name: "borderRightWidth", value: "1px" },
          { type: "styles", name: "borderBottomWidth", value: "1px" },
          { type: "styles", name: "borderTopWidth", value: "1px" },
          { type: "styles", name: "borderLeftColor", value: "#048d04" },
          { type: "styles", name: "borderRightColor", value: "#048d04" },
          { type: "styles", name: "borderBottomColor", value: "#048d04" },
          { type: "styles", name: "borderTopColor", value: "#048d04" },
          { type: "styles", name: "borderTopLeftRadius", value: "20px" },
          { type: "styles", name: "borderTopRightRadius", value: "20px" },
          { type: "styles", name: "borderBottomLeftRadius", value: "20px" },
          { type: "styles", name: "borderBottomRightRadius", value: "20px" },
          { type: "styles", name: "borderLeftStyle", value: "solid" },
          { type: "styles", name: "borderRightStyle", value: "solid" },
          { type: "styles", name: "borderBottomStyle", value: "solid" },
          { type: "styles", name: "borderTopStyle", value: "solid" },
          { type: "styles", name: "paddingLeft", value: "13px" },
          { type: "styles", name: "paddingRight", value: "13px" },
          { type: "styles", name: "paddingBottom", value: "7px" },
          { type: "styles", name: "paddingTop", value: "7px" },
          { type: "styles", name: "color", value: "#048d04" },
          { type: "styles", name: "fontWeight", value: "bold" },
          {
            type: "attributes",
            name: "typography_id",
            value: typographies.find((x) => x.tag == "p")?.id ?? null,
          },
        ],
      },
    ],
  }).then(() => {
    loadWebpageComponents(pages.active!);
  });
  return true;
}

export function addFooter() {
  const state = store.getState();
  const typographies = state.builder.typographies;
  const reusableComponents = state.builder.reusableComponents;
  const pages = state.builder.pages;
  if (!pages.active) {
    return false;
  }
  if (reusableComponents.footers.length) {
    StructureAPI.addExistingComponent(pages.active, {
      id: reusableComponents.footers[0].id,
      parent_id: null,
      sort_order: 999,
    }).then(() => {
      loadWebpageComponents(pages.active!);
    });
    return true;
  }
  StructureAPI.addComponent(pages.active, {
    type: "footer",
    sort_order: 99999,
    attributes: [
      {
        type: "attributes",
        name: "classes",
        value: "builder-footer",
      },
    ],
    childElements: [
      {
        type: "section",
        sort_order: 1,
        attributes: [
          {
            type: "attributes",
            name: "classes",
            value: "container-fluid",
          },
          { name: "paddingTop", value: "10px", type: "styles" },
          { name: "paddingLeft", value: "10px", type: "styles" },
          { name: "paddingBottom", value: "10px", type: "styles" },
          { name: "paddingRight", value: "10px", type: "styles" },
        ],
        childElements: [
          {
            type: "section",
            sort_order: 1,
            attributes: [
              {
                type: "attributes",
                name: "classes",
                value: "row",
              },
              { name: "paddingTop", value: "60px", type: "styles" },
              { name: "paddingLeft", value: "60px", type: "styles" },
              { name: "paddingBottom", value: "60px", type: "styles" },
              { name: "paddingRight", value: "60px", type: "styles" },
            ],
            childElements: [
              {
                type: "section",
                sort_order: 1,
                attributes: [
                  {
                    type: "attributes",
                    name: "classes",
                    value: "col",
                  },
                  { name: "paddingTop", value: "60px", type: "styles" },
                  { name: "paddingLeft", value: "60px", type: "styles" },
                  { name: "paddingBottom", value: "60px", type: "styles" },
                  { name: "paddingRight", value: "60px", type: "styles" },
                ],
                childElements: [
                  {
                    type: "text",
                    sort_order: 1,
                    attributes: [
                      {
                        type: "attributes",
                        name: "text",
                        value: "text",
                      },
                      {
                        type: "attributes",
                        name: "typography_id",
                        value:
                          typographies.find((x) => x.tag == "p")?.id ?? null,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }).then(() => {
    loadWebpageComponents(pages.active!);
  });
  return true;
}

export function addCookies() {
  const state = store.getState();
  const typographies = state.builder.typographies;
  const reusableComponents = state.builder.reusableComponents;
  const pages = state.builder.pages;
  if (!pages.active) {
    return false;
  }
  // if (reusableComponents.footers.length) {
  //   StructureAPI.addExistingComponent(pages.active, {
  //     id: reusableComponents.footers[0].id,
  //     parent_id: null,
  //     sort_order: 999,
  //   }).then(() => {
  //     loadWebpageComponents(pages.active!);
  //   });
  //   return true;
  // }
  // StructureAPI.addComponent(pages.active, {
  //   type: "footer",
  //   sort_order: 999,
  //   attributes: [
  //     {
  //       type: "attributes",
  //       name: "classes",
  //       value: "builder-footer",
  //     },
  //   ],
  //   childElements: [
  //     {
  //       type: "section",
  //       sort_order: 1,
  //       attributes: [
  //         {
  //           type: "attributes",
  //           name: "classes",
  //           value: "container-fluid",
  //         },
  //         { name: "paddingTop", value: "10px", type: "styles" },
  //         { name: "paddingLeft", value: "10px", type: "styles" },
  //         { name: "paddingBottom", value: "10px", type: "styles" },
  //         { name: "paddingRight", value: "10px", type: "styles" },
  //       ],
  //       childElements: [
  //         {
  //           type: "section",
  //           sort_order: 1,
  //           attributes: [
  //             {
  //               type: "attributes",
  //               name: "classes",
  //               value: "row",
  //             },
  //             { name: "paddingTop", value: "60px", type: "styles" },
  //             { name: "paddingLeft", value: "60px", type: "styles" },
  //             { name: "paddingBottom", value: "60px", type: "styles" },
  //             { name: "paddingRight", value: "60px", type: "styles" },
  //           ],
  //           childElements: [
  //             {
  //               type: "section",
  //               sort_order: 1,
  //               attributes: [
  //                 {
  //                   type: "attributes",
  //                   name: "classes",
  //                   value: "col",
  //                 },
  //                 { name: "paddingTop", value: "60px", type: "styles" },
  //                 { name: "paddingLeft", value: "60px", type: "styles" },
  //                 { name: "paddingBottom", value: "60px", type: "styles" },
  //                 { name: "paddingRight", value: "60px", type: "styles" },
  //               ],
  //               childElements: [
  //                 {
  //                   type: "text",
  //                   sort_order: 1,
  //                   attributes: [
  //                     {
  //                       type: "attributes",
  //                       name: "text",
  //                       value: "text",
  //                     },
  //                     {
  //                       type: "attributes",
  //                       name: "typography_id",
  //                       value:
  //                         typographies.find((x) => x.tag == "p")?.id ?? null,
  //                     },
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // }).then(() => {
  //   loadWebpageComponents(pages.active!);
  // });
  return true;
}
