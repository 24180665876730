import { AllComponents } from "./interfaces/base"
import { useAppSelector } from "../../store/hooks"
import RenderChildren from "./RenderChildren";
import styled from '@emotion/styled';
;

interface Props {
  id: number,
  attributes: AllComponents['attributes'] & any, //Fix this
  childElements: AllComponents[],
  childWebpageComponentRelations: any,
  editor: JSX.Element
}

const Section = styled('div')(
  null,
  props => function(props: any){
    return {...props['data-style']}
  }
);

export default function SectionComponent({ id, attributes, childElements, childWebpageComponentRelations, editor }: Props) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"))
  if (attributes && attributes.classes == 'row') {
    let style: any = {};
    if(attributes.content_width_size){
      style = {
        maxWidth: attributes.content_width_size + attributes.content_width_unit
      }
    }
    
    return (
      <div className='container' style={style}>
        <Section
          id={id ? id.toString() : ''}
          data-style={attributes && attributes.styles}
          className={attributes && attributes.classes + ' builder-component builder-component__section' + (editorId === id ? ' builder-component--active' : '')}
          data-component-type='section'>
          {attributes && attributes.text}
          <RenderChildren key={id + "children"} childWebpageComponentRelations={childWebpageComponentRelations}/>
        </Section>
      </div>
    )
  }

  return (
    <Section
      id={id ? id.toString() : ''}
      data-style={attributes && attributes.styles}
      className={attributes && attributes.classes + ' builder-component builder-component__section' + (editorId === id ? ' builder-component--active' : '')}
      data-component-type='section'>
      {attributes && attributes.text}
      <RenderChildren key={id + "children"} childWebpageComponentRelations={childWebpageComponentRelations}/>
    </Section>
  )
}
