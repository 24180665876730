import { useAppSelector } from "../../store/hooks"
import './Logo.css'
import { AllComponents, LogoComponent as LogoComponent_ } from "./interfaces/base"
import RenderChildren from "./RenderChildren";
;

interface Props {
  id: number,
  editor: JSX.Element,
  type: 'text' | 'logo' | 'none',
  attributes: LogoComponent_['attributes'],
  childElements: AllComponents[],
  childWebpageComponentRelations: [],
}

export default function LogoComponent({ id, attributes, type, childElements, childWebpageComponentRelations }: Props) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"))

  return (
    type !== "none" && (
      <div
        id={id.toString()}
        data-component-type='logo'
        style={{ ...attributes.styles }}
        className={
          "navbar-brand " + (attributes.classes ?? "") + " builder-component builder-component__logo" + (editorId === id ? " builder-component--active" : "")
        }
      >
        
        <RenderChildren key={id + "children"} childWebpageComponentRelations={childWebpageComponentRelations}/>
        {type == "text" && <span contentEditable='true'>{attributes.text ?? "text placeholder"}</span>}
        {type == "logo" && (
          <>
            <div
              className='desktop-logo'
              style={{
                ...attributes.logo.Desktop.styles,
                height: (attributes.logo.Desktop.styles?.backgroundSize as string)?.split(" ")[1] ?? "50px",
                width: (attributes.logo.Desktop.styles?.backgroundSize as string)?.split(" ")[0] ?? "50px",
              }}
            ></div>
            <div
              className='mobile-logo'
              style={{
                ...attributes.logo.Mobile.styles,
                height: (attributes.logo.Mobile.styles?.backgroundSize as string)?.split(" ")[1] ?? "50px",
                width: (attributes.logo.Mobile.styles?.backgroundSize as string)?.split(" ")[0] ?? "50px",
              }}
            ></div>
          </>
        )}
      </div>
    )
  )
}
