import React, { useState } from "react";
import WebsiteAPI from "../../../../../store/reducers/builderReducers/website/websiteApi";
import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";

interface Props {
  handleEdit: (v: boolean) => void;
  website: Website;
}

export default function WebsiteGeneralFormEdit({ handleEdit, website }: Props) {
  const [cachedWebsiteInfo, setCachedWebsiteInfo] = useState({
    name: website!.name,
    description: website!.description,
  });

  function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    WebsiteAPI.saveWebsite({ ...website!, ...cachedWebsiteInfo }).then(() => {
      handleEdit(false);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row">
        <div className="col-12 fw-bold">Website: {website!.name} e-pood</div>
      </div>
      <div className="b-row b-mt-3">
        <div className="col-12">
          <label className="fs-small">Web domain</label>
          <input
            className="builder-input form-control"
            value={cachedWebsiteInfo.name}
            onChange={(evt) =>
              setCachedWebsiteInfo({
                ...cachedWebsiteInfo,
                name: evt.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="b-row b-mt-4">
        <div className="col-12">
          <label className="fs-small">Description</label>
          <input
            className="builder-input form-control"
            value={cachedWebsiteInfo.description ?? ""}
            onChange={(evt) =>
              setCachedWebsiteInfo({
                ...cachedWebsiteInfo,
                description: evt.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="b-mt-3">
        <button className=" sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => handleEdit(false)}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
