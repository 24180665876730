import React, {
  useState,
  useEffect,
  MouseEvent,
  KeyboardEvent,
  FocusEvent,
  memo,
  useMemo,
} from "react";
import { useAppSelector } from "../../store/hooks";
import { TextComponent as TextComponent_ } from "./interfaces/base";
import styled from "@emotion/styled";
import RenderChildren from "./RenderChildren";
const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

interface Props {
  id: number;
  attributes: TextComponent_["attributes"];
  editor: JSX.Element;
  childElements: any;
  childWebpageComponentRelations: any;
}

export default function TextComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: Props) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const typographies = useAppSelector((state) => state.builder.typographies);
  const colors = useAppSelector((state) => state.builder.colors);
  const [style, setStyle] = useState<React.CSSProperties>();
  // const [mouseUp, setMouseUp] = useState<MouseEvent<HTMLSpanElement, any> | null>(null)
  // const [keyDown, setKeyDown] = useState<KeyboardEvent | null>(null)
  // const [onBlur, setOnBlur] = useState<FocusEvent | null>(null)

  useEffect(() => {
    const compTypography = typographies?.find(
      (x: any) => x.id === attributes.typography_id
    );

    if (compTypography) {
      setStyle({
        fontSize: compTypography.font_size + compTypography.font_size_unit,
        fontFamily: compTypography.font_family,
        fontStyle: compTypography.font_style,
        fontWeight: compTypography.font_weight,
        letterSpacing: compTypography.letter_spacing,
        lineHeight: compTypography.line_height,
        color: colors?.find((x: any) => x.id === compTypography.color_id)
          ?.color,
      });
    }
  }, [typographies, attributes]);

  const content = (
    <span
      dangerouslySetInnerHTML={{ __html: attributes.text }}
      contentEditable={editorId == id}
      // onMouseUp={(e) => setMouseUp(e)}
      // onKeyDown={(e) => setKeyDown(e)}
      // onBlur={(e) => setOnBlur(e)}
    ></span>
  );

  const tags: { [key: string]: JSX.Element } = {
    h1: <h1 style={{ ...style, ...attributes.styles }}>{content}</h1>,
    h2: <h2 style={{ ...style, ...attributes.styles }}>{content}</h2>,
    h3: <h3 style={{ ...style, ...attributes.styles }}>{content}</h3>,
    h4: <h4 style={{ ...style, ...attributes.styles }}>{content}</h4>,
    h5: <h5 style={{ ...style, ...attributes.styles }}>{content}</h5>,
    h6: <h6 style={{ ...style, ...attributes.styles }}>{content}</h6>,
    p: <p style={{ ...style, ...attributes.styles }}>{content}</p>,
  };

  return (
    <Div
      id={id.toString()}
      className={
        (attributes.classes ?? "") +
        " builder-component builder-component__text-editor" +
        (editorId === id ? " builder-component--active" : "")
      }
      data-style={attributes && attributes.styles}
      spellCheck="false"
    >
      {
        tags[
          typographies?.find((x: any) => x.id === attributes.typography_id)
            ?.tag ?? "p"
        ]
      }
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />
    </Div>
  );
}
