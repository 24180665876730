import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import { useEffect, useState } from "react"
import { useShoppingCart } from "./context/ShoppingCartContext"
import { pf } from "./utilities/helpers"
import { useAppSelector } from "../../store/hooks"
import { CartComponent as CartComponent_ } from "./interfaces/base"
import RenderChildren from "./RenderChildren";
import styled from '@emotion/styled';
const Div = styled('div')(
  null,
  props => function(props: any){
    return {...props['data-style']}
  }
);
;

export default function CartComponent({ id, attributes, childElements, childWebpageComponentRelations }: CartComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"))
  const [mouseUp, setMouseUp] = useState<any>(null)
  const [keyDown, setKeyDown] = useState<any>(null)
  const typographies = useAppSelector((state) => state.builder.typographies)
  const [style, setStyle] = useState<React.CSSProperties>()
  const colors = useAppSelector((state) => state.builder.colors)
  const [hoverStyle, setHoverStyle] = useState<any>()

  const { toggleCart, cartItems, locationProducts } = useShoppingCart()
  
  useEffect(() => {
    const compTypography = typographies?.find((x: any) => x.id === attributes.typography_id)
    if (compTypography) {
      setStyle({
        fontSize: compTypography.font_size + compTypography.font_size_unit,
        fontFamily: compTypography.font_family,
        color: colors?.find((x: any) => x.id === compTypography.color_id)?.color,
        transition: compTypography.transition_duration+' color '+compTypography.transition_effect
      })
      setHoverStyle({
        '&:hover, &:hover > svg': {
          color: colors?.find((x: any) => x.id === compTypography.link_hover_color_id)?.color + ' !important'
        },
      })
    }
  }, [typographies, attributes])

  return (
    <div
      id={id.toString()}
      data-component-type='cart'
      className={(attributes.classes ?? "") + " builder-component builder-component__cart" + (editorId === id ? " builder-component--active" : "")}
      style={editorId === id ? { padding: "5px 25px" } : {  }}
    >
      <RenderChildren key={id + "children"} childWebpageComponentRelations={childWebpageComponentRelations}/>
      <Div data-style={{ ...style, ...attributes.styles, ...hoverStyle}} onClick={() => toggleCart()}>
        <ShoppingCartIcon style={{ fontSize: "21px", color:  attributes.styles.borderTopColor}} />
        <div
          style={{ fontSize: "14px", paddingLeft: "3px", paddingRight: "3px" }}
          contentEditable={editorId == id}
          suppressContentEditableWarning={true}
          onMouseUp={(e) => setMouseUp(e)}
          onKeyDown={(e) => setKeyDown(e)}
        >
          {pf(
            cartItems.reduce((total, cartItem) => {
              const item = locationProducts.find(i => i.id === cartItem.id)
              return total + (item?.price_total || 0) * cartItem.quantity
            }, 0)
          )} €
        </div>
      </Div>
    </div>
  )
}
