
import { Editor } from './editorApi'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: Editor = {
    component_id: null,
    component_parent_id: null,
}

export const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        UPDATE_EDITOR: (state: any, action: PayloadAction<Editor>) => {
            // console.log('UPDATE_TEMPLATE')
            state = action.payload
            return state
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    UPDATE_EDITOR,
} = editorSlice.actions

export default editorSlice.reducer
