import { FooterComponent as FooterComponent_, SectionComponent as SectionComponent_ } from "./interfaces/base"
import { useAppSelector } from "../../store/hooks"
import RenderChildren from "./RenderChildren";
import styled from '@emotion/styled';
const Footer = styled('footer')(
  null,
  props => function(props: any){
    return {...props['data-style']}
  }
);
;

export default function FooterComponent({ id, attributes, childElements, childWebpageComponentRelations }: FooterComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"))

  return (
    <Footer id={id.toString()}
    data-style={attributes.styles ?? ""}
    data-component-type='footer'
    className={"builder-component builder-component__header " + (attributes.classes ?? "") + (editorId === id ? " builder-component--active" : "")}
    >
      <RenderChildren key={id + "children"} childWebpageComponentRelations={childWebpageComponentRelations}/>
    </Footer>
  )
}
