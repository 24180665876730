import { FormEvent, useRef, useState } from "react";
import WebsiteAPI, {
  Website,
} from "../../../../../store/reducers/builderReducers/website/websiteApi";
import Select from "react-select";
import { DefaultReactSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";

interface Props {
  website: Website;
  setFormModes: (obj: { globalSettings: boolean }) => void;
}

export default function GlobalSettingsFormEdit({
  website,
  setFormModes,
}: Props) {
  const [cachedWebsite, setCachedWebsite] = useState({ ...website });

  const fontSizeUnits = [{ label: "px", value: "px" }];

  const DefaultReactSelectStyle_ = {
    ...DefaultReactSelectStyle,
    valueContainer: (styles: any) => {
      return {
        ...styles,
        paddingLeft: "0px",
        paddingRight: "0px",
      };
    },
  };

  function updateBaseFontSize(value: string) {
    setCachedWebsite({ ...cachedWebsite, base_font_size: value });
  }

  async function handleSubmit(evt: FormEvent) {
    evt.preventDefault();
    let val = {};
    if (!cachedWebsite.base_font_size.match(/\d+(\.(\d+))?px/)) {
      val = { base_font_size: "12px" };
    }
    WebsiteAPI.saveWebsite({ ...cachedWebsite, ...val }).then(() => {
      setFormModes({ globalSettings: false });
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row b-mt-4">
        <div className="col-12 b-mb-3 b-pb-1 fw-bold">
          Website: Global settings
        </div>
        <div className="col-4">
          <label className="fs-small">Base font size</label>
          <div className="input-group">
            <input
              maxLength={4}
              className="form-input builder-input"
              value={
                cachedWebsite.base_font_size.match(/\d+(\.(\d+)?)?/)?.length
                  ? cachedWebsite.base_font_size.match(/\d+(\.(\d+)?)?/)![0]
                  : ""
              }
              onChange={(evt) =>
                updateBaseFontSize(
                  (evt.target.value.match(/\d+(\.(\d+)?)?/)?.length
                    ? evt.target.value.match(/\d+(\.(\d+)?)?/)![0]
                    : "") + cachedWebsite.base_font_size.replace(/[0-9.]/g, "")
                )
              }
              style={{ width: "39%" }}
            />
            <div style={{ width: "61%" }}>
              <Select
                isSearchable={false}
                options={fontSizeUnits}
                value={
                  fontSizeUnits.find(
                    (x) =>
                      x.value ===
                      cachedWebsite.base_font_size.replace(/[0-9.]/g, "")
                  )
                    ? fontSizeUnits.find(
                        (x) =>
                          x.value ===
                          cachedWebsite.base_font_size.replace(/[0-9.]/g, "")
                      )
                    : fontSizeUnits[0]
                }
                onChange={(evt) =>
                  updateBaseFontSize(
                    (cachedWebsite.base_font_size.match(/\d+(\.(\d+)?)?/)
                      ?.length
                      ? cachedWebsite.base_font_size.match(/\d+(\.(\d+)?)?/)![0]
                      : "") + evt!.value
                  )
                }
                styles={DefaultReactSelectStyle_}
              ></Select>
            </div>
          </div>
        </div>
      </div>
      <div className="b-mt-3">
        <button className=" sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => setFormModes({ globalSettings: false })}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
