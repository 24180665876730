import store from "../../../store"
import { ApiClient } from './api_clientsApi'

import {
    SET_API_CLIENTS,
} from './api_clientsReducer'



export function setApiClients(payload: Array<ApiClient>) {
    return store.dispatch(
      SET_API_CLIENTS(payload)
    )
}