import StructureAPI from '../../../../../store/reducers/builderReducers/structure/structureApi'
import { addNewComponent } from '../../../../../store/reducers/builderReducers/structure/structureActions'
import TemplateAPI, { Template } from '../../../../../store/reducers/builderReducers/templates/templateApi'
import { useAppSelector } from '../../../../../store/hooks'
import { AllComponents } from '../../../../_default/interfaces/base'
import { getComponentType } from '../../../../../utils/Helpers'

interface Props {
  component: AllComponents | undefined
  sort_order: number
}

export default function SelectTemplate({ component, sort_order }: Props) {
  console.log('SelectTemplate')
  const templates = useAppSelector((state) => state.builder.templates)
  const pages = useAppSelector((state) => state.builder.pages)
  // const structure = useAppSelector((state) => state.builder.structure)
  const componentType = component ? getComponentType(component) : null
  let allowedComponentTypes: Array<String> = []
  switch (componentType) {
    case 'row':
      allowedComponentTypes = ['col']
      break
    case 'col':
      allowedComponentTypes = ['text', 'ProductsComponent', 'ProductDetailComponent', 'Checkout', 'Button', 'form', 'row']
      break
    case null:
      allowedComponentTypes = ['row']
      break

    default:
      allowedComponentTypes = []
      break
  }
  console.log(allowedComponentTypes)
  console.log([...templates])
  console.log([
    ...templates.filter((template) => {
      return template.component && allowedComponentTypes.includes(getComponentType(template.component))
    }),
  ])

  function onTemplateClick(template: Template) {
    // const element: any = structure.components[template.webpage_component_id];
    // const addElement = {...element, parent_id: component.id}
    // addNewComponent(addElement as AllComponents);
    if (pages.active) {
      StructureAPI.addExistingComponent(pages.active, {
        id: template.webpage_component_id,
        parent_id: component?.id ?? null,
        sort_order: sort_order + 1,
      })
    }
  }

  return (
    <ul className='m-0 p-0'>
      <div className='select-element-type__container'>
        <div className='select-element-type__list'>
          {templates
            .filter((template) => {
              return template.component && allowedComponentTypes.includes(getComponentType(template.component))
            })
            .map((template) => (
              <div key={template.id} className='select-element-type__list__item' onClick={() => onTemplateClick(template)}>
                {template.name}
              </div>
            ))}
        </div>
      </div>
    </ul>
  )
}
