import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import TemplateApi, {
  Template,
} from "../../../../../store/reducers/builderReducers/templates/templateApi";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TemplateDetails from "./TemplateDetails";
import { Edit } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";

import { getTemplateCompType, inClass } from "../../../../../utils/Helpers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  addNewComponent,
  deleteComponentById,
} from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { editorId } from "../../../../../pages/Builder";
import { setEditableComponentId } from "../../../../../store/reducers/textEditor/textEditorActions";
import { setActivePage } from "../../../../../store/reducers/builderReducers/pages/pagesActions";
import DeleteConfirmModal from "../../../Modal/DeleteConfirmModal";

export default function Templates() {
  React.useEffect(() => {
    if (!templates.size) {
      TemplateApi.loadTemplates();
    }
  }, []);
  const templates = useAppSelector((state) => state.builder.templates);
  const editorComponent = useAppSelector(
    (state) => state.builder.structure.components[editorId]
  )!;

  console.log(templates);

  const pages = useAppSelector((state) => state.builder.pages);
  const [editTemplate, setEditTemplate] = useState<Template | null>(null);
  const [groups, setGroups] = useState([
    { value: "row", label: "Row", show: true },
    { value: "col", label: "Col", show: true },
    { value: "element", label: "Element", show: true },
    { value: "cookiepolicy", label: "Cookie Policy", show: true },
  ]);
  if (editTemplate) {
    return (
      <TemplateDetails
        template={editTemplate}
        setEditTemplate={setEditTemplate}
        pages={pages.list}
      />
    );
  }

  function showHideTemplates(val: string) {
    const copy = [...groups];
    let item = groups.find((x) => x.value === val)!;
    item.show = !item.show;
    setGroups(copy);
  }

  async function setTemplateActive(e: any, template_: Template) {
    e.stopPropagation();
    if (inClass("dropdown-trigger", e.target)) return;
    if (template_.component!.id === editorComponent?.parent_id) {
      return;
    } else if (pages.list.find((x) => x.id === template_.webpages[0])) {
      if (!template_.webpages.includes(pages.active!)) {
        setActivePage(
          pages.list.find((x) => x.id === template_.webpages[0])!.id
        );
        // Wait for the new components to load
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      if (template_.component!.id !== editorComponent?.parent_id) {
        if (editorComponent) {
          deleteComponentById(editorId);
        }
        addNewComponent({
          id: editorId,
          type: "editor",
          sort_order: 1,
          parent_id: template_.component!.id,
          childElements: [],
        } as any);
        setEditableComponentId(template_.component!.id);
      }
    } else {
      setEditTemplate(template_);
    }
  }

  return (
    <div className="b-container">
      <div className="b-row">
        <div className="col-12 fw-bold">Website: Templates</div>
      </div>
      <div className="b-row b-mt-3 template-list">
        {groups
          .filter((x) =>
            templates.find((y) => getTemplateCompType(y.component) == x.value)
          )
          .map((g) => (
            <div
              key={g.value}
              className={"template-group " + (g.show ? "show" : "")}
            >
              <div
                className={"col-12 b-py-2"}
                onClick={() => showHideTemplates(g.value)}
                role="button"
              >
                {g.label}
                <div className="d-inline" style={{ marginLeft: "10px" }}>
                  {g.show ? (
                    <ArrowDropDownIcon style={{ fontSize: "20px" }} />
                  ) : (
                    <ArrowDropUpIcon style={{ fontSize: "20px" }} />
                  )}
                </div>
              </div>
              {templates
                .filter((x) => getTemplateCompType(x.component) == g.value)
                .map((template) => (
                  <div
                    key={template.id}
                    className={"col-12 b-py-2 template"}
                    onClick={(e) => {
                      setTemplateActive(e, template);
                    }}
                  >
                    <label>{template.name}</label>
                    <div className="float-end align-items-center d-flex b-gap-2">
                      <div className="template-use">{template.use}</div>
                      <div className="dropdown-trigger" role="button">
                        <ul className="dropdown-menu-styling">
                          <li>
                            <span
                              className="templates-addon-button"
                              onClick={() => setEditTemplate(template)}
                            >
                              <Edit />
                            </span>
                          </li>
                          <li>
                            <DeleteConfirmModal
                              isOverflow={true}
                              onConfirm={() =>
                                TemplateApi.deleteTemplate(template.id)
                              }
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
}
