import React, { useEffect, useState } from "react";
import Select from "react-select";
import TypographiesAPI from "../../../../../store/reducers/builderReducers/typographies/typographiesApi";
import { DefaultReactSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { Color } from "../../../../../store/reducers/builderReducers/color/colorApi";
import { Typography } from "../../../../../store/reducers/builderReducers/typographies/typographiesApi";
import { useAppSelector } from "../../../../../store/hooks";

interface Props {
  typography: Typography;
  colors: Color[];
  setFormModes: (obj: {}) => void;
}

export default function TypographyFormEdit({
  typography,
  colors,
  setFormModes,
}: Props) {
  const fontOptions = useAppSelector((state) => state.builder.font_options);
  const [cachedTypography, setCachedTypography] = useState(typography);

  const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      content: '" "',
      display: "block",
      marginRight: 5,
      height: 10,
      width: "9px",
      marginBottom: "1px",
    },
  });

  const reactSelectStyle = {
    ...DefaultReactSelectStyle,
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
      ...dot(data.color),
      color: "#fff",
    }),
    option: (styles: any, data: any) => {
      return {
        ...DefaultReactSelectStyle.option!(styles, data),
        ...dot(data.data.color),
      };
    },
    valueContainer: (styles: any) => ({
      ...styles,
      paddingLeft: "0px",
      paddingRight: "0px",
    }),
  };

  const DefaultReactSelectStyle_ = {
    ...DefaultReactSelectStyle,
    valueContainer: (styles: any) => {
      return {
        ...styles,
        paddingLeft: "0px",
        paddingRight: "0px",
      };
    },
  };

  let colorOptions: (Color & { label: string; value: number })[] = [];
  colors.forEach((color) => {
    colorOptions.push({ ...color, label: color.name, value: color.id });
  });

  const fontFamilies = fontOptions.map((i: any) => ({
    label: i.name,
    value: i.name,
  }));

  const tags = [
    { label: "H1", value: "h1" },
    { label: "H2", value: "h2" },
    { label: "H3", value: "h3" },
    { label: "H4", value: "h4" },
    { label: "H5", value: "h5" },
    { label: "H6", value: "h6" },
    { label: "Text", value: "p" },
  ];

  const transitionEffects = [
    { label: "ease-in", value: "ease-in" },
    { label: "ease-out", value: "ease-out" },
    { label: "ease-in-out", value: "ease-in-out" },
    { label: "linear", value: "linear" },
  ];

  const [fontStyles, setFontStyles] = useState<
    { label: string; value: string }[]
  >([]);
  const [fontWeights, setFontWeights] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    setFontStyles(
      fontOptions
        .find((x: any) => x.name === cachedTypography.font_family)!
        .options.map((x: any) => ({
          label: x["font-style"],
          value: x["font-style"],
        }))
    );
  }, [cachedTypography.font_family]);

  // If font-style list changed
  useEffect(() => {
    if (
      fontStyles.length &&
      !fontStyles.find((x) => x.value === cachedTypography.font_style)
    ) {
      setCachedTypography((prev) => ({
        ...prev,
        font_style: fontStyles[0].value,
      }));
    }
  }, [fontStyles]);

  // If font-weight list changed
  useEffect(() => {
    if (
      fontWeights.length &&
      !fontWeights.find((x) => x.value == cachedTypography.font_weight)
    ) {
      setCachedTypography((prev) => ({
        ...prev,
        font_weight: fontWeights[0].value,
      }));
    }
  }, [fontWeights]);

  useEffect(() => {
    setFontWeights(() => {
      let fontFamily = fontOptions.find(
        (x: any) => x.name === cachedTypography.font_family
      );
      if (!fontFamily) {
        return [];
      }
      let fontStyle = fontFamily.options.find(
        (x: any) => x["font-style"] == cachedTypography.font_style
      );
      if (!fontStyle) {
        return [];
      }
      return fontStyle["font-weights"].map((x: any) => ({
        label: x,
        value: x,
      }));
    });
  }, [cachedTypography.font_style, cachedTypography.font_family]);

  const fontSizeUnits = [
    { label: "px", value: "px" },
    { label: "rem", value: "rem" },
  ];

  const letterSpacingUnits = [
    ...fontSizeUnits,
    { label: "normal", value: "normal" },
  ];

  const transitionDurationUnits = [
    { label: "s", value: "s" },
    { label: "ms", value: "ms" },
  ];

  function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    TypographiesAPI.saveTypographyById(cachedTypography).then((x) => {
      setFormModes({ typography: { editing: false, id: 0 } });
    });
  }

  function extractUnits(str: string) {
    let numbers = extractNumbers(str);
    return str.replace(numbers, "");
  }

  function extractNumbers(str: string) {
    if (str === "normal") return "";
    let numbers = str.match(/^-?\d+/);
    if (numbers) {
      return numbers[0];
    }
    return "";
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row b-mt-3">
        <div className="col-12 b-mb-4">
          <label className="fs-small">Typography name</label>
          <input
            className="form-input builder-input"
            value={cachedTypography.name}
            onChange={(evt) =>
              setCachedTypography({
                ...cachedTypography,
                name: evt.target.value,
              })
            }
          />
        </div>
        <div className="col-12 b-mb-4">
          <label className="fs-small">Tag</label>
          <Select
            isSearchable={false}
            options={tags}
            value={tags.find((x) => x.value === cachedTypography.tag)}
            onChange={(evt: any) =>
              setCachedTypography({ ...cachedTypography, tag: evt!.value })
            }
            styles={DefaultReactSelectStyle_}
          ></Select>
        </div>

        <div className="col-4 b-mb-4">
          <label className="fs-small">Font family</label>
          <Select
            className=""
            isSearchable={false}
            options={fontFamilies.toArray()}
            value={fontFamilies.find(
              (x: any) => x.value === cachedTypography.font_family
            )}
            onChange={(evt: any) =>
              setCachedTypography({
                ...cachedTypography,
                font_family: evt!.value,
              })
            }
            styles={DefaultReactSelectStyle_}
          ></Select>
        </div>
        <div className="col-4 b-mb-4">
          <label className="fs-small">Font style</label>
          <Select
            isSearchable={false}
            options={fontStyles}
            value={fontStyles.find(
              (x) => x.value === cachedTypography.font_style
            )}
            onChange={(evt: any) =>
              setCachedTypography({
                ...cachedTypography,
                font_style: evt!.value,
              })
            }
            styles={DefaultReactSelectStyle_}
          ></Select>
        </div>
        <div className="col-4 b-mb-4">
          <label className="fs-small">Font weight</label>
          <Select
            isSearchable={false}
            options={fontWeights}
            value={fontWeights.find(
              (x) => x.value == cachedTypography.font_weight
            )}
            onChange={(evt) =>
              setCachedTypography({
                ...cachedTypography,
                font_weight: evt!.value,
              })
            }
            styles={DefaultReactSelectStyle_}
          ></Select>
        </div>

        <div className="col-4 b-mb-4">
          <label className="fs-small">Font size</label>
          <div className="input-group">
            <input
              className="builder-input"
              value={cachedTypography.font_size}
              onChange={(evt) =>
                setCachedTypography({
                  ...cachedTypography,
                  font_size: evt.target.value,
                })
              }
              style={{ width: "39%" }}
            />
            <div style={{ width: "61%" }}>
              <Select
                isSearchable={false}
                options={fontSizeUnits}
                value={fontSizeUnits.find(
                  (x) => x.value === cachedTypography.font_size_unit
                )}
                onChange={(evt) =>
                  setCachedTypography({
                    ...cachedTypography,
                    font_size_unit: evt!.value,
                  })
                }
                styles={DefaultReactSelectStyle_}
              ></Select>
            </div>
          </div>
        </div>
        <div className="col-4 b-mb-4">
          <label className="fs-small">Letter spacing</label>
          <div className="input-group">
            {cachedTypography.letter_spacing !== "normal" && (
              <input
                className="builder-input"
                value={extractNumbers(cachedTypography.letter_spacing)}
                onChange={(evt) =>
                  setCachedTypography({
                    ...cachedTypography,
                    letter_spacing:
                      evt.target.value +
                      extractUnits(cachedTypography.letter_spacing),
                  })
                }
                style={{ width: "39%" }}
              />
            )}
            <div
              style={{
                width:
                  cachedTypography.letter_spacing === "normal" ? "100%" : "61%",
              }}
            >
              <Select
                isSearchable={false}
                options={letterSpacingUnits}
                value={letterSpacingUnits.find(
                  (x) =>
                    x.value === extractUnits(cachedTypography.letter_spacing)
                )}
                onChange={(evt) =>
                  setCachedTypography({
                    ...cachedTypography,
                    letter_spacing:
                      evt!.value === "normal"
                        ? evt!.value
                        : extractNumbers(cachedTypography.letter_spacing) +
                          evt!.value,
                  })
                }
                styles={DefaultReactSelectStyle_}
              ></Select>
            </div>
          </div>
        </div>
        <div className="col-4 b-mb-4">
          <label className="fs-small">Line height</label>
          <div className="input-group">
            {cachedTypography.line_height !== "normal" && (
              <input
                className="builder-input"
                value={extractNumbers(cachedTypography.line_height)}
                onChange={(evt) =>
                  setCachedTypography({
                    ...cachedTypography,
                    line_height:
                      evt.target.value +
                      extractUnits(cachedTypography.line_height),
                  })
                }
                style={{ width: "39%" }}
              />
            )}
            <div
              style={{
                width:
                  cachedTypography.line_height === "normal" ? "100%" : "61%",
              }}
            >
              <Select
                isSearchable={false}
                options={letterSpacingUnits}
                value={letterSpacingUnits.find(
                  (x) => x.value === extractUnits(cachedTypography.line_height)
                )}
                onChange={(evt) =>
                  setCachedTypography({
                    ...cachedTypography,
                    line_height:
                      evt!.value === "normal"
                        ? evt!.value
                        : extractNumbers(cachedTypography.line_height) +
                          evt!.value,
                  })
                }
                styles={DefaultReactSelectStyle_}
              ></Select>
            </div>
          </div>
        </div>

        <div className="col-4 b-mb-4">
          <label className="fs-small">Color</label>
          <Select
            menuPortalTarget={document.querySelector("body")}
            isSearchable={false}
            options={colorOptions}
            onChange={(evt) =>
              setCachedTypography({ ...cachedTypography, color_id: evt!.value })
            }
            value={colorOptions.find((x) => x.id === cachedTypography.color_id)}
            styles={reactSelectStyle}
          />
        </div>
        <div className="col-4 b-mb-4">
          <label className="fs-small">Color link</label>
          <Select
            menuPortalTarget={document.querySelector("body")}
            isSearchable={false}
            options={colorOptions}
            onChange={(evt) =>
              setCachedTypography({
                ...cachedTypography,
                link_color_id: evt!.value,
              })
            }
            value={colorOptions.find(
              (x) => x.id === cachedTypography.link_color_id
            )}
            styles={reactSelectStyle}
          />
        </div>
        <div className="col-4 b-mb-4">
          <label className="fs-small">Color link hover</label>
          <Select
            menuPortalTarget={document.querySelector("body")}
            isSearchable={false}
            options={colorOptions}
            onChange={(evt) =>
              setCachedTypography({
                ...cachedTypography,
                link_hover_color_id: evt!.value,
              })
            }
            value={colorOptions.find(
              (x) => x.id === cachedTypography.link_hover_color_id
            )}
            styles={reactSelectStyle}
          />
        </div>
        <div className="col-4 b-mb-4">
          <label className="fs-small">Trans. duration</label>
          <div className="input-group">
            <input
              className="builder-input"
              value={extractNumbers(cachedTypography.transition_duration)}
              onChange={(evt) =>
                setCachedTypography({
                  ...cachedTypography,
                  transition_duration:
                    evt.target.value +
                    extractUnits(cachedTypography.transition_duration),
                })
              }
              style={{ width: "39%" }}
            />
            <div style={{ width: "61%" }}>
              <Select
                isSearchable={false}
                options={transitionDurationUnits}
                value={transitionDurationUnits.find(
                  (x) =>
                    x.value ===
                    extractUnits(cachedTypography.transition_duration)
                )}
                onChange={(evt) =>
                  setCachedTypography({
                    ...cachedTypography,
                    transition_duration:
                      extractNumbers(cachedTypography.transition_duration) +
                      evt!.value,
                  })
                }
                styles={DefaultReactSelectStyle_}
              ></Select>
            </div>
          </div>
        </div>
        <div className="col-4 b-mb-4">
          <label className="fs-small">Trans. effect</label>
          <Select
            menuPortalTarget={document.querySelector("body")}
            isSearchable={false}
            options={transitionEffects}
            onChange={(evt) =>
              setCachedTypography({
                ...cachedTypography,
                transition_effect: evt!.value,
              })
            }
            value={transitionEffects.find(
              (x) => x.value === cachedTypography.transition_effect
            )}
            styles={DefaultReactSelectStyle_}
          />
        </div>
      </div>

      <div className="b-mt-3">
        <button className="b-btn btn-primary sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() =>
            setFormModes({ typography: { editing: false, id: 0 } })
          }
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
