import React, { useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
import ContentRenderWebpageComponent from "./ContentRenderWebpageComponent";
import "./CookiePolicyComponent.scss";

function CookiePolicyComponent() {
  const cookie = useAppSelector((state) => state.builder.templates);

  const cookieTemplate = cookie.find((x) => x.use === "cookiePolicy");
  
  const components = useAppSelector(
    (state) => state.builder.structure.components
  );
  const cookieComponent = cookieTemplate && components ? (components[cookieTemplate.webpage_component_id] ?? null) : null;

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const handleCookiePolicyShow = () => {
    const deleteCookie = (cname) => {
      const d = new Date();

      d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=; " + expires + "; path=/";
    };

    const setCookie = (cname, cvalue, exdays) => {
      const d = new Date();

      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
    };

    deleteCookie("user_cookie_consent");
    setCookie("user_cookie_consent", 1, 30);

    const parentElement = document.querySelector(".cookie-policy-popup");
    if (parentElement) {
      parentElement.classList.toggle("hidden");
      // Add hidden style to parent element
    }
  };

  const getElementByClassAsync = (className) => {
    return new Promise((resolve) => {
      const getElement = () => {
        const elements = document.querySelectorAll(`.${className} button`);

        if (elements.length > 0) {
          resolve(elements);
        } else {
          requestAnimationFrame(getElement);
        }
      };

      getElement();
    });
  };

  useEffect(() => {
    const componentDidMount = async () => {
      const cookieButtons = await getElementByClassAsync("cookie-policy-popup");

      (cookieButtons as NodeListOf<HTMLButtonElement>).forEach((button) => {
        button.addEventListener("click", handleCookiePolicyShow);
      });
    };

    componentDidMount();

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      const cookieButtons = document.querySelectorAll(
        ".cookie-policy-popup button"
      );
      cookieButtons.forEach((button) => {
        button.removeEventListener("click", handleCookiePolicyShow);
      });
    };
  }, []);

  let cookie_consent = getCookie("user_cookie_consent");

  if (
    cookie_consent != ""
    || !cookieTemplate
    || !cookieComponent
  ) {
    return <></>;
  } else {
    return (
      <div className="cookie-policy-popup">
        <ContentRenderWebpageComponent
          componentId={cookieTemplate?.webpage_component_id}
        />
      </div>
    );
  }
}

export default CookiePolicyComponent;
