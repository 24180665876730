import { useEffect, useLayoutEffect, useRef, useState } from "react"

export default function Confirmation({ onConfirm, onCancel, text = "Are you sure you want to remove this?", confirmText = "Remove" }: {id?: string, onConfirm: () => void, onCancel: () => void, text: string, confirmText?: string}) {
  const ref = useRef<any>(null)
  const [left, setLeft] = useState<number>(0)
  const [pos, setPos] = useState<DOMRect>()
    
  useLayoutEffect(() => {
    const resizeListener = () => {
      setPos(document.querySelector('.editor-confirmation__container')!.getBoundingClientRect())
    }
    window.addEventListener('resize', resizeListener)
    return () => window.removeEventListener('resize', resizeListener)
  })

  //useEffect(() => {
  //  if (pos && pos.x < 40) {
  //    setLeft(-280)
  //  } else if (pos && left < 0 && pos.x - pos.width > 40) {
  //    setLeft(0)
  //  }
  //}, [pos])
  
  // Set pos on initial load
  useEffect(() => {
    setPos(document.querySelector('.editor-confirmation__container')!.getBoundingClientRect())
  }, [])
  
  return (
    <div className='editor-confirmation editor-confirmation__container text-center' ref={ref} style={{left: `${left}px`}}>
      <div className='editor-confirmation editor-confirmation__overlay'></div>
      <div className='editor-confirmation editor-confirmation__form'>
          <div className='b-row'>
            <div className='col-12 b-fs-6 b-mb-3'>{text}</div>
            <div className='col-6'>
              <button className='b-btn btn-primary w-100' onClick={onCancel}>
                Cancel
              </button>
            </div>
            <div className='col-6'>
              <button className='b-btn btn-danger w-100' onClick={onConfirm}>
                {confirmText}
              </button>
            </div>
        </div>
      </div>
    </div>
  )
}
