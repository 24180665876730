import React, { useState } from "react";
import PagesAPI from "../../../../../store/reducers/builderReducers/pages/pagesApi";
import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";
import {
  deleteWebpageById,
  setActivePage,
} from "../../../../../store/reducers/builderReducers/pages/pagesActions";
import { Pages } from "../../../../../store/reducers/builderReducers/pages/pagesApi";
import Select from "react-select";
import { DefaultReactSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";

interface Props {
  page: Pages["list"][0];
  handleEdit: (form: {}) => void;
  setEditableId: (e: number | null) => void;
  website: Website;
}

export default function PageDetailsGeneralEditForm({
  page,
  handleEdit,
  setEditableId,
  website,
}: Props) {
  const [cachedPageInfo, setCachedPageInfo] = useState({
    name: page.name,
    url: page.url,
    page_type: page.page_type,
  });

  function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    setEditableId(null);
    if (!page.id) {
      PagesAPI.addWebpage(cachedPageInfo).then((x) => {
        console.log(x);

        handleEdit({ general: false });
        setEditableId(x.id);
        setActivePage(x.id);
      });
    } else {
      console.log(cachedPageInfo);

      PagesAPI.saveWebpageById(page.id, { ...page, ...cachedPageInfo }).then(
        (res) => {
          handleEdit({ general: false });
          setEditableId(null);
        }
      );
    }
  }

  function unsetEditableId() {
    setEditableId(null);
    if (!page.id) deleteWebpageById(0); // NEW
  }

  const DefaultReactSelectStyle_ = {
    ...DefaultReactSelectStyle,
    valueContainer: (styles: any) => {
      return {
        ...styles,
        paddingLeft: "0px",
        paddingRight: "0px",
      };
    },
  };
  const pageTypeOptions: any = [
    {
      label: "Content page",
      value: "contentPage",
      url: "",
    },
    {
      label: "Front page",
      value: "frontPage",
      url: "",
    },
    {
      label: "Checkout",
      value: "checkout",
      url: "checkout",
    },
    {
      label: "Not found",
      value: "notFound",
      url: "*",
    },
    {
      label: "Products",
      value: "products",
      url: "tooted",
    },
    {
      label: "Product",
      value: "product",
      url: "toode/:id",
    },

    {
      label: "Cookie policy page",
      value: "cookiePolicy",
      url: "cookiePolicy",
    },

    {
      label: "Privacy policy page",
      value: "privacyPolicy",
      url: "privacyPolicy",
    },
  ];
  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row b-mt-3">
        <div className="col-12">
          <label className="fs-small">Page type</label>
          <Select
            isSearchable={false}
            options={pageTypeOptions}
            value={pageTypeOptions.find(
              (x: any) => x.value === cachedPageInfo.page_type
            )}
            onChange={(option) => {
              setCachedPageInfo({
                ...cachedPageInfo,
                page_type: option!.value,
                url: option!.url,
              });
            }}
            styles={DefaultReactSelectStyle_}
          ></Select>
        </div>
      </div>
      <div className="b-row b-mt-3">
        <div className="col-12">
          <label className="fs-small">Page title</label>
          <input
            className="form-input builder-input"
            value={cachedPageInfo.name}
            onChange={(evt) =>
              setCachedPageInfo({ ...cachedPageInfo, name: evt.target.value })
            }
          />
        </div>
      </div>
      <div className="b-row b-mt-3">
        <div className="col-12">
          <label className="fs-small">Page url</label>
          <input
            className="form-input builder-input"
            value={cachedPageInfo.url}
            onChange={(evt) =>
              setCachedPageInfo({ ...cachedPageInfo, url: evt.target.value })
            }
          />
        </div>
      </div>
      <div className="b-my-3">
        <button className="sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() =>
            page.id ? handleEdit({ general: false }) : unsetEditableId()
          }
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
