import { TextField, Checkbox, FormControlLabel, FormControl, Autocomplete } from "@mui/material"
import { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { useForm, Controller } from "react-hook-form"
import { DeliveryMethod, useShoppingCart } from '../context/ShoppingCartContext'
import { pf } from '../utilities/helpers'
import Delivery from './Delivery'

interface Props {
  show: boolean
  setShow: (val: boolean) => void
  deliveryMethod: DeliveryMethod | null
}

export default function CheckoutDeliveryModal({ show, setShow, deliveryMethod }: Props) {
  const { orderDeliveryMethod, confirmDeliveryData, buyer } = useShoppingCart()

  var receiverEmail = ''
  var receiverFirstname = ''
  var receiverSurname = ''
  var receiverPhone = ''
  var address = ''
  var city = ''
  var postal_code = ''
  var buyerIsReciever = true

  if (orderDeliveryMethod && orderDeliveryMethod.id) {
    receiverEmail = buyer.email
    receiverFirstname = buyer.firstname
    receiverSurname = buyer.surname
    receiverPhone = buyer.phone

    if (orderDeliveryMethod.receiverEmail) {
      receiverEmail = orderDeliveryMethod.receiverEmail
      receiverFirstname = orderDeliveryMethod.receiverFirstname
      receiverSurname = orderDeliveryMethod.receiverSurname
      receiverPhone = orderDeliveryMethod.receiverPhone
    }
    address = orderDeliveryMethod.address
    city = orderDeliveryMethod.city
    postal_code = orderDeliveryMethod.postal_code
    buyerIsReciever = orderDeliveryMethod.buyer_is_receiver ? false : true
  }

  const [parcelMachineLocation, setParcelMachineLocation] = useState<{machineLocation: string}|null>(null)

  var defaultValues = {
    receiverEmail: receiverEmail,
    receiverFirstname: receiverFirstname,
    receiverSurname: receiverSurname,
    receiverPhone: receiverPhone,
    address: address,
    city: city,
    postal_code: postal_code,
    buyerIsReciever: buyerIsReciever,
    deliveryMethod: deliveryMethod?.id,
    parcelMachineLocation: parcelMachineLocation,
  }

  const {
    control,
    handleSubmit,
    watch,
    register,
    unregister,
    formState: { errors },
  } = useForm({
    mode: 'all',
    values: defaultValues,
  })

  const watchbuyerIsReciever = watch('buyerIsReciever')

  const onSubmit = (data: any) => {
    if (parcelMachineLocation) {
      const parcelLocationArr = (parcelMachineLocation as any).machineLocation
        .substr((parcelMachineLocation as any).machineLocation.lastIndexOf('(') + 1, (parcelMachineLocation as any).machineLocation.lastIndexOf(')'))
        .replace(')', '')
        .split(',')
      confirmDeliveryData({
        ...data,
        address: parcelLocationArr[0].trim(),
        city: parcelLocationArr[1].trim(),
        postal_code: (parcelMachineLocation as any).machineLocation,
        parcelDescription: (parcelMachineLocation as any).description,
      })
    } else {
      confirmDeliveryData({
        ...data,
      })
    }
    setShow(false)
  }

  const onError = (data: any) => {
    console.log(data)
  }

  var delivery: any = null
  const [deliveryParcels, setDeliveryParcels] = useState([])
  useEffect(() => {
    if (deliveryMethod?.getDeliveryLocationsUrl) {
      delivery = Delivery(deliveryMethod.getDeliveryLocationsUrl, deliveryMethod.type)
      // Get list of parcel machines
      delivery.getParcelMachines(function (res: any) {
        setDeliveryParcels(res)
      })
    }
  }, [deliveryMethod])
  
  useEffect(() => {
    setParcelMachineLocation(deliveryParcels.find((x: any) => x.machineLocation === orderDeliveryMethod?.postal_code) ?? null)
  }, [deliveryParcels, orderDeliveryMethod?.postal_code])

  return (
    <Modal show={show} onHide={() => setShow(false)} id='checkoutDeliveryMethodModal' contentClassName='modal-600'>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className='badge badge-square badge-primary me-3'>3</span>
          <span className='fw-bold'>TARNE ANDMED</span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Body>
          <div className='row'>
            <div className='col-xs-12 selectedDeliveryMethodButtonContainer'>
              <button
                className={'btn btn-round btn-primary-outline text-left checkoutDeliveryButton' /*+ (deliveryMethod?.pickup ? ' margin-bottom-30' : '')*/}
                style={{ width: '100%' }}>
                {deliveryMethod?.name}
                <span className='pull-right'> {pf(deliveryMethod!.deliveryMethodPrice.price)} &euro;</span>
              </button>
            </div>
            {deliveryMethod?.isParcelMachine && (
              <div id='form-delivery-parcelMachine' className='col-md-12 padding-top-15'>
                <FormControl fullWidth>
                  <Controller
                    name='parcelMachineLocation'
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        onChange={(e, parcelMachineLocation) => {
                          setParcelMachineLocation(parcelMachineLocation)
                          field.onChange(e, parcelMachineLocation)
                        }}
                        options={deliveryParcels}
                        value={parcelMachineLocation}
                        getOptionLabel={(parcel: any) => parcel.machineLocation}
                        isOptionEqualToValue={(option, value) => option.machineLocation == value.machineLocation}
                        renderInput={(params) => <TextField {...params} variant='standard' placeholder='Vali pakiautomaat' />}
                      />
                    )}
                  />
                </FormControl>
              </div>
            )}
            {!(deliveryMethod as any).pickup && !(deliveryMethod as any).isParcelMachine && (
              <div id='form-delivery-address'>
                <div className='col-md-12'>
                  <Controller
                    name='address'
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label='Aadress'
                        variant='standard'
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.address?.type == 'required'}
                      />
                    )}
                  />
                </div>
                <div className='col-md-6'>
                  <Controller
                    name='city'
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label='Linn'
                        variant='standard'
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.city?.type == 'required'}
                      />
                    )}
                  />
                </div>
                <div className='col-md-6'>
                  <Controller
                    name='postal_code'
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label='Postiindeks'
                        variant='standard'
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.postal_code?.type == 'required'}
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <Controller
                name='buyerIsReciever'
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox classes={{root: 'custom-checkbox-root'}} value={field.value} checked={field.value} onChange={field.onChange} inputRef={field.ref} />}
                    label='Ostja võtab kauba vastu'
                  />
                )}
              />
            </div>
          </div>
          {!watchbuyerIsReciever && (
            <div className='row' id='buyerIsNotRecieverContainer'>
              <div className='col-md-6'>
                <Controller
                  name='receiverFirstname'
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label='Eesnimi'
                      variant='standard'
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.receiverFirstname?.type == 'required'}
                    />
                  )}
                />
              </div>
              <div className='col-md-6'>
                <Controller
                  name='receiverSurname'
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label='Perenimi'
                      variant='standard'
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.receiverSurname?.type == 'required'}
                    />
                  )}
                />
              </div>
              <div className='col-md-6'>
                <Controller
                  name='receiverEmail'
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label='E-post'
                      variant='standard'
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.receiverEmail?.type == 'required'}
                    />
                  )}
                />
              </div>
              <div className='col-md-6'>
                <Controller
                  name='receiverPhone'
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label='Mobiil'
                      variant='standard'
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.receiverPhone?.type == 'required'}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className='row'>
            <div className='col-xs-12 description'>{deliveryMethod?.description}</div>
          </div>

          <div className='mt-2 links-green'>
            Andmete salvestamiseks tuleb nõustuda{' '}
            <a href='/terms' target='_blank' className='color-primary'>
              kasutustingimustega
            </a>
            ,{' '}
            <a href='/privacy' target='_blank' className='color-primary'>
              küpsiste- ja privaatsuspoliitikaga
            </a>
            .
          </div>
        </Modal.Body>
        <Modal.Footer className=''>
          <button type='submit' className='btn btn-primary w-100'>
            NÕUSTUN TINGIMUSTEGA JA SALVESTAN
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
