import { fetchApiGet, fetchApiPost, fetchApiPut } from "../../../../api/api";
import { AllComponents } from "../../../../components/_default/interfaces/base";
import store from "../../../store";
import TemplateAPI from "../templates/templateApi";
import {
  updateStructure,
  setStructure,
  deleteComponentById,
  addNewComponent,
  addNewComponents,
} from "./structureActions";
import { StructureMap } from "./structureReducer";
import { NotificationManager } from "react-notifications";
const StructureAPI = {
  loadWebpageComponents,
  deleteComponent,
  addComponent,
  addExistingComponent,
  duplicateComponent,
  updateWebpageComponents,
};
export default StructureAPI;

export function loadWebpageComponents(webpageId: number) {
  return fetchApiGet<StructureMap>(
    `/webpages/${webpageId}/components`,
    {}
  ).then((result) => {
    console.log("SET STRUCTURE RAN", result);

    setStructure(result);
    return result;
  });
}

function deleteComponent(webpage_id: number, component: AllComponents) {
  const editor = store.getState().builder.editor;
  return fetchApiPost<any>(
    `/webpages/${webpage_id}/deleteComponent/${component.id}`,
    { component: component, editor: editor }
  ).then((x) => {
    if (x.hasOwnProperty("message")) {
      NotificationManager.error(x.message, null, 6000);
    } else deleteComponentById(component.id, editor);
    return x;
  });
}

function addComponent(webpage_id: number, data: any) {
  return fetchApiPost<StructureMap["components"]>(
    `/webpages/${webpage_id}/components`,
    data
  ).then((res) => addNewComponents(res));
}

function addExistingComponent(
  webpage_id: number,
  data: { id: number; parent_id: number | null; sort_order: number }
) {
  return fetchApiPost<StructureMap>(
    `/webpages/${webpage_id}/attachComponent`,
    data
  ).then((res) => updateStructure(res));
}

function setComponentChildElements(component: AllComponents) {
  const structure = store.getState().builder.structure;
  var childElements: AllComponents[] = [];
  component.childWebpageComponentRelations.map((cr) => {
    var childElement = structure.components[cr.child_id];
    if (childElement) {
      childElements.push(childElement);
    }
    return childElement;
  });

  return { ...component, childElements: childElements };
}

function duplicateComponent(webpage_id: number, component: AllComponents) {
  component = setComponentChildElements(component);
  return fetchApiPost<StructureMap["components"]>(
    `/webpages/${webpage_id}/components/duplicate`,
    { component }
  ).then((res) => addNewComponents(res));
}

function updateWebpageComponents(
  webpage_id: number | null,
  structure: StructureMap
) {
  return fetchApiPut<StructureMap>(`/webpages/${webpage_id}/updateComponents`, {
    structure,
  }).then((res) => console.log("Update webpage components response: ", res));
}
