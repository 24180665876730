import MetadataComponent from "../MetadataComponent"

export default function ProductMetadata({ locationProduct }: any) {
  const lang = 'et';
  console.log(locationProduct);
  const product_seo = locationProduct?.translations?.[lang].page_seo;
  if (!product_seo) {
    return <></>
  }
  return (
    <MetadataComponent page_seo={product_seo} />
  )
}
