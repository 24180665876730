import { Website } from '../../../../../store/reducers/builderReducers/website/websiteApi'

interface Props {
  handleEdit: (v: boolean) => void
  website: Website
}

export default function WebsiteGeneralFormView({ handleEdit, website }: Props) {
  return (
    <div className='b-row'>
      <div className='col-10'>
        <div className='col-12 fw-bold'>Website: {website!.name} e-pood</div>
        <div className='col-12 fs-small b-mt-n1'>https://{website!.name}</div>
      </div>
      <div className='col-2 b-pt-1 text-end' onClick={() => handleEdit(true)}>
        <a className='website-edit-button'>Edit</a>
      </div>
      <div className='col-12 b-mt-2'>{website!.description}</div>
    </div>
  )
}
