// POS Delivery
// Get delivery methods, machines etc based on parcel type
// Current parcel types:
//  - dpd_parcel_machine
//  - itella_parcel_machine
//  - omniva_parcel_machine
interface OmnivaLocation {
  ZIP: string
  NAME: string
  TYPE: string
  A0_NAME: string
  A1_NAME: string
  A2_NAME: string
  A3_NAME: string
  A4_NAME: string
  A5_NAME: string
  A6_NAME: string
  A7_NAME: string
  A8_NAME: string
  X_COORDINATE: string
  Y_COORDINATE: string
  SERVICE_HOURS: string
  TEMP_SERVICE_HOURS: string
  TEMP_SERVICE_HOURS_UNTIL: string
  TEMP_SERVICE_HOURS_2: string
  TEMP_SERVICE_HOURS_2_UNTIL: string
  comment_est: string
  comment_eng: string
  comment_rus: string
  comment_lav: string
  comment_lit: string
  MODIFIED: string
}

interface ItellaLocation {
  place_id: string
  name: string
  city: string
  address: string
  opened: string
  group_id: string
  group_name: string
  group_sort: string
  description: string
  active: string
  inactive_reason: string
  created_date: string
  updated_date: string
}

interface DpdLocation {
  parcelShopId: string
  legacyShopId: string
  parcelShopType: string
  companyName: string
  companyShortName: string
  street: string
  houseNo: string
  addressLine2: string
  countryCode: string
  zipCode: string
  city: string
  longitude: string
  latitude: string
  openingHours: Array<{
    weekday: 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday'
    openMorning: string
    closeMorning: string
    openAfternoon: string
    closeAfternoon: string
  }>
}

interface Machine {
  id: string
  country: string
  city: string
  street: string
  houseNr: string
  zipCode: string
  companyName: string
  description: string
  machineLocation: string
}

export default function Delivery(PARCEL_FILE_URL: string, PARCEL_TYPE: string) {
  // Path of delivery locations file
  var URL = PARCEL_FILE_URL

  // Storage expiration length (Currently: 24h)
  var STORAGE_EXP_LENGTH = 3600 * 1000 * 24

  // Expiry date for force refresh (yyyy-mm-dd hh:mm:ss)
  var STORAGE_FORCE_DATE = '2021-04-19 14:30:00'

  // Delivery providers
  var Providers = {
    DPD: 'dpd',
    ITELLA: 'itella',
    OMNIVA: 'omniva',
  }

  // Check if browser storage contains any parcel machines
  function getParcelMachinesFromBrowserStorage(parcel: string) {
    if (typeof Storage === 'undefined') {
      return false
    }
    var parcelMachines = localStorage.getItem(parcel)
    if (parcelMachines) {
      var data = JSON.parse(parcelMachines)
      // If expiry date exceeded, then remove old parcel machines
      // and reload new from file
      if (new Date(data.expiry) <= new Date()) {
        localStorage.removeItem(parcel)
        return false
      } else {
        // Date specific removal
        // Used to force browser storage refresh
        var refreshDate = new Date(STORAGE_FORCE_DATE.replace(/-/g, '/'))
        var expiryDateMinusExpLength = new Date(new Date(data.expiry).getTime() - STORAGE_EXP_LENGTH)
        // Force refresh
        if (refreshDate > expiryDateMinusExpLength) {
          localStorage.removeItem(parcel)
          return false
        }
        // Return machines from storage
        return data.data
      }
    }
    return false
  }

  // Set parcel machines to browser storage
  function setParcelMachinesToBrowserStorage(key: string, machines: Machine[]) {
    if (typeof Storage === 'undefined') {
      return false
    }
    localStorage.setItem(
      key,
      JSON.stringify({
        expiry: new Date(Date.now() + STORAGE_EXP_LENGTH),
        data: machines,
      })
    )
    return true
  }

  // Get parcel machines based on parcel type
  function getParcelMachines(callback: (v: any) => void) {
    var parcelName = getParcelFromType(PARCEL_TYPE)

    // First try to get parcel machines from browser storage
    var storageParcels = getParcelMachinesFromBrowserStorage(parcelName)

    // Parcels found, trigger callback
    if (storageParcels && storageParcels.length) {
      callback(storageParcels)
    } else {
      // No parcels in browser storage, get from file
      if (parcelName === Providers.ITELLA) {
        fetch(URL)
          .then((res) => res.text())
          .then((res) => {
            // Result
            var result = formatItella(res)
            // Trigger callback w/ formated data or pure result
            callback(result)
          })
      } else {
        fetch(URL)
          .then((res) => res.json())
          .then((res: OmnivaLocation[] | DpdLocation[]) => {
            var result: any = []
            // Format results DPD
            if (parcelName === Providers.DPD) {
              result = formatDPD(res as DpdLocation[])
            }
            // Format results OMNIVA
            if (parcelName === Providers.OMNIVA) {
              result = formatOmniva(res as OmnivaLocation[])
            }
            // Trigger callback w/ formated data or pure result
            callback(result)
          })
      }
    }
  }

  // Get parcel name based on parcel type
  function getParcelFromType(type: string) {
    return type.replace('_parcel_machine', '')
  }

  // Format DPD
  function formatDPD(data: DpdLocation[]) {
    var machines = data
      .filter(function (row) {
        return row.countryCode === 'EE'
      })
      .map(function (row) {
        var street = row.street.length > 0 ? row.street : ''
        var houseNr = row.houseNo.length > 0 ? ' ' + row.houseNo : ''
        var city = row.city.length > 0 ? ', ' + row.city : ''
        var zipCode = row.zipCode.length > 0 ? ', ' + row.zipCode : ''
        var accessible = ''
        // Day to shorthand
        row.openingHours.map(function (ln, i) {
          var d = ln.weekday
          var day =
            d === 'Monday'
              ? 'E'
              : d === 'Tuesday'
              ? 'T'
              : d === 'Wednesday'
              ? 'K'
              : d === 'Thursday'
              ? 'N'
              : d === 'Friday'
              ? 'R'
              : d === 'Saturday'
              ? 'L'
              : 'P'
          // Open times
          var timeDay = ln.openMorning
          var timeNight = ln.closeAfternoon
          accessible += day + ' ' + timeDay.slice(0, 2) + ':' + timeDay.slice(2) + '-' + timeNight.slice(0, 2) + ':' + timeNight.slice(2) + '\n'
        })
        return {
          id: row.parcelShopId,
          country: row.countryCode,
          city: row.city,
          street: row.street,
          houseNr: row.houseNo,
          zipCode: row.zipCode,
          companyName: row.companyName,
          description: accessible,
          machineLocation: row.companyName + ' (' + street + houseNr + city + zipCode + ')',
        }
      })
      .sort(function (a, b) {
        return a.companyName.localeCompare(b.companyName)
      })
    // Save machines to browser storage
    setParcelMachinesToBrowserStorage(Providers.DPD, machines)
    // Return machines
    return machines
  }

  // Format ITELLA
  function formatItella(data: string) {
    var _data: ItellaLocation[] = JSON.parse(data.replace('var places_info =', '').trim().replace(/.$/, ''))
    var machines = _data.map(function (row) {
      var street = row.address.length > 0 ? row.address : ''
      var city = row.city.length > 0 ? ', ' + row.city : ''
      var accessible = row.opened.length > 0 ? row.opened + '\n' : ''
      accessible += row.description && row.description.length > 0 ? row.description : ''
      return {
        id: row.place_id,
        country: 'EE',
        city: row.city,
        street: row.address,
        houseNr: '',
        zipCode: '',
        companyName: row.name,
        description: accessible,
        machineLocation: row.name + ' (' + street + city + ')',
      }
    })
    // Save machines to browser storage
    setParcelMachinesToBrowserStorage(Providers.ITELLA, machines)
    // Return machines
    return machines
  }

  // Format OMNIVA
  function formatOmniva(data: OmnivaLocation[]) {
    var machines = data
      .filter(function (row) {
        return row.A0_NAME === 'EE'
      })
      .map(function (row) {
        var street = row.A5_NAME.length > 0 ? row.A5_NAME : ''
        var houseNr = row.A7_NAME.length > 0 ? ' ' + row.A7_NAME : ''
        var city = row.A2_NAME.length > 0 ? (row.A5_NAME.length > 0 ? ', ' : '') + row.A2_NAME : ''
        var zipCode = row.ZIP.length > 0 ? ', ' + row.ZIP : ''
        return {
          id: row.ZIP,
          country: row.A0_NAME,
          city: row.A2_NAME,
          street: row.A5_NAME,
          houseNr: row.A7_NAME,
          zipCode: row.ZIP,
          companyName: row.NAME,
          description: row.SERVICE_HOURS,
          machineLocation: row.NAME + ' (' + street + houseNr + city + zipCode + ')',
        }
      })
    // Save machines to browser storage
    setParcelMachinesToBrowserStorage(Providers.OMNIVA, machines)
    // Return machines
    return machines
  }

  // Public methods
  return {
    getParcelMachines: getParcelMachines,
  }
}
