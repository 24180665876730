import React, { useState } from "react";
import TemplateApi, {
  Template,
} from "../../../../../store/reducers/builderReducers/templates/templateApi";
import Select from "react-select";
import { DefaultReactSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { useAppSelector } from "../../../../../store/hooks";

interface Props {
  template: Template;
  setGeneralEdit: any;
  setEditTemplate: any;
}

export default function TemplateDetailsGeneralEditForm({
  template,
  setGeneralEdit,
  setEditTemplate,
}: Props) {
  const activeBuilderElement = useAppSelector((state) => state.builder);

  const [cachedTemplateInfo, setCachedTemplateInfo] = useState({
    name: template.name,
    use: template.use,
  });

  function handleSubmit(evt: React.FormEvent) {
    console.log("submit");
    evt.preventDefault();
    setGeneralEdit(false);

    if (cachedTemplateInfo.use === "cookiePolicy") {
      const builderElement = document.getElementsByClassName("ContentRenderer");

      TemplateApi.saveTemplateById(template.id, {
        ...template,
        ...cachedTemplateInfo,
        visible: true,
      }).then((res) => {
        // setGeneralEdit(false)
        setEditTemplate({ ...template, ...cachedTemplateInfo });
      });
    } else {
      TemplateApi.saveTemplateById(template.id, {
        ...template,
        ...cachedTemplateInfo,
      }).then((res) => {
        // setGeneralEdit(false)
        setEditTemplate({ ...template, ...cachedTemplateInfo });
      });
    }
  }

  const DefaultReactSelectStyle_ = {
    ...DefaultReactSelectStyle,
    valueContainer: (styles: any) => {
      return {
        ...styles,
        paddingLeft: "0px",
        paddingRight: "0px",
      };
    },
  };
  const templateUseOptions: any = [
    {
      label: "Default",
      value: "",
      url: "",
    },
    {
      label: "Checkout terms",
      value: "checkoutTerms",
      url: "",
    },

    {
      label: "Cookie Policy",
      value: "cookiePolicy",
      url: "",
    },
  ];

  const cookiePolicyTemplatePositions = [
    {
      label: "Top",
      value: "top",
    },
    {
      label: "Middle",
      value: "middle",
    },
    {
      label: "Bottom",
      value: "bottom",
    },
  ];

  const cookiePolicyTemplateDisplay = [
    {
      label: "Block",
      value: "block",
    },
    {
      label: "Fixed",
      value: "fixed",
    },

    {
      label: "Absolute",
      value: "absolute",
    },
  ];

  const cookiePolicyTriggers = [
    {
      label: "On Page Load",
      value: "onLoad",
    },
    {
      label: "After few seconds",
      value: "onClick",
    },
  ];

  const cookiePolicyWebpages = [
    {
      label: "Home",
      value: "home",
    },
    {
      label: "Checkout",
      value: "checkout",
    },
  ];

  return (
    <form>
      <div className="b-row b-mt-3">
        <div className="col-12">
          <label className="fs-small">Name</label>
          <input
            className="form-input builder-input"
            value={cachedTemplateInfo.name}
            onChange={(evt) =>
              setCachedTemplateInfo({
                ...cachedTemplateInfo,
                name: evt.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="b-row b-mt-3">
        <div className="col-12">
          <label className="fs-small">Use</label>
          <Select
            isSearchable={false}
            options={templateUseOptions}
            value={templateUseOptions.find(
              (x: any) => x.value === cachedTemplateInfo.use
            )}
            onChange={(option) => {
              setCachedTemplateInfo({
                ...cachedTemplateInfo,
                use: option!.value,
              });
            }}
            styles={DefaultReactSelectStyle_}
          ></Select>
        </div>
      </div>

      {/* {cachedTemplateInfo.use === "cookieolicy" && (
        <div style={{ marginTop: "15px" }}>
          <div>
            <span>Width (20%-100%)</span>
            <input
              id="cookiePolicyWidth"
              type="range"
              min="20"
              max="100"
              step={"1"}
              value={
                cachedTemplateInfo.width === ""
                  ? "100"
                  : cachedTemplateInfo.width
              }
              onChange={(e) => {
                setCachedTemplateInfo({
                  ...cachedTemplateInfo,
                  width: e.target.value,
                });
              }}
            />
            <span>{cachedTemplateInfo.width}</span>
          </div>

          <div className="">
            <label className="fs-small">Position</label>
            <Select
              isSearchable={false}
              options={cookiePolicyTemplatePositions}
              value={cookiePolicyTemplatePositions.find(
                (x: any) => x.value === cachedTemplateInfo.position
              )}
              onChange={(option) => {
                setCachedTemplateInfo({
                  ...cachedTemplateInfo,
                  position: option!.value,
                });
              }}
              styles={DefaultReactSelectStyle_}
            ></Select>
          </div>

          <div className="">
            <label className="fs-small">Display</label>
            <Select
              isSearchable={false}
              options={cookiePolicyTemplateDisplay}
              value={cookiePolicyTemplateDisplay.find(
                (x: any) => x.value === cachedTemplateInfo.display
              )}
              onChange={(option) => {
                setCachedTemplateInfo({
                  ...cachedTemplateInfo,
                  display: option!.value,
                });
              }}
              styles={DefaultReactSelectStyle_}
            ></Select>
          </div>

          <div className="">
            <label className="fs-small">Trigger</label>
            <Select
              isSearchable={false}
              options={cookiePolicyTriggers}
              value={cookiePolicyTriggers.find(
                (x: any) => x.value === cachedTemplateInfo.trigger
              )}
              onChange={(option) => {
                setCachedTemplateInfo({
                  ...cachedTemplateInfo,
                  trigger: option!.value,
                });
              }}
              styles={DefaultReactSelectStyle_}
            ></Select>
          </div>

          <div style={{ marginTop: "15px" }}>
            <label htmlFor="cookiePolicyGenerate" style={{ fontSize: "12px" }}>
              Generate cookie policy page
            </label>
            <input
              id="cookiePolicyGenerate"
              type="checkbox"
              checked={
                cachedTemplateInfo.generatePolicy
                  ? cachedTemplateInfo.generatePolicy
                  : false
              }
              onChange={() => {
                setCachedTemplateInfo({
                  ...cachedTemplateInfo,
                  generatePolicy: !cachedTemplateInfo.generatePolicy,
                });
              }}
            />
          </div>
        </div>
      )} */}

      <div className="b-my-3">
        <button
          className="sidebar-save-btn"
          type="submit"
          onClick={handleSubmit}
        >
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => setGeneralEdit(false)}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
