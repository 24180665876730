import store from "../../../store"
import { Website } from '../../../../store/reducers/builderReducers/website/websiteApi'
import {
    UPDATE_WEBSITE,
} from './websiteReducer'

export function updateWebsite(payload: Website) {
    return store.dispatch(
      UPDATE_WEBSITE(payload)
    )
}