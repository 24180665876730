import { FormEvent, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LanguageSettingsAPI from "../../../../../store/reducers/builderReducers/languageSettings/languageSettingsApi";
import { LanguageSetting } from "../../../../../store/reducers/builderReducers/languageSettings/languageSettingsApi";
import { DropResult } from "react-beautiful-dnd";

interface Props {
  languageSetting: LanguageSetting;
  handleEdit: (obj: {}) => void;
}

export default function LanguagesActiveFormEdit({
  handleEdit,
  languageSetting,
}: Props) {
  const [cacheActiveLanguages, setCacheActiveLanguages] = useState(
    languageSetting!.activeLanguages
  );

  function onDragEnd(result: DropResult) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let copyOfCachedLangs = [...cacheActiveLanguages];
    const [removed] = copyOfCachedLangs.splice(
      cacheActiveLanguages.findIndex((x) => x.order === result.source.index),
      1
    );

    copyOfCachedLangs.splice(
      cacheActiveLanguages.findIndex(
        (x) => x.order === result.destination!.index
      ),
      0,
      removed
    );

    copyOfCachedLangs = copyOfCachedLangs.map((x, index) => {
      return { ...x, order: index + 1 };
    });

    setCacheActiveLanguages(copyOfCachedLangs);
  }

  function handleSubmit(evt: FormEvent) {
    evt.preventDefault();
    LanguageSettingsAPI.saveLanguageSettings({
      ...languageSetting!,
      activeLanguages: cacheActiveLanguages,
    }).then(() => {
      handleEdit({ languagesActive: false });
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row">
        <div className="col-12 b-pb-1 b-mb-3 fw-bold">Website: Languages</div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {cacheActiveLanguages.map((lang) => (
                <Draggable
                  key={lang.id}
                  draggableId={lang.id.toString()}
                  index={lang.order}
                >
                  {(provided, snapshot) => (
                    <div
                      className="b-row"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="col-12 b-mb-2 d-flex align-items-center">
                        <div
                          className="d-flex flex-column position-relative"
                          style={{ width: "25px", height: "100%" }}
                        >
                          <ArrowDropUpIcon
                            className="position-absolute"
                            style={{ top: "-5px" }}
                          />
                          <ArrowDropDownIcon
                            className="position-absolute"
                            style={{ top: "5px" }}
                          />
                        </div>
                        <img src={lang.icon} height="21px" width="32px"></img>
                        <label className="b-ps-3" style={{ cursor: "grab" }}>
                          {lang.name}
                        </label>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="b-mt-3">
        <button className="sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => handleEdit({ languagesActive: false })}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
