import React, {
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { useAppSelector } from "../../store/hooks";
import { ButtonComponent as ButtonComponent_ } from "./interfaces/base";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import RenderChildren from "./RenderChildren";
const Button = styled("button")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function ButtonComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}: ButtonComponent_) {
  const editorId = useAppSelector((state) => state.textEditor?.id);
  const typographies = useAppSelector((state) => state.builder.typographies);
  const colors = useAppSelector((state) => state.builder.colors);
  const [style, setStyle] = useState<React.CSSProperties>();
  const [hoverStyle, setHoverStyle] = useState<any>();
  // const [mouseUp, setMouseUp] = useState<MouseEvent<HTMLDivElement, any> | null>(null)
  // const [keyDown, setKeyDown] = useState<KeyboardEvent | null>(null)
  // const [onBlur, setOnBlur] = useState<FocusEvent | null>(null)
  const isBuilder = document.getElementById("BuilderContent");

  const component = useAppSelector(
    (state) => state.builder.structure.components
  );

  useEffect(() => {
    const compTypography = typographies?.find(
      (x: any) => x.id === attributes.typography_id
    );
    if (compTypography) {
      setStyle({
        backgroundColor: attributes.styles.backgroundColor ?? "#fff",
        width: attributes.styles.width ?? "auto",

        fontSize: compTypography.font_size + compTypography.font_size_unit,
        fontFamily: compTypography.font_family,
        fontStyle: compTypography.font_style,
        fontWeight: compTypography.font_weight,
        letterSpacing: compTypography.letter_spacing,
        lineHeight: attributes.styles.lineHeight ?? compTypography.line_height,
        color: colors?.find((x: any) => x.id === compTypography.color_id)
          ?.color,
        transition:
          compTypography.transition_duration +
          " color " +
          compTypography.transition_effect,
      });
      setHoverStyle({
        "&:hover": {
          color:
            colors?.find(
              (x: any) => x.id === compTypography.link_hover_color_id
            )?.color + " !important",
        },
      });
    }
  }, [typographies, attributes]);

  // If builder, do not render submit button, or else cant edit the button while in a form
  return (
    <Button
      as={
        attributes.link?.type === "Web"
          ? "a"
          : attributes.link?.type === "Page"
          ? Link
          : null
      }
      to={!isBuilder ? "/" + attributes.link?.url : "#"}
      target={attributes.link?.target}
      href={
        !isBuilder && attributes.link?.type === "Web"
          ? "/" + attributes.link?.url
          : null
      }
      id={id.toString()}
      type={isBuilder ? "button" : attributes.type ?? "button"}
      className={
        "builder-component builder-component__button " +
        (attributes.classes ?? "") +
        (editorId === id ? " builder-component--active" : "")
      }
      data-style={{ ...style, ...attributes.styles, ...hoverStyle }}
      data-component-type="button"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <RenderChildren
        key={id + "children"}
        childWebpageComponentRelations={childWebpageComponentRelations}
      />
      <div
        dangerouslySetInnerHTML={{ __html: attributes.text }}
        contentEditable={editorId == id}
        // onMouseUp={(e) => setMouseUp(e)}
        // onKeyDown={(e) => setKeyDown(e)}
        // onBlur={(e) => setOnBlur(e)}
      ></div>
    </Button>
  );
}
