import React, { useState } from "react";
import TypographiesAPI from "../../../../../store/reducers/builderReducers/typographies/typographiesApi";
import FontOptionsAPI from "../../../../../store/reducers/builderReducers/font_options/font_optionsApi";
import ColorAPI from "../../../../../store/reducers/builderReducers/color/colorApi";
import TypographyView from "./TypographyView";
import TypographyFormEdit from "./TypographyFormEdit";
import TypographyFormAdd from "./TypographyFormAdd";
import { useAppSelector } from "../../../../../store/hooks";
import { Typography as TypographyInterface } from "../../../../../store/reducers/builderReducers/typographies/typographiesApi";
import GlobalSettingsFormView from "./GlobalSettingsFormView";
import GlobalSettingsFormEdit from "./GlobalSettingsFormEdit";

interface TypographyFormModes {
  typography: { editing: boolean; id: number };
  globalSettings: boolean;
}

export default function Typography() {
  const typographies = useAppSelector(
    (state) =>
      JSON.parse(JSON.stringify(state.builder.typographies))?.sort(
        (a: TypographyInterface, b: TypographyInterface) =>
          a.name.localeCompare(b.name)
      ) as TypographyInterface[]
  );
  const website = useAppSelector((state) => state.builder.website);
  const colors = useAppSelector((state) => state.builder.colors);
  const [formModes, setFormModes_] = useState<TypographyFormModes>({
    typography: { editing: false, id: 0 },
    globalSettings: false,
  });

  function setFormModes(newMode: {}) {
    console.log(newMode);
    setFormModes_((modes: TypographyFormModes) => ({
      ...modes,
      ...newMode,
    }));
  }

  return (
    <div className="b-container">
      <div className="b-row">
        <div className="col-9 fw-bold">Website: Typographies</div>
        {!formModes.typography.editing && (
          <div className="col-3 b-pt-1 text-end">
            <a
              className="hover-underline typograhpy-button"
              onClick={() =>
                setFormModes({ typography: { editing: true, id: 0 } })
              }
            >
              + Add
            </a>
          </div>
        )}
      </div>
      {formModes.typography.editing && formModes.typography.id == 0 ? (
        <TypographyFormAdd
          setFormModes={setFormModes}
          colors={colors?.toArray()}
        />
      ) : formModes.typography.id ? (
        <TypographyFormEdit
          setFormModes={setFormModes}
          typography={
            typographies.find((x) => x.id === formModes.typography.id)!
          }
          colors={colors?.toArray()}
        />
      ) : (
        <div className="b-row b-mt-3 typography-list">
          {typographies?.map((x) => (
            <TypographyView
              key={x.id}
              typography={x}
              setFormModes={setFormModes}
            />
          ))}
        </div>
      )}

      {formModes.globalSettings ? (
        <GlobalSettingsFormEdit setFormModes={setFormModes} website={website} />
      ) : (
        <GlobalSettingsFormView setFormModes={setFormModes} website={website} />
      )}
      {/*formModes.fontUpload ? (
        <TypographyUploadFormEdit handleEdit={setFormModes} fonts={fonts} />
      ) : (
        <TypographyUploadFormView handleEdit={setFormModes} fonts={fonts} />
      )*/}
    </div>
  );
}
