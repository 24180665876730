import { useEffect, useState } from "react";
import ColorPicker from "../FontMenu/ColorPicker/ColorPicker";
import FileExplorer from "./FileExplorer/FileExplorer";
import {
  deleteComponentStyles,
  updateComponentStyle,
  updateStructureComponent,
} from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { MoreHorizOutlined } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteIcon from "@mui/icons-material/Delete";
import Confirmation from "../../Confirmation/Confirmation";
import Dropdown from "../../../Dropdown/Dropdown";
import { useAppSelector } from "../../../../../store/hooks";
import { Document } from "../../../../../store/reducers/builderReducers/documents/documentsApi";
import Select from "react-select";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";

interface Background {
  url: string;
  position: string;
  origin: string;
  repeat: string;
  size: string;
  attachment: string;
}

export default function BackgroundMenu({
  id,
  triggerResize,
}: {
  id: number;
  triggerResize: () => void;
}) {
  console.log("BackgroundMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )!;
  const [fileExplorerOpen, setFileExplorerOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [backgrounds, setBackgrounds] = useState<Array<Background>>([]);
  const [deleteIndex, setDeleteIndex] = useState<null | number>(0);
  const [showDropdown, setShowDropdown] = useState(false);

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isHoverColorPickerOpen, setIsHoverColorPickerOpen] = useState(false);

  const [isBackgroundRepeatOpen, setIsBackgroundRepeatOpen] = useState(false);
  const [isBackgroundPositionOpen, setIsBackgroundPositionOpen] =
    useState(false);
  const [isBackgroundOriginOpen, setIsBackgroundOriginOpen] = useState(false);
  const [isBackgroundSizeOpen, setIsBackgroundSizeOpen] = useState(false);
  const [isBackgroundAttachmentOpen, setIsBackgroundAttachmentOpen] =
    useState(false);

  // useEffect(() => {
  //   console.log("this ran");
  //   updateStructureComponent(id, "attributes.styles.backdropFilter", "none");
  // }, []);

  const positions = [
    { label: "Left Top", value: "left top" },
    { label: "Center Top", value: "center top" },
    { label: "Right Top", value: "right top" },
    { label: "Left Center", value: "left center" },
    { label: "Center Center", value: "center center" },
    { label: "Right Center", value: "right center" },
    { label: "Left Bottom", value: "left bottom" },
    { label: "Center Bottom", value: "center bottom" },
    { label: "Right Bottom", value: "right bottom" },
  ];

  const origins = ["border-box", "padding-box", "content-box"];

  const repeats = [
    { label: "Repeat", value: "repeat" },
    { label: "No repeat", value: "no-repeat" },
    { label: "Repeat X", value: "repeat-x" },
    { label: "Repeat Y", value: "repeat-y" },
    { label: "Space", value: "space" },
    { label: "Round", value: "round" },
  ];

  const sizes = [
    "auto",
    "px auto",
    "px px",
    "% auto",
    "% %",
    "cover",
    "contain",
  ];

  const attachments = [
    { label: "Scroll", value: "scroll" },
    { label: "Fixed", value: "fixed" },
    { label: "Local", value: "local" },
  ];

  const transitionEffects = [
    { label: "ease-in", value: "ease-in" },
    { label: "ease-out", value: "ease-out" },
    { label: "ease-in-out", value: "ease-in-out" },
    { label: "linear", value: "linear" },
  ];

  const transitionDurationUnits = [
    { label: "s", value: "s" },
    { label: "ms", value: "ms" },
  ];

  useEffect(() => {
    let backgrounds_: any = [];
    component.attributes.styles?.backgroundImage
      ?.split(",")
      .forEach((back: any, i: any) => {
        backgrounds_.push({
          url: extractUrl(back),
          position: component.attributes.styles.backgroundPosition
            ?.toString()
            .split(",")[i],
          origin: component.attributes.styles.backgroundOrigin
            ?.toString()
            .split(",")[i],
          repeat: component.attributes.styles.backgroundRepeat
            ?.toString()
            .split(",")[i],
          size: component.attributes.styles.backgroundSize
            ?.toString()
            .split(",")[i],
          attachment: component.attributes.styles.backgroundAttachment
            ?.toString()
            .split(",")[i],
        });
      });
    setBackgrounds(backgrounds_);
  }, [component!.attributes.styles]);

  useEffect(() => {
    triggerResize();
  }, [backgrounds]);

  function extractUrl(url: string) {
    // let url = str.match(
    //   /(?:(?:http?|https|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi
    // )![0]

    if (url.startsWith("url(")) {
      url = url.substring(4, url.length - 1);
    }
    if (url.startsWith('"')) {
      return url.substring(1, url.length - 1);
    }
    if (url.startsWith("'")) {
      return url.substring(1, url.length - 1);
    }
    return url;
  }

  function setBackground(item: Document) {
    if (item.extension === "mp4") {
      return;
    }

    if (!component.attributes.styles.backgroundImage?.includes("url(")) {
      updateComponentStyle(id, {
        backgroundImage:
          'url("' + (item.type === "reference" ? item.path : item.url) + '")',
        backgroundPosition: "center top",
        backgroundSize: "cover",
        backgroundRepeat: "repeat",
        backgroundOrigin: "padding-box",
        backgroundAttachment: "scroll",
      });
    } else {
      updateComponentStyle(id, {
        backgroundImage:
          component.attributes.styles.backgroundImage +
          ',url("' +
          (item.type === "reference" ? item.path : item.url) +
          '")',
        backgroundPosition:
          component.attributes.styles.backgroundPosition + ",center top",
        backgroundSize: component.attributes.styles.backgroundSize + ",cover",
        backgroundRepeat:
          component.attributes.styles.backgroundRepeat + ",repeat",
        backgroundOrigin:
          component.attributes.styles.backgroundOrigin + ",padding-box",
        backgroundAttachment:
          component.attributes.styles.backgroundAttachment + ",scroll",
      });
    }
  }

  function getSelectedBackgroundSize(size: string) {
    if (size.includes("px") && size.includes("auto")) {
      return "px auto";
    } else if ((size.match(/px/g) || []).length == 2) {
      return "px px";
    } else if (size.includes("%") && size.includes("auto")) {
      return "% auto";
    } else if ((size.match(/%/g) || []).length == 2) {
      return "% %";
    } else {
      return size;
    }
  }

  function extractNumbers(str: string) {
    let numbers = str.match(/^-?\d+/);
    if (numbers) {
      return numbers[0];
    }
    return "";
  }

  function extractUnits(str: string) {
    let numbers = extractNumbers(str);
    return str.replace(numbers, "");
  }

  function renderBackgroundSizeOption(
    url: string,
    value: string,
    size: string
  ) {
    const selectedSize = getSelectedBackgroundSize(value);
    if (selectedSize !== size) return size;

    if (selectedSize == "px auto") {
      let pxSize = Number(
        value.match(/\-?\d*\.?\d+/) ? value.match(/\-?\d*\.?\d+/)![0] : 14
      );
      return (
        <>
          <span
            onClick={() =>
              setBackgroundPropByUrl(url, "size", pxSize - 1 + "px auto")
            }
          >
            {" "}
            -{" "}
          </span>{" "}
          {pxSize}{" "}
          <span
            onClick={() =>
              setBackgroundPropByUrl(url, "size", pxSize + 1 + "px auto")
            }
          >
            {" "}
            +{" "}
          </span>
          <span className="me-2">px</span>auto
        </>
      );
    } else if (selectedSize == "px px") {
      let pxSizes: any = value.match(/\-?\d*\.?\d+/g);
      if (!pxSizes || pxSizes.length != 2) {
        pxSizes = [14, 14];
      }
      return (
        <>
          <span
            onClick={() =>
              setBackgroundPropByUrl(
                url,
                "size",
                Number(pxSizes[0] ?? 14) -
                  1 +
                  "px " +
                  Number(pxSizes[1] ?? 14) +
                  "px"
              )
            }
          >
            -{" "}
          </span>{" "}
          {pxSizes[0] ?? 14}
          <span
            className="me-2"
            onClick={() =>
              setBackgroundPropByUrl(
                url,
                "size",
                Number(pxSizes[0] ?? 14) +
                  1 +
                  "px " +
                  Number(pxSizes[1] ?? 14) +
                  "px"
              )
            }
          >
            {" "}
            +{" "}
          </span>
          <span
            onClick={() =>
              setBackgroundPropByUrl(
                url,
                "size",
                Number(pxSizes[0] ?? 14) +
                  "px " +
                  (Number(pxSizes[1] ?? 14) - 1) +
                  "px"
              )
            }
          >
            {" "}
            -{" "}
          </span>{" "}
          {pxSizes[1] ?? 14}
          <span
            onClick={() =>
              setBackgroundPropByUrl(
                url,
                "size",
                Number(pxSizes[0] ?? 14) +
                  "px " +
                  (Number(pxSizes[1] ?? 14) + 1) +
                  "px"
              )
            }
          >
            {" "}
            +{" "}
          </span>
        </>
      );
    } else if (selectedSize == "% auto") {
      let pxSize = Number(
        value.match(/\-?\d*\.?\d+/) ? value.match(/\-?\d*\.?\d+/)![0] : 14
      );
      return (
        <>
          <span
            onClick={() =>
              setBackgroundPropByUrl(url, "size", pxSize - 1 + "% auto")
            }
          >
            {" "}
            -{" "}
          </span>{" "}
          {pxSize}
          {"% "}
          <span
            className="me-2"
            onClick={() =>
              setBackgroundPropByUrl(url, "size", pxSize + 1 + "% auto")
            }
          >
            {" "}
            +{" "}
          </span>{" "}
          auto
        </>
      );
    } else if (selectedSize == "% %") {
      let pxSizes: any = value.match(/\-?\d*\.?\d+/g);
      if (!pxSizes || pxSizes.length != 2) {
        pxSizes = [14, 14];
      }
      return (
        <>
          <span
            onClick={() =>
              setBackgroundPropByUrl(
                url,
                "size",
                Number(pxSizes[0] ?? 14) -
                  1 +
                  "% " +
                  Number(pxSizes[1] ?? 14) +
                  "%"
              )
            }
          >
            -{" "}
          </span>
          {pxSizes[0] ?? 14}
          {"% "}
          <span
            className="me-2"
            onClick={() =>
              setBackgroundPropByUrl(
                url,
                "size",
                Number(pxSizes[0] ?? 14) +
                  1 +
                  "% " +
                  Number(pxSizes[1] ?? 14) +
                  "%"
              )
            }
          >
            {" "}
            +{" "}
          </span>
          <span
            onClick={() =>
              setBackgroundPropByUrl(
                url,
                "size",
                Number(pxSizes[0] ?? 14) +
                  "% " +
                  (Number(pxSizes[1] ?? 14) - 1) +
                  "%"
              )
            }
          >
            {" "}
            -{" "}
          </span>
          {pxSizes[1] ?? 14}
          {"% "}
          <span
            onClick={() =>
              setBackgroundPropByUrl(
                url,
                "size",
                Number(pxSizes[0] ?? 14) +
                  "% " +
                  (Number(pxSizes[1] ?? 14) + 1) +
                  "%"
              )
            }
          >
            {" "}
            +{" "}
          </span>
        </>
      );
    } else {
      return size;
    }
  }

  function setBackgroundPropByUrl(
    url: string,
    key: keyof Background,
    value: string
  ) {
    if (backgrounds && backgrounds.find((x) => x.url === url)![key] === value)
      return;
    setBackgrounds(
      backgrounds.map((x) => {
        if (x.url === url) {
          x[key] = value;
        }
        return x;
      })
    );
    updateComponentStyle(id, {
      backgroundImage: backgrounds.map((x) => 'url("' + x.url + '")').join(","),
      backgroundPosition: backgrounds.map((x) => x.position).join(","),
      backgroundSize: backgrounds.map((x) => x.size).join(","),
      backgroundRepeat: backgrounds.map((x) => x.repeat).join(","),
      backgroundOrigin: backgrounds.map((x) => x.origin).join(","),
      backgroundAttachment: backgrounds.map((x) => x.attachment).join(","),
    });
  }

  function deleteBackground(index: number) {
    const temp = [...backgrounds];
    temp.splice(index, 1);
    setBackgrounds(temp);
    if (temp.length === 0) {
      deleteComponentStyles(id, [
        "backgroundImage",
        "backgroundPosition",
        "backgroundSize",
        "backgroundRepeat",
        "backgroundOrigin",
        "backgroundAttachment",
      ]);
    } else {
      updateComponentStyle(id, {
        backgroundImage: temp.map((x) => 'url("' + x.url + '")').join(","),
        backgroundPosition: temp.map((x) => x.position).join(","),
        backgroundSize: temp.map((x) => x.size).join(","),
        backgroundRepeat: temp.map((x) => x.repeat).join(","),
        backgroundOrigin: temp.map((x) => x.origin).join(","),
        backgroundAttachment: temp.map((x) => x.attachment).join(","),
      });
    }
  }

  return (
    <>
      <div className="d-flex flex-wrap align-items-center b-gap-2">
        <div>Background:</div>
        <div className="b-ms-1 color-picker color-picker__swatch-item color-picker__swatch-item__parent position-relative dropup">
          <div
            className="color-picker color-picker__swatch-item position-relative"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            style={{
              margin: 0,
              backgroundColor:
                component.attributes.styles.backgroundColor ?? "#fff",
            }}
            onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
          ></div>
          {isColorPickerOpen && (
            <ColorPicker
              title="Background"
              value={component.attributes.styles.backgroundColor ?? "#fff"}
              onChange={(val) => {
                updateStructureComponent(
                  id,
                  "attributes.styles.backgroundColor",
                  val
                );
              }}
              setColorPickerOpen={() => {
                (document.querySelector(
                  '.color-picker.show[data-bs-toggle="dropdown"]'
                ) as HTMLElement)!.click();
              }}
            />
          )}
        </div>
        <div>Hover:</div>
        <div className="b-ms-1 color-picker color-picker__swatch-item color-picker__swatch-item__parent position-relative dropup">
          <div
            className="color-picker color-picker__swatch-item position-relative"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            style={{
              margin: 0,
              backgroundColor:
                component.attributes.styles[":hover"]?.backgroundColor ?? "",
            }}
            onClick={() => setIsHoverColorPickerOpen(!isHoverColorPickerOpen)}
          ></div>

          {isHoverColorPickerOpen && (
            <ColorPicker
              title="Background"
              value={
                component.attributes.styles[":hover"]?.backgroundColor ?? ""
              }
              onChange={(val) => {
                updateStructureComponent(
                  id,
                  "attributes.styles.:hover.backgroundColor",
                  val
                );
              }}
              setColorPickerOpen={() => {
                (document.querySelector(
                  '.color-picker.show[data-bs-toggle="dropdown"]'
                ) as HTMLElement)!.click();
              }}
            />
          )}
        </div>
        <div>Duration:</div>
        <div className="background-menu_input">
          <input
            className="global-editor__input builder-input"
            size={2}
            onChange={(e) => {
              updateStructureComponent(
                id,
                "attributes.styles.transitionDuration",
                e.target.value +
                  extractUnits(
                    component.attributes.styles.transitionDuration ?? "300ms"
                  )
              );
              updateStructureComponent(
                id,
                "attributes.styles.transitionProperty",
                "background-color"
              );
            }}
            value={extractNumbers(
              component.attributes.styles.transitionDuration ?? "300ms"
            )}
          ></input>
          <Select
            isSearchable={false}
            menuPortalTarget={document.getElementById("react-select-container")}
            className="m-0"
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={transitionDurationUnits.find(
              (x) =>
                x.value ===
                extractUnits(
                  component.attributes.styles.transitionDuration ?? "300ms"
                )
            )}
            onChange={(val) => {
              updateStructureComponent(
                id,
                "attributes.styles.transitionDuration",
                extractNumbers(
                  component.attributes.styles.transitionDuration ?? "300ms"
                ) + val.value
              );
              updateStructureComponent(
                id,
                "attributes.styles.transitionProperty",
                "background-color"
              );
            }}
            options={transitionDurationUnits}
            placeholder=""
          ></Select>
        </div>
        <div>Effect:</div>
        <Select
          isSearchable={false}
          menuPortalTarget={document.getElementById("react-select-container")}
          className="m-0 background-menu_input"
          styles={TextEditorSelectStyle}
          noOptionsMessage={({}) => ""}
          value={transitionEffects.find(
            (x) =>
              x.value ===
              (component.attributes.styles.transitionTimingFunction ??
                "ease-in-out")
          )}
          onChange={(val) =>
            updateStructureComponent(
              id,
              "attributes.styles.transitionTimingFunction",
              val.value
            )
          }
          options={transitionEffects}
          placeholder=""
        ></Select>

        {/* <div>Backdrop Blur:</div>

        <div className="border-menu_input">
          <div
            style={{
              fontSize: "14px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setBackdropBlurValue((prev) => prev - 1);
            }}
          >
            −
          </div>
          <div>
            <input
              className="global-editor__input builder-input"
              size={1}
              value={backdropBlurValue}
              onChange={(ev) => {
                console.log(ev.target.value);

                console.log("THIS TRIGGERED");

                updateStructureComponent(
                  id,
                  "attributes.styles.backdropBlur",
                  `blur(${ev.target.value} + "px")`
                );
              }}

              // value={extractNumbers(
              //   borders[
              //     "border" +
              //       capFirstLetter(borderPosRadiusMap[position]) +
              //       "Radius"
              //   ]
              // )}
              // onChange={(ev) => {
              //   if (ev.target.value !== "") {
              //     setValueByProp(
              //       borderPosRadiusMap[position],
              //       "Radius",
              //       ev.target.value +
              //         extractUnits(
              //           borders[
              //             "border" +
              //               capFirstLetter(borderPosRadiusMap[position]) +
              //               "Radius"
              //           ]
              //         )
              //     );
              //   }
              // }}
            />
          </div>
          <div
            style={{
              fontSize: "14px",
            }}
            onClick={(e) => {
              e.stopPropagation();

              setBackdropBlurValue((prev) => prev + 1);

              // updateComponentStyle(id, {
              //   backdropFilter: "blur(" + backDropBlur + 1 + "px)",
              // });
            }}
          >
            +
          </div>
        </div>

        <div>Sticky:</div> */}

        {fileExplorerOpen && (
          <FileExplorer
            setFileExplorerOpen={setFileExplorerOpen}
            onSelect={setBackground}
            id={id}
          />
        )}
        <div className="text-end flex-grow-1 b-ms-1 position-relative">
          <MoreHorizOutlined
            data-bs-toggle="dropdown"
            style={{ fontSize: "18px" }}
            onClick={() => {
              setShowDropdown(!showDropdown);
            }}
          />
          <ul
            className={
              "dropdown-menu b-dropdown-menu dropdown-menu-end builder-dropdown " +
              (showDropdown ? "show" : "")
            }
          >
            <li>
              <span
                className="dropdown-item builder-dropdown__item"
                onClick={() => setFileExplorerOpen(true)}
              >
                + Add image
              </span>
            </li>
            <li>
              <span
                className="dropdown-item builder-dropdown__item"
                onClick={() => setFileExplorerOpen(true)}
              >
                + Add video
              </span>
            </li>
          </ul>
        </div>
      </div>
      {backgrounds.map((background, i) => (
        <div
          className={
            "d-flex align-items-start b-mb-1" + (i == 0 ? " b-mt-1" : "")
          }
          key={background.url}
        >
          <div className="background-item b-mx-1">
            <img src={background.url}></img>
          </div>
          <div className="d-flex w-100">
            <div className="d-flex flex-row flex-wrap">
              <div className="background-item-option">
                <div
                  className="clickable background-menu_input"
                  onClick={() =>
                    setIsBackgroundRepeatOpen(!isBackgroundRepeatOpen)
                  }
                >
                  {repeats.find((x) => x.value === background.repeat)!.label}
                  <ArrowDropDownIcon
                    style={{ marginLeft: "-2px", marginTop: "-1px" }}
                  />
                </div>

                {isBackgroundRepeatOpen && (
                  <ul className="background-menu-dropdown">
                    {repeats.map((x) => (
                      <li
                        onClick={() =>
                          setBackgroundPropByUrl(
                            background.url,
                            "repeat",
                            x.value
                          )
                        }
                      >
                        <span
                          className={
                            "dropdown-item builder-dropdown__item" +
                            (x.value === background.repeat
                              ? " builder-dropdown__item--active"
                              : "")
                          }
                        >
                          {x.label}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="background-item-option">
                <div
                  className="clickable background-menu_input"
                  onClick={() =>
                    setIsBackgroundPositionOpen(!isBackgroundPositionOpen)
                  }
                >
                  {
                    positions.find((x) => x.value === background.position)!
                      .label
                  }
                  <ArrowDropDownIcon
                    style={{ marginLeft: "-2px", marginTop: "-1px" }}
                  />
                </div>

                {isBackgroundPositionOpen && (
                  <ul className="background-menu-dropdown">
                    <div className="d-grid background-grid-container">
                      {positions.map((x) => (
                        <div
                          key={x.value}
                          className={
                            "background-grid-item" +
                            (background.position === x.value
                              ? " background-grid-item--active"
                              : "")
                          }
                          onClick={() => {
                            setBackgroundPropByUrl(
                              background.url,
                              "position",
                              x.value
                            );
                          }}
                        >
                          {x.label}
                        </div>
                      ))}
                    </div>
                  </ul>
                )}
              </div>
              <div className="background-item-option ">
                <div className="background-menu_input">
                  {renderBackgroundSizeOption(
                    background.url,
                    background.size,
                    getSelectedBackgroundSize(background.size)
                  )}
                  <ArrowDropDownIcon
                    style={{ marginLeft: "-2px", marginTop: "-1px" }}
                    onClick={() =>
                      setIsBackgroundSizeOpen(!isBackgroundSizeOpen)
                    }
                  />
                </div>

                {isBackgroundSizeOpen && (
                  <ul className="background-menu-dropdown">
                    {sizes.map((x) => (
                      <li
                        key={x}
                        onClick={() => {
                          if (getSelectedBackgroundSize(background.size) != x) {
                            setBackgroundPropByUrl(background.url, "size", x);
                          }
                        }}
                      >
                        <span
                          className={
                            "dropdown-item builder-dropdown__item" +
                            (getSelectedBackgroundSize(background.size) === x
                              ? " builder-dropdown__item--active"
                              : "")
                          }
                        >
                          {x}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="background-item-option ">
                <div
                  className="clickable background-menu_input"
                  onClick={() =>
                    setIsBackgroundOriginOpen(!isBackgroundOriginOpen)
                  }
                >
                  {origins.find((x) => x === background.origin)}
                  <ArrowDropDownIcon
                    style={{ marginLeft: "-2px", marginTop: "-1px" }}
                  />
                </div>

                {isBackgroundOriginOpen && (
                  <ul className="background-menu-dropdown">
                    {origins.map((x) => (
                      <li
                        onClick={() =>
                          setBackgroundPropByUrl(background.url, "origin", x)
                        }
                      >
                        <span
                          className={
                            "dropdown-item builder-dropdown__item" +
                            (x === background.origin
                              ? " builder-dropdown__item--active"
                              : "")
                          }
                        >
                          {x}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="background-item-option">
                <div
                  className="clickable background-menu_input"
                  onClick={() =>
                    setIsBackgroundAttachmentOpen(!isBackgroundAttachmentOpen)
                  }
                >
                  {
                    attachments.find((x) => x.value === background.attachment)!
                      .label
                  }
                  <ArrowDropDownIcon
                    style={{ marginLeft: "-2px", marginTop: "-1px" }}
                  />
                </div>

                {isBackgroundAttachmentOpen && (
                  <ul className="background-menu-dropdown">
                    {attachments.map((x) => (
                      <li
                        onClick={() =>
                          setBackgroundPropByUrl(
                            background.url,
                            "attachment",
                            x.value
                          )
                        }
                      >
                        <span
                          className={
                            "dropdown-item builder-dropdown__item" +
                            (x.value === background.attachment
                              ? " builder-dropdown__item--active"
                              : "")
                          }
                        >
                          {x.label}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="flex-grow-1 text-end">
              <DeleteIcon
                className="clickable"
                onClick={() => {
                  setConfirmation(true);
                  setDeleteIndex(i);
                }}
                style={{ fontSize: "18px" }}
              />
            </div>
          </div>
          {confirmation && (
            <Confirmation
              id={id.toString()}
              onCancel={() => {
                setConfirmation(false);
                setDeleteIndex(null);
              }}
              onConfirm={() => {
                deleteBackground(deleteIndex!);
                setConfirmation(false);
                setDeleteIndex(null);
              }}
              text="Are you sure you want to remove this picture?"
            />
          )}
        </div>
      ))}
    </>
  );
}
