import { useState } from "react";
import TemplateDetailsGeneralEditForm from "./TemplateDetailsGeneralEditForm";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Template } from "../../../../../store/reducers/builderReducers/templates/templateApi";
import { getTemplateCompType } from "../../../../../utils/Helpers";
import { Pages } from "../../../../../store/reducers/builderReducers/pages/pagesApi";

interface Props {
  template: Template;
  setEditTemplate: any;
  pages: Pages["list"];
}

export default function TemplateDetails({
  template,
  setEditTemplate,
  pages,
}: Props) {
  const [generalEdit, setGeneralEdit] = useState(false);

  console.log(template);

  function getTemplateTypeLabel() {
    switch (getTemplateCompType(template.component)) {
      case "col":
        return "Column";
      case "row":
        return "Row";
      default:
        return "Element";
    }
  }

  return (
    <div className="b-container">
      <div className="b-row align-items-start">
        <div className="col-2">
          <div
            className="back-btn d-flex align-items-center justify-content-center"
            onClick={() => setEditTemplate(null)}
          >
            <ChevronLeftIcon />
          </div>
        </div>
        <div className="col-8 d-flex flex-column">
          <div className="fw-bold">Template: {template.name}</div>
          <div className="fs-small">{getTemplateTypeLabel()}</div>
          {template.use ? (
            <div className="fs-small">Use: {template.use}</div>
          ) : (
            ""
          )}
        </div>
        {!generalEdit && (
          <div className="col-2 text-end">
            <a
              className="templates-button"
              onClick={() => setGeneralEdit(true)}
            >
              Edit
            </a>
          </div>
        )}
      </div>
      {!generalEdit && (
        <div className="b-row b-mt-3">
          <div className="col-12 fw-bold">Used in pages</div>
          {template.webpages
            .sort((a, b) =>
              pages
                .find((x) => x.id === a)!
                .name.toLowerCase()
                .localeCompare(
                  pages.find((x) => x.id === b)!.name.toLowerCase()
                )
            )
            .map((x) => (
              <div key={x} className="col-12">
                {pages?.find((y) => y.id === x)?.name}
              </div>
            ))}
        </div>
      )}
      {generalEdit && (
        <TemplateDetailsGeneralEditForm
          setGeneralEdit={setGeneralEdit}
          template={template}
          setEditTemplate={setEditTemplate}
        />
      )}
    </div>
  );
}
