import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TypographiesAPI from "../../../../../store/reducers/builderReducers/typographies/typographiesApi";
import { Typography } from "../../../../../store/reducers/builderReducers/typographies/typographiesApi";

import { Edit } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import DeleteConfirmModal from "../../../Modal/DeleteConfirmModal";

interface Props {
  typography: Typography;
  setFormModes: (obj: {}) => void;
}

export default function TypographyView({ typography, setFormModes }: Props) {
  return (
    <div className="col-12 b-py-2 typography-item">
      <label>
        {typography.name} ({typography.tag})
      </label>
      <div className="float-end">
        <ul className="typography-menu">
          <li>
            <span
              className="typography-addon-button"
              onClick={() =>
                setFormModes({
                  typography: { editing: true, id: typography.id },
                })
              }
            >
              <Edit style={{ fontSize: "15px" }} />
            </span>
          </li>
          <li>
            <DeleteConfirmModal
              onConfirm={() =>
                TypographiesAPI.deleteTypographyById(typography.id)
              }
            />

            {/* <span
              className="typography-addon-button"
              onClick={() =>
                TypographiesAPI.deleteTypographyById(typography.id)
              }
            >
              <Delete style={{ fontSize: "16px" }} />
            </span> */}
          </li>
        </ul>
      </div>
    </div>
  );
}
