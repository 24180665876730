import React, { useState } from "react";
import Select from "react-select";
import PagesAPI from "../../../../../store/reducers/builderReducers/pages/pagesApi";
import { DefaultReactSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { Pages } from "../../../../../store/reducers/builderReducers/pages/pagesApi";

interface Props {
  page: Pages["list"][0];
  languages: Pages["list"][0]["language"][];
  handleEdit: (obj: {}) => void;
  webpages: Pages["list"];
}

export default function PageDetailsLanguagesEditForm({
  page,
  languages,
  handleEdit,
  webpages,
}: Props) {
  const [cachedPage, setCachedPage] = useState(page);
  const primaryLanguageId = languages[0].id ?? "";

  const dot = (image = "none") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      background: image,
      content: '" "',
      display: "block",
      marginRight: 5,
      height: 20,
      width: 20,
      backgroundSize: "20px 20px",
    },
  });

  const reactSelectStyle = {
    ...DefaultReactSelectStyle,
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
      ...dot("url(" + data.icon + ")"),
      color: "#fff",
    }),
    option: (styles: any, data: any) => {
      return {
        ...DefaultReactSelectStyle.option!(styles, data),
        ...dot("url(" + data.data.icon + ")"),
      };
    },
  };

  let languageOptions: any = [];
  if (page.language) {
    let lang = cachedPage.language;
    languageOptions.push({ ...lang, label: lang.name, value: lang.id });
  } else {
    languages.forEach((lang) => {
      languageOptions.push({ ...lang, label: lang.name, value: lang.id });
    });
  }
  let webpagesOptions: any = [];
  webpages.forEach((webpage) => {
    webpagesOptions.push({
      ...webpage,
      label: webpage.name,
      value: webpage.id,
    });
  });

  function changePageLanguageWebpage(value: any, lang_id: number) {
    if (
      cachedPage.language_webpages.find(
        (x) => x.website_language_id === lang_id
      )
    ) {
      let changedLanguage_webpages = cachedPage.language_webpages.map((x) =>
        x.website_language_id === lang_id ? value : x
      );
      setCachedPage({
        ...cachedPage,
        language_webpages: changedLanguage_webpages,
      });
    } else {
      setCachedPage({
        ...cachedPage,
        language_webpages: [...cachedPage.language_webpages, value],
      });
    }
  }

  function changePageLanguage(val: any) {
    setCachedPage({
      ...cachedPage,
      language: val,
      website_language_id: val.id,
    });
  }

  function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    PagesAPI.saveWebpageById(page.id, {
      ...page,
      website_language_id: cachedPage.website_language_id,
      language_webpages: cachedPage.language_webpages,
    }).then(() => {
      handleEdit({ languages: false });
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row b-mb-3">
        <div className="col-12 b-pb-1 fw-bold">Languages</div>
      </div>
      <div className="b-row b-mb-3">
        <div className="col-12 fs-small b-mb-2">Page language</div>
        <div className="col-12">
          <Select
            isSearchable={false}
            options={languageOptions}
            value={languageOptions.find((x) =>
              page.language ? x.id === page.language.id : ""
            )}
            onChange={(val) => changePageLanguage(val)}
            styles={reactSelectStyle}
          ></Select>
        </div>
      </div>
      {/*languages
        .filter((x) => !page.language || x.id != page.language.id)
        .map((lang, index) => {
          let languagePage = cachedPage.language_webpages.find((x) => x.website_language_id === lang.id)
          return (
            <div key={lang.id} className='b-row b-mb-3'>
              <div className='col-12 fs-small b-mb-2'>The same page in {lang.name}</div>
              <div className='col-12 b-mb-2'>
                <Select
                  isSearchable={false}
                  options={webpagesOptions.slice(0).filter((x) => {
                    x.label = x.name
                    x.value = x.id
                    return x.website_language_id === lang.id
                  })}
                  value={webpagesOptions.find((x) => (languagePage ? languagePage.id === x.id : ""))}
                  onChange={(val) => changePageLanguageWebpage(val, lang.id)}
                  styles={DefaultReactSelectStyle}
                ></Select>
                {/* <input className="builder-input form-input" value={lang.name} onChange={(evt) => setPageNameById(evt.target.value, lang.id)} />}
              </div>
            </div>
          )
        })*/}
      <div className="b-my-3">
        <button className="sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => handleEdit({ languages: false })}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
