import { updateStructureComponent } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { useAppSelector } from "../../../../../store/hooks";
import Select from "react-select";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { MoreHorizOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";

export default function ColMenu({
  id,
  triggerResize,
}: {
  id: number;
  triggerResize: () => void;
}) {
  console.log("ColMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )!;
  // Expand if more than 1 bootstrap class active
  const [expanded, setExpanded] = useState(
    component.attributes.classes.split(" ").filter((x) => x.includes("col-"))
      .length > 1
  );
  const cols = [
    { label: "1/12", value: "1" },
    { label: "1/6", value: "2" },
    { label: "1/4", value: "3" },
    { label: "1/3", value: "4" },
    { label: "5/12", value: "5" },
    { label: "1/2", value: "6" },
    { label: "7/12", value: "7" },
    { label: "2/3", value: "8" },
    { label: "3/4", value: "9" },
    { label: "5/6", value: "10" },
    { label: "11/12", value: "11" },
    { label: "12/12", value: "12" },
  ];
  const sizes = [
    { label: "XS", pixels: "(0<)", value: "col-" },
    { label: "SM", pixels: "(576<)", value: "col-sm-" },
    { label: "MD", pixels: "(768<)", value: "col-md-" },
    { label: "LG", pixels: "(992<)", value: "col-lg-" },
    { label: "XL", pixels: "(1200<)", value: "col-xl-" },
  ];

  const alignments = [
    { label: "Left", value: "left" },
    { label: "Center", value: "center" },
    { label: "Right", value: "right" },
  ];

  function addRemoveClass(size: string, number: string) {
    let classes = component.attributes.classes.split(" ");
    classes = classes.filter((x) => !x.match(`${size}\\d+`));
    // If class previously existed remove it from list
    if (!component.attributes.classes.split(" ").includes(size + number)) {
      classes.push(size + number);
    }
    updateStructureComponent(id, "attributes.classes", classes.join(" "));
  }

  function renderRow(size: (typeof sizes)[0]) {
    return (
      <>
        <div className="text-end" style={{ width: "17px" }}>
          {size.label}
        </div>
        <div className="text-end" style={{ width: "45px" }}>
          {size.pixels}
        </div>
        {cols.map((y) => (
          <div
            key={y.value}
            className={
              "builder-column builder-column__item" +
              (component.attributes.classes
                .split(" ")
                .includes(size.value + y.value)
                ? " builder-column__item--active"
                : "")
            }
            onClick={() => addRemoveClass(size.value, y.value)}
          >
            {y.label}
          </div>
        ))}
      </>
    );
  }

  return (
    <div className="overflow-auto">
      <div className="builder-column builder-column__container">
        <div className="builder-column builder-column__row">
          {renderRow(sizes[0])}
          <Select
            menuPosition="fixed"
            className="b-mx-1"
            isSearchable={false}
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={alignments.find(
              (x) =>
                x.value === (component.attributes.styles.textAlign ?? "left")
            )}
            onChange={(val) => {
              updateStructureComponent(
                id,
                "attributes.styles.textAlign",
                val.value
              );
            }}
            options={alignments}
            placeholder=""
          ></Select>
          <div className="flex-grow-1 text-end">
            <MoreHorizOutlined
              style={{ height: "16px", width: "19px" }}
              onClick={() => {
                setExpanded(!expanded);
                triggerResize();
              }}
            />
          </div>
        </div>
        {expanded &&
          sizes.slice(1).map((size) => (
            <div
              className="builder-column builder-column__row"
              key={size.value}
            >
              {renderRow(size)}
            </div>
          ))}
      </div>
    </div>
  );
}
