import React, { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import FolderIcon from '@mui/icons-material/Folder'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import DocumentsAPI from '../../../../../../store/reducers/builderReducers/documents/documentsApi'
import DragAndDropUpload from '../../../../DragAndDropUpload/DragAndDropUpload'
import { useAppSelector } from '../../../../../../store/hooks'
import { Document } from '../../../../../../store/reducers/builderReducers/documents/documentsApi'

export default function FileExplorer({
  setFileExplorerOpen,
  onSelect,
  id,
}: {
  setFileExplorerOpen: Dispatch<SetStateAction<boolean>>
  onSelect: (item: Document) => void
  id: number
}) {
  console.log('FileExplorer')
  const [link, setLink] = useState('')
  const [view, setView] = useState('upload')
  React.useEffect(() => {
    DocumentsAPI.loadDocumentsTree()
  }, [])
  const docStruc = useAppSelector((state) => state.builder.documents)
  const [preview, setPreview] = useState<null | Document>(null)
  const [activeNavId, setActiveNavId] = useState<number | null>(null)
  const [filename, setFilename] = useState<string>('')
  const component = useAppSelector((state) => state.builder.structure.components[id])!
  const styles = component.attributes.styles
  const website = useAppSelector((state) => state.builder.website)

  function handleItemClick(item: Document) {
    console.log(item)
    if (item.type === 'directory') {
      setActiveNavId(item.id)
    } else if (styles.backgroundImage?.includes(item.url)) {
      return
    } else {
      if (styles.backgroundImage?.split(',')?.length === 4) {
        return
      }
      setPreview(item)
      setView('preview')
    }
  }

  function handleBreadcrumbClick(id: number) {
    setActiveNavId(id)
  }

  function findFolderRecursive(struc: Document[], id: number | null): Document[] | null {
    if (!id) return struc // When no folder is selected

    let folders = struc.filter((x) => x.type === 'directory')
    for (let i = 0; i < folders.length; i++) {
      if (folders[i].id === id) {
        return folders[i].childElements
      } else if (folders[i].type === 'directory') {
        let item = findFolderRecursive(folders[i].childElements, id)
        if (item) {
          return item
        }
      }
    }

    return null
  }

  function getFolderNameRecursive(
    struc: Document[],
    id: number | null,
    breadcrumb_: Array<{ name: string; id?: number }> = [{ name: website!.name }]
  ): Array<{ name: string; id?: number }> | null {
    if (!id) return breadcrumb_ // When no folder is selected

    let folders = struc.filter((x) => x.type === 'directory')
    for (let i = 0; i < folders.length; i++) {
      if (folders[i].id === id) return [...breadcrumb_, { id: folders[i].id, name: folders[i].originalname }]
      else if (folders[i].childElements.length) {
        let fold = getFolderNameRecursive(folders[i].childElements, id, [...breadcrumb_, { id: folders[i].id, name: folders[i].originalname }])
        if (fold) return fold
      }
    }
    return null
  }

  function uploadFileFromUrl() {
    DocumentsAPI.addReferenceFile({ path: link, originalname: filename }).then((data) => {
      if (data.addedCustomFiles.length) {
        setPreview(data.addedCustomFiles[0])
        setView('preview')
      }
    })
  }

  function uploadFile(e: any) {
    DocumentsAPI.uploadDocuments(e.target.files).then((data) => {
      if (data.addedCustomFiles.length) {
        setPreview(data.addedCustomFiles[0])
        setView('preview')
      }
      // for (let i = 0; i < files.length; i++) {
      //   if (files[i].originalname === e.target.files[0].name) {
      //     setPreview(files[i])
      //     setView('preview')
      //     return
      //   }
      // }
    })
  }

  return (
    <div className='file-explorer file-explorer__container'>
      <div className='b-row'>
        {view !== 'preview' && (
          <div className='col-12 b-mb-3 file-locations'>
            <div
              className={'file-locations file-locations__item ' + (view === 'upload' ? 'file-locations__item--active' : '')}
              onClick={() => setView('upload')}>
              + New file
            </div>
            <div
              className={'file-locations file-locations__item ' + (view === 'directory' ? 'file-locations__item--active' : '')}
              onClick={() => setView('directory')}>
              Select from folder
            </div>
          </div>
        )}

        {view === 'upload' && (
          <>
            <div className='col-12 b-mb-3'>
              <DragAndDropUpload icon='cloud' onChange={uploadFile} />
            </div>
            <div className='col-12 b-mb-3'>
              <div className='fs-small'>Link to file</div>
              <input className='form-input builder-input' value={link} onChange={(ev) => setLink(ev.target.value)} />
            </div>
            {link != '' && (
              <div className='col-12 b-mb-3'>
                <div className='fs-small'>Name</div>
                <input className='form-input builder-input' value={filename} onChange={(e) => setFilename(e.target.value)} />
              </div>
            )}
            <div className='col-12'>
              {link != '' && (
                <button className='b-btn btn-primary w-100' onClick={() => uploadFileFromUrl()}>
                  Save
                </button>
              )}
              {link == '' && (
                <button className='b-btn btn-primary w-100' onClick={() => setFileExplorerOpen(false)}>
                  Back
                </button>
              )}
            </div>
          </>
        )}

        {view === 'directory' && (
          <>
            <div className='col-12 b-mb-2 b-fs-5'>
              {getFolderNameRecursive(docStruc.toArray(), activeNavId)?.map((x, i) => (
                <>
                  {i != 0 && ' > '}
                  <span className='file-explorer file-explorer__breadcrumb' onClick={() => handleBreadcrumbClick(x.id!)}>
                    {x.name}
                  </span>
                </>
              ))}
            </div>
            <div className='file-explorer file-explorer__items'>
              {findFolderRecursive(docStruc.toArray(), activeNavId)?.map((x) => (
                <div key={x.id} className='col-12 b-py-2 d-flex file-explorer__items__item' onClick={() => handleItemClick(x)}>
                  <div className='file-explorer__items__item__icon'>
                    {x.type === 'directory' && <FolderIcon />}
                    {['files', 'reference'].includes(x.type) && <InsertDriveFileOutlinedIcon />}
                    {/* TODO: add image preview */}
                  </div>
                  <div className='file-explorer__items__item__name'>{x.originalname}</div>
                </div>
              ))}
            </div>
            <div className='col-12 b-pt-3'>
              <button
                className='b-btn btn-primary w-100'
                onClick={() => {
                  setFileExplorerOpen(false)
                }}>
                Back
              </button>
            </div>
          </>
        )}

        {view === 'preview' && preview && (
          <>
            <div className='col-12 b-mb-2'>
              {preview.extension !== 'mp4' && <img src={preview.type == 'reference' ? preview.path : preview.url} className='file-explorer file-explorer__picture-preview' />}
              {preview.extension === 'mp4' && (
                <video autoPlay loop controls className='file-explorer file-explorer__picture-preview'>
                  <source src={preview.url} type='video/mp4' />
                </video>
              )}
              <div className='text-center b-mt-1'>{preview.originalname}</div>
            </div>
            <div className='col-6 b-pe-1'>
              <button
                className='b-btn btn-primary w-100'
                onClick={() => {
                  setView('directory')
                  setPreview(null)
                }}>
                Back
              </button>
            </div>
            <div className='col-6 b-ps-1'>
              <button
                className='b-btn btn-primary w-100'
                onClick={() => {
                  onSelect(preview)
                  setFileExplorerOpen(false)
                }}>
                Select
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
