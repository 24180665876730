import React, { useState } from 'react'
import LanguagesActiveFormEdit from './LanguagesActiveFormEdit'
import LanguagesActiveFormView from './LanguagesActiveFormView'
import LanguagesSettingsFormEdit from './LanguagesSettingsFormEdit'
import LanguagesSettingsFormView from './LanguagesSettingsFormView'
import LanguageSettingsAPI from '../../../../../store/reducers/builderReducers/languageSettings/languageSettingsApi'
import { useAppSelector } from '../../../../../store/hooks'

export default function Languages() {
  React.useEffect(() => {
    LanguageSettingsAPI.loadLanguageSettings()
  }, [])
  const languageSetting = useAppSelector((state) => state.builder.languageSettings)

  const [formEditModes, setFormEditModes] = useState({
    languagesActive: false,
    settings: false,
  })

  function setFormModes(newMode: {}) {
    setFormEditModes((modes) => ({
      ...modes,
      ...newMode,
    }))
  }

  return (
    <div className='b-container'>
      {formEditModes.languagesActive ? (
        <LanguagesActiveFormEdit handleEdit={setFormModes} languageSetting={languageSetting} />
      ) : (
        <LanguagesActiveFormView handleEdit={setFormModes} languageSetting={languageSetting} />
      )}
      {formEditModes.settings ? (
        <LanguagesSettingsFormEdit handleEdit={setFormModes} languageSetting={languageSetting} />
      ) : (
        <LanguagesSettingsFormView handleEdit={setFormModes} languageSetting={languageSetting} />
      )}
    </div>
  )
}
