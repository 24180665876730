import { useState } from 'react'
import { DeliveryMethod, useShoppingCart } from '../context/ShoppingCartContext'
import CheckoutPaymentMethodButton from './CheckoutPaymentMethodButton'
import WestIcon from '@mui/icons-material/West'
import CheckoutConfirmModal from './CheckoutConfirmModal'
import CheckoutSelectDeliveryMethodModal from './CheckoutSelectDeliveryMethodModal'
import CheckoutSelectPaymentMethodModal from './CheckoutSelectPaymentMethodModal'

interface Props {
  setShowBuyerModal: (val: boolean) => void
  setModalDeliveryMethod: (val: DeliveryMethod | null) => void
  setShowDeliveryModal: (val: boolean) => void
}

export default function CheckoutPaymentPanel({ setShowBuyerModal, setModalDeliveryMethod, setShowDeliveryModal }: Props) {
  const [showCheckoutConfirmModal, setShowCheckoutConfirmModal] = useState(false)
  const [showSelectDeliveryMethodModal, setShowSelectDeliveryMethodModal] = useState(false)
  const [showSelectPaymentMethodModal, setShowSelectPaymentMethodModal] = useState(false)
  const { paymentMethods, orderPaymentMethod, orderDeliveryMethod, setOrderPaymentMethod, buyer } = useShoppingCart()
  console.log(orderPaymentMethod)

  const confirmClick = () => {
    if (!buyer || !buyer.firstname) {
      setShowBuyerModal(true)
      return false
    }
    if (!orderDeliveryMethod || !orderDeliveryMethod.id) {
      setShowSelectDeliveryMethodModal(true)
      return false
    }
    if (!orderPaymentMethod || !orderPaymentMethod.id) {
      setShowSelectPaymentMethodModal(true)
      return false
    }
    setShowCheckoutConfirmModal(true)
  }
  return (
    <div className='panel' id='checkoutPaymentmethodPanel'>
      <div className='panel-heading'>
        <div className='panel-title'>
          <span className='badge badge-square badge-primary gifticum-panel-title-number'>4</span>
          <span className='gifticum-panel-title-text'>MAKSEVIIS</span>
        </div>
      </div>
      <div className='panel-body'>
        {!orderPaymentMethod || !orderPaymentMethod.id ? (
          <div className='paymentMethodNotSelected'>
            {paymentMethods ? paymentMethods.map((pm) => <CheckoutPaymentMethodButton key={pm.id} paymentMethod={pm} />) : ''}
          </div>
        ) : (
          <div className='row paymentMethodSelected'>
            <div className='col-xs-12'>
              <button className='btn btn-pure backToPaymentMethodSelect' onClick={() => setOrderPaymentMethod(null)}>
                <WestIcon className='icon' style={{ fontSize: '14px' }} /> <span> Tagasi makseviisi valikusse</span>
              </button>
            </div>
            <div className='col-xs-12 selectedPaymentMethodButtonConainer'>
              <CheckoutPaymentMethodButton key={orderPaymentMethod.id} paymentMethod={orderPaymentMethod} />
            </div>
            <div className='col-xs-12' style={{fontSize: '15px'}}>Kohe pärast tellimuse kinnitamist ja tingimustega nõustumist suunatakse edasi internetipanka makset sooritama.</div>
          </div>
        )}
      </div>
      <div className='panel-footer text-center'>
        <button className='btn btn-primary checkoutConfirmOrderButton' onClick={confirmClick}>
          KINNITA TELLIMUS
        </button>
      </div>
      {showCheckoutConfirmModal && <CheckoutConfirmModal show={showCheckoutConfirmModal} setShow={setShowCheckoutConfirmModal} />}
      {showSelectDeliveryMethodModal && (
        <CheckoutSelectDeliveryMethodModal
          show={showSelectDeliveryMethodModal}
          setShow={setShowSelectDeliveryMethodModal}
          setModalDeliveryMethod={setModalDeliveryMethod}
          setShowDeliveryModal={setShowDeliveryModal}
        />
      )}
      {showSelectPaymentMethodModal && <CheckoutSelectPaymentMethodModal show={showSelectPaymentMethodModal} setShow={setShowSelectPaymentMethodModal} />}
    </div>
  )
}
