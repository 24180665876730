import {
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
  fetchApiDelete,
} from "../../../../api/api";
import { AllComponents } from "../../../../components/_default/interfaces/base";
import { updateTemplates, addNewTemplate } from "./templateActions";

export interface Template {
  id: number;
  website_id: number;
  name: string;
  type: string;
  use: string;

  visible: boolean;

  webpage_component_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  webpages: number[];
  component: AllComponents | null;
}

const TemplateAPI = {
  loadTemplates,
  addTemplate,
  saveTemplateById,
  deleteTemplate,
};
export default TemplateAPI;

export function loadTemplates() {
  return fetchApiGet<Array<Template>>(`/templates`, {}).then((res) =>
    updateTemplates(res)
  );
}

export function addTemplate(data: { template: string; name: string }) {
  return fetchApiPost<Array<Template>[0]>(`/templates`, data).then((d) =>
    addNewTemplate(d)
  );
}

function deleteTemplate(template_id: number) {
  return fetchApiDelete<Array<Template>>(`/templates/${template_id}`, {}).then(
    (res) => {
      updateTemplates(res);
    }
  );
}

function saveTemplateById(id: number, data: Template) {
  console.log(data);

  return fetchApiPut<Array<Template>>(`/templates/${id}`, data).then((res) => {
    let result = res;

    if (data.use === "cookiePolicy") {
      const findIndex = result.findIndex(
        (x) => x.webpage_component_id === data.webpage_component_id
      );

      result[findIndex].visible = data.visible;
      //@ts-ignore
      result[findIndex].webpage_component.type = "cookiePolicy";
    }

    console.log(result);

    updateTemplates(result);
  });
}
