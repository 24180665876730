import { useShoppingCart } from "../context/ShoppingCartContext"
import { pf } from "../utilities/helpers"


export default function CheckoutProductsPanelConfirmed() {
  const { locationProducts, cartItemsConfirmed } = useShoppingCart()

  return (
    <div className='panel' id='checkoutProductsPanel'>
      <div className='panel-heading'>
        <div className='panel-title'>
          <span className='badge badge-square badge-primary gifticum-panel-title-number'>1</span>
          <span className='gifticum-panel-title-text'>TOOTED</span>
        </div>
      </div>
      <div className='panel-body'>
        <div className='row'>
          <div className='col-xs-12 padding-top-20'>
            <table className='table tablerow-no-border cartTable'>
              <tbody>
                {cartItemsConfirmed.map((item) => {
                  const locationProduct = locationProducts.find((lp) => lp.id === item.id)
                  if (locationProduct == null) return null
                  return (
                    <tr key={item.id} className='cartOrderProductRow'>
                      <td className='cartOrderProductRowImage d-none d-sm-table-cell'>
                        <img src={locationProduct.firstFileUrl} className='cartSidepanelProductImage' />
                      </td>
                      <td className='cartOrderProductRowName'>{locationProduct.display_name}</td>
                      <td className='cartOrderProductQuantity'>
                        {item.quantity} {locationProduct.unit_shortname}
                      </td>
                      <td className='cartOrderProductRowPrice'>{pf(locationProduct.price_total * item.quantity)} &euro;</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='col-xs-12 padding-top-20'>
            <h4>
              KOKKU{' '}
              <span className='pull-right'>
                <span className='cartTotal'>
                  {pf(
                    cartItemsConfirmed.reduce((total, cartItem) => {
                      const item = locationProducts.find((i) => i.id === cartItem.id)
                      return total + (item?.price_total || 0) * cartItem.quantity
                    }, 0)
                  )}
                </span>{' '}
                &euro;
              </span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}
