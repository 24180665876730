import React, { useState } from "react";
import { Website } from "../../../../../store/reducers/builderReducers/website/websiteApi";
import Select from "react-select";
import { DefaultReactSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import WebsiteAPI from "../../../../../store/reducers/builderReducers/website/websiteApi";

interface Props {
  handleEdit: (form: {}) => void;
  website: Website;
}

export default function WebsiteParametersFormEdit({
  handleEdit,
  website,
}: Props) {
  const [cachedWebsiteParameters, setCachedWebsiteParameters] = useState({
    content_width_size: website!.content_width_size,
    content_width_unit: website!.content_width_unit,
  });

  function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    WebsiteAPI.saveWebsite({ ...website!, ...cachedWebsiteParameters }).then(
      () => {
        handleEdit(false);
      }
    );
  }

  const DefaultReactSelectStyle_ = {
    ...DefaultReactSelectStyle,
    valueContainer: (styles: any) => {
      return {
        ...styles,
        paddingLeft: "0px",
        paddingRight: "0px",
      };
    },
  };

  const contentWidthUnits = [
    { label: "px", value: "px" },
    { label: "%", value: "%" },
  ];

  function setContentWidthSize(newValue: number) {
    setCachedWebsiteParameters({
      ...cachedWebsiteParameters,
      content_width_size:
        cachedWebsiteParameters.content_width_unit === "%" && newValue > 100
          ? 100
          : newValue,
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="b-row b-mt-4">
        <div className="col-12 fw-bold">Page builder parameters</div>
      </div>
      <div className="b-row b-mt-3">
        <div className="col-4 b-mt-4">
          <label className="fs-small">Content width</label>
          <div className="input-group">
            <input
              className="builder-input"
              value={cachedWebsiteParameters.content_width_size}
              onChange={(evt) => setContentWidthSize(evt.target.value as any)}
              style={{ width: "39%" }}
            />
            <div style={{ width: "61%" }}>
              <Select
                isSearchable={false}
                options={contentWidthUnits}
                value={contentWidthUnits.find(
                  (x) => x.value === cachedWebsiteParameters.content_width_unit
                )}
                onChange={(evt) =>
                  setCachedWebsiteParameters({
                    ...cachedWebsiteParameters,
                    content_width_unit: evt!.value,
                  })
                }
                styles={DefaultReactSelectStyle_}
              ></Select>
            </div>
          </div>
        </div>
      </div>
      <div className="b-mt-3">
        <button className="sidebar-save-btn" type="submit">
          Save
        </button>
        <a
          className="b-px-3 hover-underline sidebar-cancel-btn"
          onClick={() => handleEdit({ parameters: false })}
        >
          Cancel
        </a>
      </div>
    </form>
  );
}
