import { useState } from "react";
import Select from "react-select";
import { MoreHorizOutlined } from "@mui/icons-material";
import { updateComponentStyle } from "../../../../../store/reducers/builderReducers/structure/structureActions";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import { useAppSelector } from "../../../../../store/hooks";

interface Props {
  id: number;
  triggerResize: () => void;
}

export default function SpacingMenu({ id, triggerResize }: Props) {
  console.log("SpacingMenu");
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )!;
  const [expanded, setExpanded] = useState(true);
  const [itemEmpty, setItemEmpty] = useState<string[]>([]);

  const marginUnits = [
    { value: "px", label: "px" },
    { value: "em", label: "em" },
    { value: "rem", label: "rem" },
    { value: "%", label: "%" },
    { value: "auto", label: "auto" },
  ];
  const paddingUnits = [
    { value: "px", label: "px" },
    { value: "em", label: "em" },
    { value: "rem", label: "rem" },
    { value: "%", label: "%" },
  ];

  // TODO make better
  const spacing = {
    paddingLeft:
      component.attributes &&
      component.attributes.styles["paddingLeft"] !== undefined
        ? component.attributes.styles["paddingLeft"]
        : "0px",
    paddingRight:
      component.attributes &&
      component.attributes.styles["paddingRight"] !== undefined
        ? component.attributes.styles["paddingRight"]
        : "0px",
    paddingBottom:
      component.attributes &&
      component.attributes.styles["paddingBottom"] !== undefined
        ? component.attributes.styles["paddingBottom"]
        : "0px",
    paddingTop:
      component.attributes &&
      component.attributes.styles["paddingTop"] !== undefined
        ? component.attributes.styles["paddingTop"]
        : "0px",
    marginLeft:
      component.attributes &&
      component.attributes.styles["marginLeft"] !== undefined
        ? component.attributes.styles["marginLeft"]
        : "0px",
    marginRight:
      component.attributes &&
      component.attributes.styles["marginRight"] !== undefined
        ? component.attributes.styles["marginRight"]
        : "0px",
    marginBottom:
      component.attributes &&
      component.attributes.styles["marginBottom"] !== undefined
        ? component.attributes.styles["marginBottom"]
        : "0px",
    marginTop:
      component.attributes &&
      component.attributes.styles["marginTop"] !== undefined
        ? component.attributes.styles["marginTop"]
        : "0px",
  };

  return (
    <>
      <div
        className={
          "d-flex align-items-center b-gap-2 spacing-menu-row spacing-menu-items" +
          (expanded ? " b-pb-2" : "")
        }
      >
        {!expanded && (
          <>
            <div>Margin:</div>
            <div className="d-flex b-px-0">
              <span
                className="b-px-1"
                onClick={() => {
                  let newVal =
                    Number(
                      spacing.marginTop.toString().match(/(-\d+|\d+)/)![0]
                    ) -
                    1 +
                    marginUnits.find(
                      (x) =>
                        x.value ===
                        (spacing["marginTop"]
                          .toString()
                          .match("[A-Za-z%]+$")![0] ?? "px")
                    )!.value;
                  Object.entries(spacing).forEach(([key, value]) => {
                    if (key.includes("margin")) {
                      spacing[key as keyof typeof spacing] = newVal;
                      setItemEmpty((a) => a.filter((x) => x !== key));
                    }
                  });
                  updateComponentStyle(id, spacing);
                }}
              >
                {" "}
                -{" "}
              </span>
              <input
                className="global-editor__input builder-input"
                size={1}
                value={
                  itemEmpty.includes("marginTop")
                    ? ""
                    : spacing.marginTop.toString().match(/(-\d+|\d+)/)![0] ?? ""
                }
                onChange={(ev) => {
                  if (isNaN(ev.target.value as any)) return;

                  let newVal =
                    (ev.target.value == "" ? 0 : ev.target.value) +
                    marginUnits.find(
                      (x) =>
                        x.value ===
                        (spacing["marginTop"]
                          .toString()
                          .match("[A-Za-z%]+$")![0] ?? "px")
                    )!.value;
                  Object.entries(spacing).forEach(([key, value]) => {
                    if (key.includes("margin")) {
                      spacing[key as keyof typeof spacing] = newVal;
                      if (ev.target.value == "") {
                        setItemEmpty((a) => [...a, key]);
                      } else {
                        setItemEmpty((a) => a.filter((x) => x !== key));
                      }
                    }
                  });
                  updateComponentStyle(id, spacing);
                }}
              />
              <span
                className="b-px-1"
                onClick={() => {
                  let newVal =
                    Number(
                      spacing.marginTop.toString().match(/(-\d+|\d+)/)![0]
                    ) +
                    1 +
                    marginUnits.find(
                      (x) =>
                        x.value ===
                        (spacing["marginTop"]
                          .toString()
                          .match("[A-Za-z%]+$")![0] ?? "px")
                    )!.value;
                  Object.entries(spacing).forEach(([key, value]) => {
                    if (key.includes("margin")) {
                      spacing[key as keyof typeof spacing] = newVal;
                      setItemEmpty((a) => a.filter((x) => x !== key));
                    }
                  });
                  updateComponentStyle(id, spacing);
                }}
              >
                {" "}
                +{" "}
              </span>
              <div style={{ width: "35px" }}>
                <Select
                  menuPosition="fixed"
                  className="m-0"
                  isSearchable={false}
                  styles={TextEditorSelectStyle}
                  noOptionsMessage={({}) => ""}
                  options={marginUnits}
                  value={marginUnits.find(
                    (x) =>
                      x.value ===
                      (spacing["marginTop"]
                        .toString()
                        .match("[A-Za-z%]+$")![0] ?? "px")
                  )}
                  onChange={(option: any) => {
                    let newVal =
                      Number(
                        spacing.marginTop.toString().match(/(-\d+|\d+)/)![0]
                      ) + option!.value;
                    Object.entries(spacing).forEach(([key, value]) => {
                      if (key.includes("margin")) {
                        spacing[key as keyof typeof spacing] = newVal;
                      }
                    });
                    updateComponentStyle(id, spacing);
                  }}
                  placeholder=""
                ></Select>
              </div>
            </div>
            <div className="b-ms-3 b-me-1" style={{ minWidth: "unset" }}>
              Padding:
            </div>
            <div className="d-flex b-px-0">
              <div
                onClick={() => {
                  let newVal =
                    Number(
                      spacing.paddingTop.toString().match(/(-\d+|\d+)/)![0]
                    ) -
                    1 +
                    (spacing["paddingTop"]
                      .toString()
                      .match("[A-Za-z%]+$")![0] ?? "px");
                  Object.entries(spacing).forEach(([key, value]) => {
                    if (key.includes("padding")) {
                      spacing[key as keyof typeof spacing] = newVal;
                      setItemEmpty((a) => a.filter((x) => x !== key));
                    }
                  });
                  updateComponentStyle(id, spacing);
                }}
              >
                {" "}
                -{" "}
              </div>
              <input
                className="global-editor__input builder-input"
                size={1}
                value={
                  itemEmpty.includes("paddingTop")
                    ? ""
                    : spacing.paddingTop.toString().match(/(-\d+|\d+)/)![0]
                }
                onChange={(ev) => {
                  if (isNaN(ev.target.value as any)) return;

                  let newVal =
                    (ev.target.value == "" ? 0 : ev.target.value) +
                    paddingUnits.find(
                      (x) =>
                        x.value ===
                        (spacing["paddingTop"]
                          .toString()
                          .match("[A-Za-z%]+$")![0] ?? "px")
                    )!.value;
                  Object.entries(spacing).forEach(([key, value]) => {
                    if (key.includes("padding")) {
                      spacing[key as keyof typeof spacing] = newVal;
                      if (ev.target.value == "") {
                        setItemEmpty((a) => [...a, key]);
                      } else {
                        setItemEmpty((a) => a.filter((x) => x !== key));
                      }
                    }
                  });
                  updateComponentStyle(id, spacing);
                }}
              />
              <div
                className="b-px-1"
                onClick={() => {
                  let newVal =
                    Number(
                      spacing.paddingTop.toString().match(/(-\d+|\d+)/)![0]
                    ) +
                    1 +
                    (spacing["paddingTop"]
                      .toString()
                      .match("[A-Za-z%]+$")![0] ?? "px");
                  Object.entries(spacing).forEach(([key, value]) => {
                    if (key.includes("padding")) {
                      spacing[key as keyof typeof spacing] = newVal;
                      setItemEmpty((a) => a.filter((x) => x !== key));
                    }
                  });
                  updateComponentStyle(id, spacing);
                }}
              >
                {" "}
                +{" "}
              </div>
              <div style={{ width: "35px" }}>
                <Select
                  menuPosition="fixed"
                  className="m-0"
                  isSearchable={false}
                  styles={TextEditorSelectStyle}
                  value={paddingUnits.find(
                    (x) =>
                      x.value ===
                      (spacing["paddingTop"]
                        .toString()
                        .match("[A-Za-z%]+$")![0] ?? "px")
                  )}
                  options={paddingUnits}
                  onChange={(option: any) => {
                    let newVal =
                      Number(
                        spacing.paddingTop.toString().match(/(-\d+|\d+)/)![0]
                      ) + option!.value;
                    Object.entries(spacing).forEach(([key, value]) => {
                      if (key.includes("padding")) {
                        spacing[key as keyof typeof spacing] = newVal;
                      }
                    });
                    updateComponentStyle(id, spacing);
                  }}
                  placeholder=""
                ></Select>
              </div>
            </div>
          </>
        )}
        {expanded && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
                gap: "7px",
                fontWeight: "600",
              }}
            ></div>
            {["Left", "Top", "Right", "Bottom"].map((x, i) => (
              <div
                key={x}
                className={"b-px-1" + (i === 0 ? " b-ms-2" : "")}
                style={{ display: "flex", flexDirection: "column", gap: "7px" }}
              >
                <div style={{ fontWeight: "600", marginTop: "5px" }}>{x}</div>
                {["margin", "padding"].map((y) => {
                  return (
                    <div key={y} className="d-flex" style={{ gap: "5px" }}>
                      <div style={{ width: "50px" }}>
                        {y === "margin" ? "Margin" : "Padding"}
                      </div>
                      <div className="spacing-menu_input">
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          className="b-px-1 "
                          onClick={() => {
                            setItemEmpty((a) => a.filter((s) => s !== y + x));
                            (spacing as any)[y + x] =
                              Number(
                                (spacing as any)[y + x].match(/(-\d+|\d+)/)[0]
                              ) -
                              1 +
                              ((spacing as any)[y + x].match(
                                "[A-Za-z%]+$"
                              )[0] ?? "px");
                            updateComponentStyle(id, spacing);
                          }}
                        >
                          {" "}
                          −{" "}
                        </div>
                        <input
                          className="global-editor__input builder-input"
                          size={1}
                          value={
                            itemEmpty.includes(y + x)
                              ? ""
                              : (spacing as any)[y + x].match(/(-\d+|\d+)/)[0]
                          }
                          onChange={(ev) => {
                            if (isNaN(ev.target.value as any)) return;

                            if (ev.target.value == "")
                              setItemEmpty((a) => [...a, y + x]);
                            else
                              setItemEmpty((a) => a.filter((s) => s !== y + x));
                            (spacing as any)[y + x] =
                              (ev.target.value === "" ? 0 : ev.target.value) +
                              "px";
                            updateComponentStyle(id, spacing);
                          }}
                        />
                        <div
                          className="b-px-1 "
                          style={{
                            fontSize: "15px",
                          }}
                          onClick={() => {
                            setItemEmpty((a) => a.filter((s) => s !== y + x));
                            (spacing as any)[y + x] =
                              Number(
                                (spacing as any)[y + x].match(/(-\d+|\d+)/)[0]
                              ) +
                              1 +
                              ((spacing as any)[y + x].match(
                                "[A-Za-z%]+$"
                              )[0] ?? "px");
                            updateComponentStyle(id, spacing);
                          }}
                        >
                          {" "}
                          +{" "}
                        </div>
                      </div>
                      <div
                        style={{ width: "35px" }}
                        className="spacing-menu_input"
                      >
                        <Select
                          menuPosition="fixed"
                          className="m-0"
                          isSearchable={false}
                          styles={TextEditorSelectStyle}
                          value={
                            y === "padding"
                              ? paddingUnits.find(
                                  (l) =>
                                    l.value ===
                                    ((spacing as any)[y + x].match(
                                      "[A-Za-z%]+$"
                                    )[0] ?? "px")
                                )
                              : marginUnits.find(
                                  (l) =>
                                    l.value ===
                                    ((spacing as any)[y + x].match(
                                      "[A-Za-z%]+$"
                                    )[0] ?? "px")
                                )
                          }
                          options={y === "padding" ? paddingUnits : marginUnits}
                          onChange={(option: any) => {
                            (spacing as any)[y + x] =
                              Number(
                                (spacing as any)[y + x].match(/(-\d+|\d+)/)[0]
                              ) + option.value;
                            updateComponentStyle(id, spacing);
                          }}
                          placeholder=""
                        ></Select>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </>
        )}
        {/* <div className="col-1 text-end flex-grow-1 align-self-start">
          <MoreHorizOutlined
            onClick={() => {
              setExpanded(!expanded);
              setTimeout(() => {
                triggerResize();
              }, 300);
            }}
            style={{ fontSize: "18px" }}
          />
        </div> */}
      </div>
    </>
  );
}
