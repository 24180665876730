import { useShoppingCart } from "../context/ShoppingCartContext"
import { pf } from "../utilities/helpers"
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'

type CartItemProps = {
  id: number
  quantity: number
}

export function CartItemRow({ id, quantity }: CartItemProps) {
  const {
    locationProducts,
    decreaseCartQuantity,
    setCartQuantity,
    increaseCartQuantity,
    removeFromCart
  } = useShoppingCart()
  const locationProduct = locationProducts.find(i => i.id === id)

  if (locationProduct == null) return null

  return (
    <tr className="cartOrderProductRow">
      <td className="cartOrderProductRowImage d-none d-sm-table-cell">
        <img src={locationProduct.firstFileUrl} className="cartSidepanelProductImage" />
      </td>
      <td className="cartOrderProductRowName">
        {locationProduct.display_name}
      </td>
      <td className="cartOrderProductQuantity">
        <RemoveIcon className="icon clickable padding-5 fs-14" onClick={() => decreaseCartQuantity(id)} />
        <input type="text" name="quantity" value={quantity} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCartQuantity(id, +e.target.value)} style={{
          display: 'inline-block',
          width: '30px',
          backgroundColor: 'transparent',
          border: 'none',
          textAlign: 'right',
          fontWeight: 'bold'
        }} /> {locationProduct.unit_shortname} <AddIcon className="icon clickable padding-5 fs-14" onClick={() => increaseCartQuantity(id)} />
      </td>
      <td className="cartOrderProductRowPrice">
        {pf(locationProduct.price_total * quantity)} &euro;
      </td>
      <td className="cartOrderProductRowDelete">
        <FontAwesomeIcon icon={faTrashAlt} onClick={() => removeFromCart(id)} />
      </td>
    </tr>

  )
}