import React, { useEffect, useState } from "react";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import FormatPaint from "@mui/icons-material/FormatPaint";
import LinkIcon from "@mui/icons-material/Link";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import Select from "react-select";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import ColorPicker from "./ColorPicker/ColorPicker";
import {
  FormatListBulleted,
  FormatListNumbered,
  MoreHorizOutlined,
} from "@mui/icons-material";
import LinkForm from "./LinkForm/LinkForm";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  deleteComponentStyles,
  updateStructureComponent,
} from "../../../../../store/reducers/builderReducers/structure/structureActions";
import TypographiesAPI from "../../../../../store/reducers/builderReducers/typographies/typographiesApi";
import { TextEditorSelectStyle } from "../../../ReactSelectStyle/ReactSelectStyle";
import Dropdown from "../../../Dropdown/Dropdown";
import { useAppSelector } from "../../../../../store/hooks";
import { TextComponent } from "../../../../_default/interfaces/base";
import { inClass } from "../../../../../utils/Helpers";
import Dropdown2 from "react-overlays/Dropdown";
import { createPortal } from "react-dom";

interface Props {
  id: number;
  onMouseUp?: MouseEvent;
  setRange: () => void;
  keyDownEvent: KeyboardEvent;
  triggerResize: () => void;
}

export interface Toolbar {
  fontSize: number;
  fontSizeUnit: string;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  align: string;
  typo: null | number;
  blockquote: boolean;
  lineHeight: string;
  backColor: string;
  foreColor: string;
}

export default function TextEditorSub({
  id,
  onMouseUp,
  setRange,
  keyDownEvent,
  triggerResize,
}: Props) {
  console.log("TextEditorSub");
  const [showMoreMenus, setShowMoreMenus] = useState(false);
  // React.useEffect(() => {
  //   TypographiesAPI.loadTypographies()
  // }, [])
  const typographies = useAppSelector((state) => state.builder.typographies);
  if (!typographies) {
    TypographiesAPI.loadTypographies();
  }
  const component = useAppSelector(
    (state) => state.builder.structure.components[id]
  )! as TextComponent;
  const [linkModalActive, setLinkModalActive] = useState(false);
  const [lineHeightOpen, setLineHeightOpen] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [backgroundColorPickerOpen, setBackgroundColorPickerOpen] =
    useState(false);
  const [hideExpanded, setHideExpanded] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  // First time open sometimes needs to resize
  triggerResize();

  const observer = new ResizeObserver((entries) => {
    setHideExpanded(!(entries[0].contentRect.height > 19));
  });
  useEffect(() => {
    observer.observe(document.querySelector(".text-editor-options")!);
  }, []);

  const [toolbar, setToolbar] = useState<Toolbar>({
    fontSize: 0,
    fontSizeUnit: "px",
    bold: false,
    italic: false,
    underline: false,
    align: "left",
    typo: null,
    blockquote: false,
    lineHeight: component.attributes.styles.lineHeight?.toString() ?? "normal",
    backColor: "#fff",
    foreColor: "#fff",
  });

  const fontSizeUnits = [
    { value: "px", label: "px" },
    { value: "em", label: "em" },
    { value: "rem", label: "rem" },
  ];

  const lineHeightUnits = [
    { value: "px", label: "px" },
    { value: "em", label: "rem" },
    { value: "normal", label: "normal" },
  ];

  const alignments = [
    { value: "left", label: <FormatAlignLeftIcon /> },
    { value: "center", label: <FormatAlignCenterIcon /> },
    { value: "right", label: <FormatAlignRightIcon /> },
    { value: "justify", label: <FormatAlignJustifyIcon /> },
  ];

  const lists = [
    { value: "bulleted", label: <FormatListBulleted /> },
    { value: "numbered", label: <FormatListNumbered /> },
  ];

  useEffect(() => {
    if (
      keyDownEvent?.keyCode === 8 &&
      window.getSelection()?.anchorOffset === 0
    ) {
      document.execCommand("outdent", false, "true");
    }
  }, [keyDownEvent]);

  function getCurrentFontSize() {
    if (!window.getSelection()?.anchorNode) return 12;

    let node = window.getSelection()?.anchorNode as any;
    let matches: any = [];
    while (!matches || matches.length === 0) {
      node = node?.parentElement;
      matches = node?.style.fontSize.match(/\d+(\.\d+)?/);
    }

    return parseFloat(matches[0]);
  }

  function getCurrentFontSizeUnit() {
    if (!window.getSelection()?.anchorNode) return "px";

    let node = window.getSelection()?.anchorNode as any;
    let fontSize = "";
    while (!fontSize || fontSize == "") {
      node = node?.parentElement;
      fontSize = node?.style.fontSize.replace(/[0-9.]/g, "");
    }

    return fontSize;
  }

  function getCurrentFontWeight() {
    if (!window.getSelection()?.anchorNode) return "400";

    return (
      window
        .getComputedStyle(
          window.getSelection()?.anchorNode!.parentElement!,
          null
        )
        .getPropertyValue("font-weight") ?? "400"
    );
  }

  function getCurrentFontStyle() {
    if (!window.getSelection()?.anchorNode) return "normal";

    return (
      window
        .getComputedStyle(
          window.getSelection()!.anchorNode!.parentElement!,
          null
        )
        .getPropertyValue("font-style") ?? "normal"
    );
  }

  function getCurrentTextDecoration() {
    if (!window.getSelection()?.anchorNode) return false;

    return (
      window
        .getComputedStyle(
          window.getSelection()!.anchorNode!.parentElement!,
          null
        )
        .getPropertyValue("text-decoration")
        .includes("underline") ?? false
    );
  }

  function getCurrentAlignment() {
    if (!window.getSelection()?.anchorNode) return "left";

    let curr = window
      .getComputedStyle(window.getSelection()!.anchorNode?.parentElement!, null)
      .getPropertyValue("text-align");
    return curr === "start" ? "left" : curr;
  }

  function increaseFontSize() {
    let fontSize = getCurrentFontSize() + 1 + getCurrentFontSizeUnit();
    document.execCommand("fontSize", false, "7");
    replaceFontSize(fontSize);
    updateToolbar();
  }

  function decreaseFontSize() {
    let fontSize = getCurrentFontSize() - 1 + getCurrentFontSizeUnit();
    document.execCommand("fontSize", false, "7");
    replaceFontSize(fontSize);
    updateToolbar();
  }

  function setFontSize(val: string, setRange_ = false) {
    if (setRange_) setRange();
    document.execCommand("fontSize", false, "7");
    replaceFontSize(val);
    updateToolbar();
  }

  function handleBold() {
    document.execCommand("bold");
    updateToolbar();
  }

  function handleItalic() {
    document.execCommand("italic");
    updateToolbar();
  }

  function handleUnderline() {
    document.execCommand("underline");
    updateToolbar();
  }

  function handleAlignment(alignment: string) {
    setRange();
    document.execCommand(
      "Justify" + (alignment === "justify" ? "full" : alignment),
      false,
      ""
    );
    updateToolbar();
  }

  function handleLineHeight(val: string) {
    if (val != "")
      updateStructureComponent(id, "attributes.styles.lineHeight", val);
    val = val.toString();
    setToolbar({
      ...toolbar,
      lineHeight: val,
    });
  }

  function replaceFontSize(fontSize: string) {
    const builderElement = inClass(
      "builder-component--active",
      window.getSelection()!.anchorNode?.parentElement!
    );
    if (builderElement) {
      let fontTags = builderElement!.getElementsByTagName("font") as any;
      for (let i = 0; i < fontTags.length; i++) {
        if (fontTags[i].size == "7") {
          fontTags[i].removeAttribute("size");
          fontTags[i].style.fontSize = fontSize;
        }
      }
    }
  }

  useEffect(() => {
    updateToolbar();
    console.log(toolbar);
  }, [onMouseUp, component.attributes]);

  function updateToolbar() {
    setToolbar({
      ...toolbar,
      fontSize: getCurrentFontSize(),
      fontSizeUnit: getCurrentFontSizeUnit(),
      bold: getCurrentFontWeight() == "700" ? true : false,
      italic: getCurrentFontStyle() == "italic" ? true : false,
      underline: getCurrentTextDecoration(),
      align: getCurrentAlignment(),
      typo: component.attributes.typography_id,
      blockquote: getBlockQuote(),
      lineHeight:
        component.attributes.styles.lineHeight?.toString() ?? "normal",
      backColor: getBackgroundColor(),
      foreColor: getForeColor(),
    });
    // console.log(getCurrentFontSizeUnit())
  }

  function extractUnits(str: string) {
    let numbers = extractNumbers(str);
    return str.replace(numbers, "");
  }

  function extractNumbers(str: string) {
    if (str === "normal") return "";
    let numbers = str.match(/^-?\d+/);
    if (numbers) {
      return numbers[0];
    }
    return "";
  }

  function getBlockQuote() {
    if (!window.getSelection()?.anchorNode) return false;

    return elDescendantOf(
      window.getSelection()!.anchorNode!.parentElement!,
      "blockquote"
    );
  }

  function getBackgroundColor() {
    let selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return "#fff";

    let backVal = window
      .getComputedStyle(selection.anchorNode?.parentElement as any)
      .getPropertyValue("background-color");
    if (
      backVal &&
      backVal != "rgba(0, 0, 0, 0)" &&
      backVal != "rgb(0, 0, 0, 0)"
    ) {
      return backVal;
    }
    return window
      .getComputedStyle(
        selection?.anchorNode?.parentElement?.parentElement as any,
        null
      )
      .getPropertyValue("background-color");
  }

  function getForeColor() {
    let selection = window.getSelection();
    if (!selection || !selection.anchorNode) return "#000";

    let color = window
      .getComputedStyle(selection.anchorNode?.parentElement as any)
      .getPropertyValue("color");
    if (color && color != "rgba(0, 0, 0, 0)" && color != "rgb(0, 0, 0, 0)") {
      // console.log(color)
      return color;
    }
    return window
      .getComputedStyle(
        selection?.anchorNode?.parentElement?.parentElement as any,
        null
      )
      .getPropertyValue("color");
  }

  function onTypoChange(val: any) {
    updateStructureComponent(id, "attributes.typography_id", val.id);
  }

  function elDescendantOf(elNode: Element, parentTag: string): boolean {
    if (elNode.localName === parentTag) {
      return true;
    } else if (
      elNode.parentElement &&
      !elNode.classList.contains("builder-component")
    ) {
      return elDescendantOf(elNode.parentElement, parentTag);
    }
    return false;
  }

  function unformat() {
    document.execCommand("removeFormat", false, "true");
    // remove bulleted list and indent
    while (
      elDescendantOf(
        window.getSelection()?.anchorNode!.parentNode! as Element,
        "li"
      ) ||
      elDescendantOf(
        window.getSelection()?.anchorNode!.parentNode! as Element,
        "blockquote"
      )
    ) {
      document.execCommand("outdent", false, "true");
    }
    document.execCommand("unlink", false, "true");
    document.execCommand("removeFormat", false, "true");
    deleteComponentStyles(id, ["lineHeight"]);
  }

  return (
    <>
      <div className="d-flex">
        <div
          style={{
            gap: "2px",
          }}
          className={
            "d-flex text-editor-options d-flex align-items-center flex-wrap text-nowrap" +
            (showMoreMenus || lineHeightOpen || colorPickerOpen
              ? ""
              : " fixed-parent-height")
          }
        >
          {linkModalActive && (
            <LinkForm
              highlightedText={window.getSelection()?.toString()!}
              setLinkModalActive={setLinkModalActive}
              setRange={setRange}
              id={id}
            />
          )}

          <UndoIcon
            onClick={() => document.execCommand("undo")}
            className="font-menu_hover"
          />
          <RedoIcon
            onClick={() => document.execCommand("redo")}
            className="font-menu_hover"
          />
          <Select
            menuPortalTarget={document.getElementById("react-select-container")}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={(val) => onTypoChange(val)}
            className="m-0 font-menu_input"
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={typographies
              ?.map((x: any) => {
                return { ...x, label: x.name };
              })
              .find((x: any) => {
                return x.id == toolbar.typo;
              })}
            options={typographies?.map((x: any) => {
              return { ...x, label: x.name };
            })}
            placeholder=""
          ></Select>
          <div
            className="d-inline font-menu_input"
            style={{
              width: "60px",
            }}
          >
            <span
              onClick={() => decreaseFontSize()}
              style={{
                fontSize: "17px",
              }}
            >
              −
            </span>
            <input
              className="global-editor__input builder-input"
              size={1}
              style={{
                fontWeight: 600,
              }}
              value={toolbar.fontSize}
              onChange={(ev) => {
                setFontSize(ev.target.value + toolbar.fontSizeUnit, true);
              }}
            />
            <span
              onClick={() => increaseFontSize()}
              style={{
                fontSize: "17px",
              }}
            >
              +
            </span>
          </div>
          <Select
            isSearchable={false}
            menuPortalTarget={document.getElementById("react-select-container")}
            className="m-0 font-menu_input"
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={fontSizeUnits.find((x) => x.value === toolbar.fontSizeUnit)}
            onChange={(val) => setFontSize(toolbar.fontSize + val.value, true)}
            options={fontSizeUnits}
            placeholder=""
          ></Select>
          <FormatBoldIcon
            className={"font-menu_hover" + (toolbar.bold ? "active" : "")}
            onClick={() => handleBold()}
          />
          <FormatItalicIcon
            className={"font-menu_hover" + (toolbar.italic ? "active" : "")}
            onClick={() => handleItalic()}
          />
          <FormatUnderlinedIcon
            className={"font-menu_hover" + (toolbar.underline ? "active" : "")}
            onClick={() => handleUnderline()}
          />

          {/* <span
            className="position-relative dropup"
            onClick={() => setColorPickerOpen(!colorPickerOpen)}
          >
            <FormatColorTextIcon
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
            />
            <Dropdown
              className="color-picker color-picker__container"
              isOpen={(e) => {
                // setColorPickerOpen(e);

                return true;
              }}
            >
              <div className="d-flex" style={{ gap: "10px" }}>
                <ColorPicker
                  setColorPickerOpen={() => {
                    document
                      .querySelector('.show[data-bs-toggle="dropdown"]')
                      ?.parentElement!.click();
                  }}
                  title="Background"
                  onChange={(val) => {
                    document.execCommand("backColor", false, val);
                    updateToolbar();
                  }}
                  returnType="rgb"
                  value={toolbar.backColor}
                />
                <ColorPicker
                  setColorPickerOpen={() => {
                    document
                      .querySelector('.show[data-bs-toggle="dropdown"]')
                      ?.parentElement!.click();
                  }}
                  title="Text"
                  onChange={(val) => {
                    document.execCommand("foreColor", false, val);
                    updateToolbar();
                  }}
                  returnType="rgb"
                  value={toolbar.foreColor}
                />
              </div>
            </Dropdown>
          </span> */}

          <span>
            <FormatColorTextIcon
              onClick={() => setColorPickerOpen(!colorPickerOpen)}
            />

            {colorPickerOpen && (
              <ColorPicker
                title="Text"
                onChange={(val) => {
                  document.execCommand("foreColor", false, val);
                  updateToolbar();
                }}
                setColorPickerOpen={() => {}}
                returnType="rgb"
                value={toolbar.foreColor}
              />
            )}
          </span>

          <span>
            <FormatPaint
              onClick={() =>
                setBackgroundColorPickerOpen(!backgroundColorPickerOpen)
              }
            />

            {backgroundColorPickerOpen && (
              <ColorPicker
                title="Background"
                onChange={(val) => {
                  document.execCommand("backColor", false, val);
                  updateToolbar();
                }}
                setColorPickerOpen={() => {}}
                returnType="rgb"
                value={toolbar.backColor}
              />
            )}
          </span>

          <Select
            menuPortalTarget={document.getElementById("react-select-container")}
            isSearchable={false}
            className="m-0 font-menu_hover"
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={alignments.find((x) => x.value == toolbar.align)}
            components={{
              IndicatorSeparator: () => null,
            }}
            options={alignments}
            onChange={(val) => handleAlignment(val!.value)}
            placeholder=""
          ></Select>
          <Select
            menuPortalTarget={document.getElementById("react-select-container")}
            isSearchable={false}
            className="m-0 font-menu_hover"
            styles={TextEditorSelectStyle}
            noOptionsMessage={({}) => ""}
            value={lists[0]}
            onChange={(val) => {
              setRange();
              if (val!.value === "bulleted") {
                document.execCommand("insertUnorderedList");
              } else {
                document.execCommand("insertorderedlist");
              }
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
            options={lists}
            placeholder=""
          ></Select>
          <FormatIndentIncreaseIcon
            className="font-menu_hover"
            onClick={() => document.execCommand("indent", false, "null")}
          />
          <FormatIndentDecreaseIcon
            className="font-menu_hover"
            onClick={() => document.execCommand("outdent", false, "true")}
          />
          <Dropdown2 onToggle={() => {}}>
            <Dropdown2.Toggle>
              {(props: any) => (
                <span {...props}>
                  <FormatLineSpacingIcon className="font-menu_hover" />
                </span>
              )}
            </Dropdown2.Toggle>
            <Dropdown2.Menu>
              {(props: any, { show: any }) =>
                createPortal(
                  <div {...props} className="builder-dropdown">
                    <div className="d-flex font-height font-height__container">
                      {toolbar.lineHeight !== "normal" && (
                        <>
                          <div
                            className="font-height font-height__buttons"
                            onClick={() =>
                              handleLineHeight(
                                Number(extractNumbers(toolbar.lineHeight)) -
                                  1 +
                                  extractUnits(toolbar.lineHeight)
                              )
                            }
                          >
                            -
                          </div>
                          <div>
                            <input
                              className="global-editor__input builder-input global-editor__input"
                              size={2}
                              value={extractNumbers(toolbar.lineHeight)}
                              onChange={(ev) => {
                                handleLineHeight(
                                  ev.target.value +
                                    extractUnits(toolbar.lineHeight)
                                );
                              }}
                            />
                          </div>
                        </>
                      )}
                      <Select
                        isSearchable={false}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(x) =>
                          handleLineHeight(
                            x.value === "normal"
                              ? x.value
                              : (extractNumbers(toolbar.lineHeight) == ""
                                  ? 25
                                  : extractNumbers(toolbar.lineHeight)) +
                                  x.value
                          )
                        }
                        className="m-0"
                        styles={TextEditorSelectStyle}
                        noOptionsMessage={({}) => ""}
                        value={lineHeightUnits.find(
                          (x) => x.value === extractUnits(toolbar.lineHeight)
                        )}
                        options={lineHeightUnits}
                        placeholder=""
                      ></Select>
                      {toolbar.lineHeight !== "normal" && (
                        <div
                          className="font-height font-height__buttons"
                          onClick={() =>
                            handleLineHeight(
                              Number(extractNumbers(toolbar.lineHeight)) +
                                1 +
                                extractUnits(toolbar.lineHeight)
                            )
                          }
                        >
                          +
                        </div>
                      )}
                    </div>
                  </div>,
                  document.body
                )
              }
            </Dropdown2.Menu>
          </Dropdown2>
          <LinkIcon
            className="font-menu_hover"
            onClick={() => {
              if (window.getSelection()?.toString() !== "") {
                setLinkModalActive(true);
              }
            }}
          />
          <FormatStrikethroughIcon
            className="font-menu_hover"
            onClick={() => document.execCommand("strikeThrough", false, "true")}
          />
          <FormatClearIcon
            className="font-menu_hover"
            onClick={() => unformat()}
          />
        </div>
        <div
          className={"text-end flex-grow-1" + (hideExpanded ? " d-none" : "")}
          style={{ background: "#333333", zIndex: 3 }}
        >
          {/* <MoreHorizOutlined
            style={{ fontSize: "18px" }}
            onClick={() => {
              setShowMoreMenus(!showMoreMenus);
              triggerResize();
            }}
          /> */}
        </div>
      </div>
    </>
  );
}
